import baseURL from "../Api/baseURL";

let previousURL = "";
let previousData = null;

export const useGetData = async (url, params) => {
  try {
    // قم بفحص إذا كان الـ URL نفسه مكرر
    if (url === previousURL) {
      // إذا كان مكررًا، قم بإرجاع البيانات السابقة
      return previousData;
    }

    const res = await baseURL.get(url, params);
    const newData = res.data;

    // قم بتحديث الحالة السابقة
    previousURL = url;
    previousData = newData;

    return newData;
  } catch (error) {
    throw error;
  }
};
export const useGetDataToken = async (url, params) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const res = await baseURL.get(url, config);
    return res.data;
  } catch (error) {
    throw error; // رمي الخطأ ليتم التعامل معه في الدالة التي تستدعيها
  }
};
