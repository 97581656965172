import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import "./category.css";
import { Link } from "react-router-dom";
import HomeCategoryHook from "../../hook/category/home-category-hook";
import { AspectRatio } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
const HomeCategory = () => {
  const aspectRatio = 16 / 9;
  const width = 60 * aspectRatio;
  const [category, categories, loading] = HomeCategoryHook();
  return (
    <div class="category" dir="rtl">
      <div class="container-cat">
        <div className="category-title-up">
          <h4>الاقسام الرئيسية</h4>
          <button className="btn-more">
            <a href="/allCategory">المزيد</a>
          </button>
        </div>
        <div class="category-item-container has-scrollbar">
          {loading ? (
            <div
              className="text-center"
              style={{ margin: "auto", marginTop: "50px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">يتم التحميل...</span>
              </Spinner>
            </div>
          ) : categories ? (
            categories.slice(0, 7).map((item) => (
              <Link
                to={`/products?category=${item._id}`}
                class="category-item"
                key={item._id}
              >
                <LazyLoadImage
                  src={item.image}
                  effect="blur"
                  alt={`${item.name} - Kiwi Fashion`}
                  width={`${width}`}
                  placeholderSrc={item.image}
                />

                <div class="category-content-box">
                  <h3 class="category-item-title">{item.name}</h3>
                </div>
              </Link>
            ))
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HomeCategory;
