import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotifaction";
import { createReview } from "../../redux/actions/reviewAction";
import { useChange } from "../../context/ChangeContext";
import { useUser } from "../../context/getCurrentUser";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const AddRateHook = (id) => {
  const dispatch = useDispatch();
  const [rateText, setRateText] = useState("");

  const [rateValue, setRateValue] = useState(1);
  const [loading, setLoading] = useState("");
  const [isPress, setIsPress] = useState(false);
  const onChangeRateText = (e) => {
    setRateText(e.target.value);
  };
  const onChangeRateValue = (val) => {
    setRateValue(val);
  };

  const { user } = useUser();
  const onSubmit = async () => {
    if (rateText === "") {
      notify("من فضلك اضف تعليق", "error");
  
      return;
    }
    setLoading(true);
    setIsPress(true);
    await dispatch(
      createReview(id, {
        review: rateText,
        rating: rateValue,
      })
    );
    setLoading(false);
    setIsPress(false);
  };
  const res = useSelector((state) => state.reviewReducer.createReview);
  const { change, updateChange } = useChange();
  const notify2 = () =>
    NotificationManager.error("لقد قمت باضافة تقييم لهذا المنتج مسبقاً");

  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.status) {
          if (res.status === 201) {
            notify("لقد تم اضافة التقييم بنجاح", "success");
            setRateText("");
            updateChange(Math.random(100 * 500));
          }
          if (res.status === 403) {
            notify("غير مسموح للادمن بالتقييم", "error");
          }
          if (res.status === 401) {
            notify("يرجى التسجيل في الموقع لاضافة التقييم", "error");
          }
        }
        if (res.data.errors) {
          if (
            res.data.errors[0].msg ===
            "You already added review on this product"
          ) {
            notify2();
          }
        }
      }
    }
  }, [loading]);
  return [
    rateText,
    onChangeRateText,
    onChangeRateValue,
    rateValue,
    setRateValue,
    loading,
    onSubmit,
    isPress,
    user,
  ];
};

export default AddRateHook;
