import React from "react";
import { Row } from "react-bootstrap";
import AdminAllProductsCard from "./AdminAllProductsCard";
const AdminAllProducts = ({ products }) => {
  return (
    <div>
      <div className="admin-content-text">اداره جميع المنتجات</div>
      <div className="product-grid">
        {products && products.data ? (
          products.data.map((item, index) => <AdminAllProductsCard item={item} key={index}/>)
        ) : (
          <h4 className="mt-4 text-center"> لا يوجد منتجات </h4>
        )}
      </div>
    </div>
  );
};

export default AdminAllProducts;
