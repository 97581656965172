import {
  GET_ERROR,
  CREATE_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_LIKE,
} from "../type";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";

export const getProductLike = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/products?category=${id}`);
    dispatch({
      type: GET_PRODUCT_LIKE,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
