import React from "react";
import { Container, Row , Spinner} from "react-bootstrap";
import BrandCard from './BrandCard';

const BrandContainer = ({data,loading}) => {
  return (
    <div className="my-3">
      <Container>
        <div className="admin-content-text ">كل الماركات</div>
        <Row className="my-1 justify-content-between">
        {loading ? (
          <div
            className="text-center"
            style={{ margin: "auto", marginTop: "50px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">يتم التحميل...</span>
            </Spinner>
          </div>
        ) : data&& data.length > 0 ? (
          data.slice(0, 5).map((item) => {
            return (
              <BrandCard key={item._id} title={item.name} img={item.image} />
            );
          })
        ) : (
          <p className="text-center mt-4">لا يوجد ماركات حالياً</p>
        )}
        </Row>
        
      </Container>
    </div>
  );
};

export default BrandContainer;
