import {
  GET_ALL_PRODUCTS_PRESS,
  DELETE_PRODUCTS,
  GET_ERROR,
  CREATE_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_PRODUCT_DETAILS,
  UPDATE_PRODUCTS,
  GET_PRODUCT_IMAGES,
  GET_ALL_PRODUCTS_HOME,
  GET_ALL_PRODUCTS_HOME_SORT,
  GET_ALL_PRODUCTS_NEW,
  OUT_STOCK,
} from "../type";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";
import useDeleteData from "../../hooks/useDeleteData";
import {
  useUpdateData,
  useUpdateDataWithImage,
} from "../../hooks/useUpdateData";
export const getOneProduct = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/products/${id}`);
    dispatch({
      type: GET_PRODUCT_DETAILS,
      payload: response,
      loading: true, // تحديد الحالة على false بعد الإرسال الناجح
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
export const getOneProductImages = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/products/${id}`);
    dispatch({
      type: GET_PRODUCT_IMAGES,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
    return response.data;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
export const createProduct = (formData) => async (dispatch) => {
  const response = await useInsertDataWithImage("/api/v1/products", formData);
  try {
    if (response.status === 201) {
      dispatch({
        type: CREATE_PRODUCTS,
        payload: response,
        loading: false, // تحديد الحالة على false بعد الإرسال الناجح
      });
    } else {
      dispatch({
        type: GET_ERROR,
        payload: "هناك مشكلة في عملية الإضافة",
      });
    }
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
export const getAllProducts = (limit, CatID, SubID) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products?limit=4
        `
    );
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
export const getAllProductsSearch =

    (limit, CatID, SubID, key, sort, page, fromPrice, toPrice, sizeS) =>
  async (dispatch) => {
    try {
      // إذا كانت لا توجد عمليات تحميل حاليًا
      // if (!isLoading) {
      // قم بإرسال الطلب
      const pageParam = page ? `&page=${encodeURIComponent(page)}` : "";
      const fromPriceParam =
        fromPrice !== "" && !isNaN(fromPrice) ? `&price[gte]=${fromPrice}` : "";
      const toPriceParam =
        toPrice !== "" && !isNaN(toPrice) ? `&price[lte]=${toPrice}` : "";

      const response = await useGetData(
        `/api/v1/products?limit=${limit}${
          CatID ? `&category=${encodeURIComponent(CatID)}` : ""
        }${
          SubID !== "" && SubID !== null && SubID
            ? `&subcategory=${encodeURIComponent(SubID)}`
            : ""
        }${
          key !== "" && key !== null && key
            ? `&keyword=${encodeURIComponent(key)}`
            : ""
        }${
          sort !== "" ? `&sort=${sort}` : ""
        }${pageParam}${fromPriceParam}${toPriceParam}${
          sizeS !== "" && typeof sizeS === "string"
            ? `&availableSizes[in]=${sizeS}`
            : ""
        }`
      );

      dispatch({
        type: GET_ALL_PRODUCTS_NEW,
        payload: response,
        loading: false,
      });

      return response;
      // }
    } catch (e) {
      dispatch({
        type: GET_ERROR,
        payload: "حدث خطأ أثناء إرسال البيانات: " + e,
      });
    }
  };

export const getAllProductsHome = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/products`);
    dispatch({
      type: GET_ALL_PRODUCTS_HOME,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
export const getAllProductsHomeSort = (sort) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products${sort ? `?sort=${sort}` : ""}`
    );
    dispatch({
      type: GET_ALL_PRODUCTS_HOME_SORT,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
export const getAllProductsPage = (limit, page) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/products?limit=${limit}&page=${page}`
    );
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: response,
      loading: true, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};

export const deleteProducts = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/products/${id}`);
    dispatch({
      type: DELETE_PRODUCTS,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
export const updateProducts = (id, data) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/v1/products/${id}`,
      data
    );
    dispatch({
      type: UPDATE_PRODUCTS,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};
export const outStockProducts = (id, data) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/products/${id}`, {
      inStock: false,
    });
    dispatch({
      type: OUT_STOCK,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};
