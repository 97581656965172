import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProductToCart, applyCouponCart, clearAllCartItems, clearCartItem, getAllCartItems, updateCartItem } from '../../redux/actions/cartAction'
import notify from '../useNotifaction'
const ApplyCouponHook = () => {
    const dispatch = useDispatch()
    const [loading,setLoading] = useState('')
    const [coupon,setCoupon] = useState('')

    const onChangeCoupon = (e)=>{
        setCoupon(e)
    }
    const handleSubmitCoupon = async ()=>{
   
        setLoading(true)
        await dispatch(applyCouponCart({
            couponName:coupon
        }))
        setLoading(false)

    }
    const res = useSelector(state=>state.cartReducer.applyCouponCart)
    useEffect(()=>{
        if(loading === false){
            if(res && res.status === 200){
                notify('تم تطبيق الكوبون بنجاح','success')
                setTimeout(()=>{
                    window.location.reload()
                },1000)
            }else {
                notify('هذا الكوبون خاطئ او منتهي الصلاحية','error')
                setTimeout(()=>{
                    window.location.reload()
               },2000)
            }
        }
    },[loading])
  return [coupon,onChangeCoupon,handleSubmitCoupon]
}

export default ApplyCouponHook