import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import './AddAddress.css'
import PhoneInput from "react-phone-input-2";
import UpdateAddressHook from "../../hook/user/update-address-hook";
import { ToastContainer } from "react-toastify";
const UserEditAddress = () => {
  const [
    alias,details,phone,country,city,postalCode,
    onChangeAlias,onChangeDetails,onChangePhone,
    onChangeCountry,onChangeCity,onChangePostalCode,
    onSubmit,loadingEdit
] = UpdateAddressHook()
  return (
    <div className="user-add-address">
      <Row className="justify-content-center">
        <div className="admin-content-text pb-2 text-center">تعديل عنوان </div>
        <Col sm="8">
        <input
            type="text"
            value={alias}
            onChange={onChangeAlias}
            className="input-form d-block mt-3 px-3"
            placeholder="تسمية العنوان مثلا(المنزل - العمل)"
          />
          <textarea
           value={details}
           onChange={onChangeDetails}
            className="input-form-area p-2 mt-3"
            rows="4"
            cols="50"
            placeholder="العنوان بالتفصيل"
          />
          {/* <input
            type="text"
            value={phone}
            onChange={onChangePhone}
            className="input-form d-block mt-3 px-3"
            placeholder="رقم الهاتف"
          /> */}

              <PhoneInput
            country={'tr'} // اختيار الدولة الافتراضية
            value={phone}
            onChange={onChangePhone}
            inputExtraProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            />
          <input
            type="text"
            value={country}
            onChange={onChangeCountry}
            className="input-form d-block mt-3 px-3"
            placeholder="الدولة"
          />
          <input
            type="text"
            value={city}
            onChange={onChangeCity}
            className="input-form d-block mt-3 px-3"
            placeholder="المدينة"
          />
          <input
            type="number"
            value={postalCode}
            onChange={onChangePostalCode}
            className="input-form d-block mt-3 px-3"
            placeholder="الرمز البريدي"
          />
          <br/>
        <button onClick={onSubmit} className="btn-save">
            {loadingEdit === true ? <Spinner animation="border" variant="dark" /> : "تعديل العنوان"}

          </button>
        </Col>

      </Row>
      <ToastContainer/>

    </div>
  );
};
export default UserEditAddress