/* eslint-disable no-undef */
import React, { useEffect } from "react";
import "./else.css";
import { Helmet } from "react-helmet";
import SecondAd from "../../Components/AdsCompnents/2Ad";
import FiveAd from "../../Components/AdsCompnents/5Ad";
import AdCom from "../../Components/AdsCompnents/AdCom";
const AboutUs = () => {
  useEffect(() => {
    // window.adsbygoogle = window.adsbygoogle || [];
    // window.adsbygoogle.push({});
    // eslint-disable-next-line no-undef
    const fetch = async()=>{

      await (adsbygoogle = window.adsbygoogle || []).push({});
    }
    fetch()
  }, []);
  return (
    <div className="container-else">
      <Helmet>
        <title>من نحن - Kiwi Fashion</title>
        <meta
          name="description"
          content="تعريف حول شركتنا شركة كيوي للملابس - Kiwi Fashion"
        />
      </Helmet>
      <h2>حولنا</h2>
      <p>
        نحن كيوي هوم مختصين بتجارة وبيع الالبسة ، مقرنا الأول تركيا – ولاية
        اسطنبول تأسست كيوي عام 2016 م حيث إننا بفضل اللّه وصلنا إلى مرحلة متقدمة
        بفضل خبرتنا العاليه بمجال التصنيع واللباس وفن التصميم منذ عام 2009 بدأنا
        بعالم الالبسة بدأنا منذ فتره طويله حتى تمكنا بفضل الله بإفتتاح معرض خاص
        بنا ومصنع يتمتع بمخرجات عالية الدقه ، بالإستعانه بمصممين محترفين بهذا
        المجال بالإضافة لانواع القطن المستوردة من اكثر من دولة أبرمت شركة كيوي
        اتفاقيات طويلة الأجل مع العديد من العلامات التجارية المتميزة ذات المستوى
        العالمي وأصبحت مرادفًا لتوفير لباس عالي الجودة وخدمة العملاء في جميع
        أنحاء تركيا والعالم.
      </p>
      <AdCom slot="7372516538" />

    </div>
  );
};

export default AboutUs;
