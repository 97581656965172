// ChangeContext.js
import React, { createContext, useContext, useState } from 'react';

const ChangeWishlist = createContext();

export const ChangeWishlistProvider = ({ children }) => {
  const [changeWishlist, setChangeWishlist] = useState(null);

  const updateChangeWishlist = (newChange) => {
    setChangeWishlist(newChange);
  };

  return (
    <ChangeWishlist.Provider value={{ changeWishlist, updateChangeWishlist }}>
      {children}
    </ChangeWishlist.Provider>
  );
};

export const useChangeWishlist = () => {
  return useContext(ChangeWishlist);
};
