import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ViewProductDetailsHook from "../../hook/products/view-product-details-hook";
import Skeleton from "react-loading-skeleton";

const ProductDesc = () => {
  let { id } = useParams();
  const [item] = ViewProductDetailsHook(id);
  return (
    <Row className={"desc-cont"}>
      <h4>وصف المنتج : </h4>
      <Col md="12">
        {item && item.description ? (
          <div dangerouslySetInnerHTML={{ __html: item.description }} />
        ) : (
          <Skeleton width={260} height={65} />
        )}
      </Col>
      {item && item.imagesElse && item.imagesElse.length > 0 ? (
        <>
          <h4>صور اخرى:</h4>
          <Col md="12">
            <div className={"images-cont-desc"}>
              {item.imagesElse.map((image, index) => (
                <div className="img-box" key={index}>
                  <img src={image} alt="" />
                </div>
              ))}
            </div>
          </Col>
        </>
      ) : (
        <div>
          {!item.imagesElse || item.imagesElse.length !== 0 ? (
            <Skeleton width={350} height={90} />
          ) : null}
        </div>
      )}
    </Row>
  );
};

export default ProductDesc;
