import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../redux/actions/categoryAction";
import notify from "./../../hook/useNotifaction";
import { createSubCategory } from "./../../redux/actions/subCategoryAction";

const AdminAddSubcategoryHook = () => {
  const dispatch = useDispatch();
  const [id, setID] = useState("0");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  const category = useSelector((state) => state.allCategory.category);
  const subcategory = useSelector((state) => state.allSubCategory.subcategory);

  const handelChange = useCallback((e) => {
    setID(e.target.value);
  }, []);

  const onChangeName = useCallback((e) => {
    e.persist();
    setName(e.target.value);
  }, []);

  const handelSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (id === "0") {
        notify("من فضلك اختر تصنيف رئيسي", "warn");
        return;
      }

      if (name === "") {
        notify("من فضلك ادخل اسم التصنيف", "warn");
        return;
      }

      setLoading(true);
      await dispatch(
        createSubCategory({
          name,
          category: id,
        })
      );
      setLoading(false);
    },
    [dispatch, id, name]
  );

  useEffect(() => {
    if (!navigator.onLine) {
      notify("هناك مشكله فى الاتصال بالانترنت", "warn");
      return;
    }
    dispatch(getAllCategory());
  }, [dispatch]);

  useEffect(() => {
    if (loading === false) {
      setName("");
      setID("0");

      if (subcategory.status === 201) {
        notify("تمت الاضافة بنجاح", "success");
      } else if (
        subcategory.status !== 201 ||
        subcategory.status === 400 ||
        subcategory.status
      ) {
        notify("هناك مشكله فى عملية الاضافة", "warn");
      }

      setLoading(true);
    }
  }, [loading, subcategory]);

  return [id, name, loading, category, subcategory, handelChange, handelSubmit, onChangeName];
};

export default AdminAddSubcategoryHook;
