import React, { useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DeleteCartHook from "../../hook/cart/delete-cart-hook";
import { ToastContainer } from "react-toastify";
import ApplyCouponHook from "../../hook/cart/apply-coupon-hook";
import notify from "../../hook/useNotifaction";

const CartCheckout = ({
  cartItems,
  totalPrice,
  couponName,
  totalPriceAfter,
}) => {
  const [handleDeleteCart, loadingDelete] = DeleteCartHook();
  const [coupon, onChangeCoupon, handleSubmitCoupon] = ApplyCouponHook();
  const navigate = useNavigate();
  useEffect(() => {
    if (couponName) {
      onChangeCoupon(couponName);
    }
  }, [couponName]);
  const handleCheckout = () => {
    if (cartItems.length >= 1) {
      navigate("/order/paymethoud");
    } else {
      notify("من فضلك اضف منتجات للعربة اولا", "warn");
    }
  };
  return (
    <Row className="my-1 d-flex justify-content-center cart-checkout pt-3">
      <Col xs="12" className="d-flex  flex-column  ">
        {totalPrice > 0 ? (
          <button className="product-cart-add w-100 px-2 mb-1">
            {loadingDelete === true ? (
              <Spinner animation="border" variant="dark" />
            ) : (
              <span onClick={handleDeleteCart}>مسح العربة </span>
            )}
          </button>
        ) : (
          ""
        )}
        <div className="d-flex  ">
          <input
            className="copon-input d-inline text-center "
            placeholder="كود الخصم"
            value={coupon}
            onChange={(e) => onChangeCoupon(e.target.value)}
          />
          <button onClick={handleSubmitCoupon} className="copon-btn d-inline ">
            تطبيق
          </button>
        </div>
        <div className="product-price d-inline w-100 my-3  border">
          {totalPriceAfter >= 1
            ? `${totalPriceAfter}TL بعد الخصم`
            : `${totalPrice}TL`}
        </div>

        <button
          onClick={handleCheckout}
          style={{ textDecoration: "none" }}
          className="product-cart-check  d-inline "
        >
          {" "}
          اتمام الشراء
        </button>
      </Col>
      <ToastContainer />
    </Row>
  );
};

export default CartCheckout;
