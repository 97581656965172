import {
    GET_ERROR,
    GET_PRODUCT_LIKE,
  } from "../type";
  
  const inital = {
    productLike: [],
    loading: true,
  };
  const likeReducer = (state = inital, action) => {
    switch (action.type) {
      case GET_PRODUCT_LIKE:
        return {
          ...state,
          productLike: action.payload,
          loading: false,
        };
      case GET_ERROR:
        return {
          loading: true,
          products: action.payload,
        };
      default:
        return state;
    }
  };
  export default likeReducer;
  