import React from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import mobile from "../../images/mobile.png";
import deleteicon from "../../images/delete.png";
const AdminAllOrdersItem = ({item}) => {
  const dateString = item.createdAt

  const formatDate = (dateString)=>{
        const options = {year:'numeric',month:'long',day:'numeric'}
        return new Date(dateString).toLocaleDateString(undefined,options)
    }
    let dateOfOrder = formatDate(dateString)
  return (
    <Col sm="12">
        <a href={`/admin/orders/${item && item._id ? item._id : ''}`}
        className="cart-item-body-admin my-2 d-flex "
        style={{textDecoration:"none"}}>
      <div className="w-100">
        <Row className="justify-content-between">
          <Col sm="12" className=" d-flex flex-row justify-content-between">
            <div className="d-inline pt-2 cat-text">طلب رقم #{item && item.id}</div>
            <div className="d-flex pt-2 " style={{ cursor: "pointer" }}>
              <img src={deleteicon} alt="" width="20px" height="24px" />
              <div className="cat-text d-inline me-2">ازاله</div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col sm="12" className=" d-flex flex-row justify-content-start">
            <div className="d-inline pt-2 cat-title">
طلب من {item && item.user && item.user.name}
            </div>
            <div className="d-inline pt-2 cat-rate me-2" style={{color:'orange'}}>{item && item.user && item.user.email}</div>
          </Col>
        </Row>
       
        {/* <Row>
          <Col sm="12" className="mt-1">
            <div className="cat-text d-inline">الماركة :</div>
            <div className="barnd-text d-inline mx-1">ابل </div>
          </Col>
        </Row> */}
        <Row>
          <Col sm="12" className="mt-1 d-flex">
            <div
              className="color ms-2 border"
              style={{ backgroundColor: item.color}}></div>
          </Col>
        </Row>

        <Row className="justify-content-between">
          <Col sm="12" className=" d-flex flex-row justify-content-between">
            {/* <div className="d-inline pt-2 d-flex">
              <div className="cat-text  d-inline">الكميه</div>
              <input
                className="mx-2 "
                type="number"
                value={item.count}
                disabled
                style={{ width: "40px", height: "25px" }}
              />
            </div> */}
            <div className="d-inline pt-2 barnd-text">{item.totalOrderPrice}TL</div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
      <Col>
      <div className="d-flex">

        <div className="attribute-cont">
          <div className="d-inline "> حالة التوصيل </div>
          <div className="d-inline mx-2 stat">{item.isDelivred === true ? ' تم التوصيل ' : ' لم يتم التوصيل '}</div>
        </div>
        <div className="attribute-cont">
          <div className="d-inline"> حالة الدفع </div>
          <div className="d-inline mx-2 stat">{item.isPaid === true ? ' تم دفع ' : ' لم يتم الدفع '}</div>
        </div>
      </div>
        <div className="attribute-cont">
          <div className="d-inline"> طريقة الدفع </div>
          <div className="d-inline mx-2 stat">{item.paymentMethodType === 'cash' ? 'كاش' : ' بطاقة ائتمانية'}</div>
        </div>
        <div className="attribute-cont">
          <div className="d-inline"> تاريخ الطلب </div>
          <div className="d-inline mx-2 stat">{dateOfOrder}</div>
        </div>
      </Col>
      </Row>
      </div>
        </a>
    </Col>
  );
}

export default AdminAllOrdersItem