import React from "react";
import CategoriesHeader from "../../Components/Category/CategoriesHeader";
import SearchCountResult from "../../Components/Utility/SearchCountResult";
import { Col, Container, Row } from "react-bootstrap";
import SideFilter from "../../Components/Utility/SideFilter";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import Pagination from "../../Components/Utility/Pagination";
import ViewSearchProductsHook from "../../hook/products/view-search-product-hook";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import FourthAd from "../../Components/AdsCompnents/4Ad";
import AdCom from "../../Components/AdsCompnents/AdCom";

const ShopProducsPage = () => {
  const [
    items,
    pagination,
    onPress,
    getProduct,
    results,
    fromPrice,
    setFromPrice,
    toPrice,
    setToPrice,
    filterByPrice,
    loading,
  ] = ViewSearchProductsHook();

  const pageCount = pagination ? pagination.numberOfPages : 1;
  return (
    <div className="min-height-e">
      <Helmet>
        <title>المنتجات - Kiwi Fashion</title>
        <meta
          name="description"
          content="تسوق احدث وافضل الملابس العربية والتركية من كيوي - Kiwi Fashion"
        />
        <meta property="og:title" content={`تسوق في Kiwi Fashion`} />
        <meta
          property="og:description"
          content={`تسوق افضل الالبسة من شركة كيوي مع شحن الى جميع انحاء العالم`}
        />
        <meta
          property="og:image"
          content={`https://www.kiwifashion.net/logo-ki.jpeg`}
        />
        <meta
          property="og:url"
          content={`https://www.kiwifashion.net/products`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="container-shop">
        {/* <CategoriesHeader /> */}
        {loading === true ? (
          <Skeleton style={{ width: "250px", height: "20px" }} />
        ) : (
          <SearchCountResult
            onClick={getProduct}
            title={
              results === 1 && Number.isInteger(results)
                ? "يوجد منتج واحد"
                : results === 2 && Number.isInteger(results)
                ? "يوجد منتجين فقط"
                : results > 2 && Number.isInteger(results)
                ? `يوجد ${results} منتجات`
                : results > 10 && Number.isInteger(results)
                ? `يوجد ${results} منتج`
                : results <= 0 && Number.isInteger(results)
                ? "لا يوجد منتجات"
                : ""
            }
          />
        )}
        <Row className={"d-flex flex-row"}>
          <Col lg="2" className="d-flex">
            <SideFilter />
          </Col>
          <Col md="12" lg="9" className={"co-cont-pro"}>
            <CardProductsContainer
              title={""}
              imgUrl={false}
              btntitle={""}
              products={items.data}
              loading={loading}
              noCaro={false}
            />
            {pageCount > 1 ? (
              <div>
                <Pagination loading={loading} pageCount={pageCount} onPress={onPress} />
              </div>
            ) : (
              ""
            )}{" "}
          </Col>

          <AdCom slot="7372516538" />
        </Row>
      </div>
    </div>
  );
};

export default ShopProducsPage;
