import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginHook from "../../hook/auth/login-hook";
import { ToastContainer } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";

const LoginPage = () => {
  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     window.location.replace("/user/profile");
  //   }
  // }, [localStorage.getItem("token")]);
  const [
    email,
    password,
    onChangeEmail,
    onChangePassword,
    loading,
    onSubmit,
    isPress,
    responseGoogle,
  ] = LoginHook();

  return (
    <Container className="min-height-e">
      <Row className="py-5 d-flex justify-content-center ">
        <Col sm="12" className="d-flex flex-column " dir="ltr">
          <label className="mx-auto title-login">تسجيل الدخول</label>
          <input
            placeholder="الايميل..."
            type="email"
            value={email}
            onChange={onChangeEmail}
            className="user-input my-3 text-center mx-auto"
          />
          <input
            placeholder="كلمه السر..."
            type="password"
            value={password}
            onChange={onChangePassword}
            className="user-input text-center mx-auto"
          />
          <button className="btn-login mx-auto mt-4" onClick={onSubmit}>
            {" "}
            {isPress === true ? (
              loading === true ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "تسجيل الدخول"
              )
            ) : (
              "تسجيل الدخول"
            )}
          </button>
          <div className="google-login">
            <GoogleLogin
              buttonText="تسجيل الدخول باستخدام جوجل"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
            />
          </div>
          <label className="mx-auto my-4">
            ليس لديك حساب ؟{" "}
            <Link to="/register" style={{ textDecoration: "none" }}>
              <span style={{ cursor: "pointer" }} className="text-danger">
                اضغط هنا
              </span>
            </Link>
          </label>
          <label className="mx-auto my-4">
            <Link to="/user/forget-password" style={{ textDecoration: "none" }}>
              <span style={{ cursor: "pointer" }} className="text-danger">
                هل نسيت كلمة السر
              </span>
            </Link>
          </label>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default LoginPage;
