import { combineReducers } from "redux";
import categoryReducer from "./categoryReducer";
import brandReducer from "./brandReducer";
import subCategoryReducer from "./subCategoryReducer";
import productsReducer from "./productsReducer";
import likeReducer from "./likeReducer";
import authReducer from "./authReducer";
import reviewReducer from "./reviewReducer";
import wishlistReducer from "./wishlistReducer";
import couponReducer from "./couponReducer";
import userAddressReducer from "./userAddressReducer";
import userReducer from "./userReducer";
import cartReducer from "./cartReducer";
import checkoutReducer from "./checkoutReducer";
import orderReducer from "./orderReducer";
export default combineReducers({
  allCategory: categoryReducer,
  allBrand: brandReducer,
  allSubCategory: subCategoryReducer,
  allProducts: productsReducer,
  likeProducts:likeReducer,
authReducer:authReducer,
reviewReducer:reviewReducer,
wishlistReducer:wishlistReducer,
couponReducer:couponReducer,
userAddressReducer:userAddressReducer,
userReducer:userReducer,
cartReducer:cartReducer,
checkoutReducer:checkoutReducer,
orderReducer:orderReducer,
});
