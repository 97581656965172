import React, { useEffect, useRef, useState } from 'react'
import deleteicon from '../../images/delete.png'
import editicon from '../../images/edit-icon.png'
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import CouponCardHook from '../../hook/coupon/coupon-card-hook';
const AdminCouponCard = ({item}) => {
  const dateRef = useRef()
    const [show,
      handleClose,
      handleDelete,
      handleShow,
      dateOfCoupon,handleCloseEdit,showEdit,handleEdit,handleShowEdit,
      couponName,onChangeCouponName,couponDate,onChangeCouponDate,
      couponDiscount,onChangeCouponDiscount] = CouponCardHook(item)
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>تاكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل انت متاكد من انك تريد حذف الكوبون</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            تراجع
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
      >
        <Modal.Header>
          <Modal.Title> تعديل الكوبون</Modal.Title>
        </Modal.Header>
        <input
          type="text"
          className="input-form d-block mt-3 px-3"
          placeholder="اسم الكوبون"
          value={couponName}
          id="name"
          onChange={onChangeCouponName}
        />
        <label htmlFor='date' style={{marginTop:"16px"}}> تاريخ انتهاء الكوبون هو : {couponDate}</label>

        <input
          type="date"
          ref={dateRef}
          className="input-form d-block mt-3 px-3"
          placeholder="تاريخ الانتهاء"
          id="date"
          onChange={onChangeCouponDate}
          value={couponDate}
          onFocus={()=> dateRef.current.type = 'date'}
          onBlur={()=> dateRef.current.type = 'text'}
        />
        <label htmlFor='discount' style={{marginTop:"16px"}}>نسبة الخصم</label>
        <input
          type="number"
          id='discount'
          value={couponDiscount}
          onChange={onChangeCouponDiscount}
          className="input-form d-block mt-3 px-3"
          placeholder="نسبة خصم الكوبون"
      
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            تراجع
          </Button>
          <Button variant="danger" onClick={handleEdit}>
            تعديل
          </Button>
        </Modal.Footer>
      </Modal>
    <div className="user-address-card my-3">
      <div className="header">
        <div className="title">اسم الكوبون : {item.name}</div>
        <div className="actions">
       
          <span className="delete"    onClick={handleShow}>
            <img
              alt=""
              className="delete-icon"
              src={deleteicon}
              height="17px"
              width="15px"
           
            style={{cursor:'pointer'}}
            />
            ازاله
          </span>
    
       
          <span onClick={handleShowEdit} className="delete" style={{marginRight:'11px',color:'green !important',cursor:'pointer'}}>
            <img


              alt=""
              className="delete-icon"
              src={editicon}
              height="17px"
            
              width="15px"
            />
            تعديل
          </span>
        </div>
      </div>

      <div className="address">نسبة الخصم : {item.discount}%</div>
      <div className="address">تاريخ الانتهاء : {dateOfCoupon}</div>
    </div> 
    <ToastContainer/>
            </>
     )
}

export default AdminCouponCard