import {
  GET_ALL_CATEGORY,
  GET_ERROR,
  CREATE_CATEGORY,
  GET_ONE_CATEGORY,
  GET_ALL_CATEGORY_NAV,
} from "../type";

import { useGetData } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";

// استيراد الأنواع

// احصل على كل الفئات
export const getAllCategory = (limit) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/categories${
        limit && limit !== null && limit !== undefined ? `?limit=${limit}` : ""
      }`
    );
    dispatch({
      type: GET_ALL_CATEGORY,
      payload: response,
    
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: "Error: " + error.message,
    });
  }
};
export const getAllCategoryNav = (limit) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/categories`);
    dispatch({
      type: GET_ALL_CATEGORY_NAV,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: "Error: " + error.message,
    });
  }
};

// احصل على فئة واحدة
export const getOneCategory = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/categories/${id}`);
    dispatch({
      type: GET_ONE_CATEGORY,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: "Error: " + error.message,
    });
  }
};

// احصل على كل الفئات بالتصفح
export const getAllCategoryPage = (page) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/categories?limit=8&page=${page}`
    );
    dispatch({
      type: GET_ALL_CATEGORY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: "Error: " + error.message,
    });
  }
};

// قم بإنشاء فئة جديدة
export const createCategory = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(
      "/api/v1/categories",
      formData
    );
    if (response.status === 201) {
      dispatch({
        type: CREATE_CATEGORY,
        payload: response,
        loading: false,
      });
    } else {
      dispatch({
        type: GET_ERROR,
        payload: "هناك مشكلة في عملية الإضافة",
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + error.message,
    });
  }
};
