import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Footer from "./Components/Utility/Footer";
import NavBarLogin from "./Components/Utility/NavBarLogin";
import LoginPage from "./Pages/Auth/LoginPage";
import HomePage from "./Pages/Home/HomePage";
import RegisterPage from "./Pages/Auth/RegisterPage";
import AllCategoryPage from "./Pages/Category/AllCategoryPage";
import AllBrandPage from "./Pages/Brand/AllBrandPage";
import ShopProducsPage from "./Pages/Products/ShopProducsPage";
import ProductDetalisPage   from "./Pages/Products/ProductDetalisPage";
import CartPage from "./Pages/Cart/CartPage";
import ChoosePayMethoudPage from "./Pages/Checkout/ChoosePayMethoudPage";
import AdminAllProductsPage from "./Pages/Admin/AdminAllProductsPage";
import AdminAllOrdersPage from "./Pages/Admin/AdminAllOrdersPage";
import AdminOrderDetalisPage from "./Pages/Admin/AdminOrderDetalisPage";
import AdminAddbrandPage from "./Pages/Admin/AdminAddbrandPage";
import AdminAddCategoryPage from "./Pages/Admin/AdminAddCategoryPage";
import AdminAddSubCategoryPage from "./Pages/Admin/AdminSubCategoryPage";
import AdminAddProductsPage from "./Pages/Admin/AdminAddProductsPage";
import UserAllOrdersPage from "./Pages/User/UserAllOrdersPage";
import UserFavoriteProductPage from "./Pages/User/UserFavoriteProductPage";
import UserAllAddressPage from "./Pages/User/UserAllAddressPage";
import UserAddAddressPage from "./Pages/User/UserAddAddressPage";
import UserEditAddressPage from "./Pages/User/UserEditAddressPage";
import UserProfilePage from "./Pages/User/UserProfilePage";
import AdminEditProductsPage from "./Pages/Admin/AdminEditProductsPage";
import ViewSearchProductsHook from "./hook/products/view-search-product-hook";
import ForgetPasswordPage from "./Pages/Auth/ForgetPasswordPage";
import VerifyPasswordPage from "./Pages/Auth/VerifyPasswordPage";
import ResetPasswordPage from "./Pages/Auth/ResetPasswordPage";
import { useDispatch, useSelector } from "react-redux";
import AdminAddCouponPage from "./Pages/Admin/AdminAddCouponPage";
import { useUser } from "./context/getCurrentUser";
import AboutUs from "./Pages/elsePages/AboutUs";
import WarrantyPolicy from "./Pages/elsePages/WarrantyPolicy";
import ShippingDelivery from "./Pages/elsePages/ShippingDelivery";
import ReturnPolicy from "./Pages/elsePages/ReturnPolicy";
import FurnishingHomesAndProjects from "./Pages/elsePages/FurnishingHomesAndProjects";
import Privacy from "./Pages/elsePages/privacy";
import AdminAllUsersPage from "./Pages/Admin/AdminAllUsersPage";
import { createNewUser } from "./redux/actions/authAction";

function App() {
  const { user } = useUser();
 
  return (
    <div className="font" style={{ background: "#fff" }}>
      <NavBarLogin />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {localStorage.getItem("token") ? (
          <>
            {/* ... بقية الـuserRoutes ... */}
            <Route
              path="/login"
              element={<Navigate to="/user/profile" replace />}
            />
            <Route
              path="/register"
              element={<Navigate to="/user/profile" replace />}
            />
            <Route path="/user/allorders" element={<UserAllOrdersPage />} />
            <Route
              path="/user/favoriteproducts"
              element={<UserFavoriteProductPage />}
            />
            <Route path="/user/addresses" element={<UserAllAddressPage />} />
            <Route path="/user/add-address" element={<UserAddAddressPage />} />
            <Route
              path="/user/edit-address/:userAddress"
              element={<UserEditAddressPage />}
            />
            <Route path="/user/profile" element={<UserProfilePage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route
              path="/order/paymethoud"
              element={<ChoosePayMethoudPage />}
            />
          </>
        ) : (
          <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/user/allorders"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/user/favoriteproducts"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/user/addresses"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/user/add-address"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/user/edit-address"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/user/profile"
              element={<Navigate to="/login" replace />}
            />
            <Route path="/cart" element={<Navigate to="/login" replace />} />
            <Route
              path="/order/paymethoud"
              element={<Navigate to="/login" replace />}
            />
          </>
        )}
        <Route path="/allcategory" element={<AllCategoryPage />} />
        <Route path="/allbrand" element={<AllBrandPage />} />
        <Route path="/products" element={<ShopProducsPage />} />
        <Route path="/products/:id" element={<ProductDetalisPage />} />
        <Route
          path="/products/:categoryValue/:subcategoryValue"
          component={ViewSearchProductsHook}
        />
        <Route path="/products/:id" component={ProductDetalisPage} />

        {!user &&
          !localStorage.getItem("token") &&
          user &&
          user.data &&
          user.data.role !== "admin" && (
            <>
              <Route
                path="/admin/allproducts"
                element={<Navigate to="/" replace />}
              />
              <Route
                path="/admin/allorders"
                element={<Navigate to="/" replace />}
              />
              <Route
                path="/admin/allUsers"
                element={<Navigate to="/" replace />}
              />
              <Route
                path="/admin/orders/:id"
                element={<Navigate to="/" replace />}
              />
              <Route
                path="/admin/addbrand"
                element={<Navigate to="/" replace />}
              />
              <Route
                path="/admin/addcategory"
                element={<Navigate to="/" replace />}
              />
              <Route
                path="/admin/editproduct/:id"
                element={<AdminEditProductsPage />}
              />
              <Route
                path="/admin/addsubcategory"
                element={<AdminAddSubCategoryPage />}
              />
              <Route
                path="/admin/addproduct"
                element={<Navigate to="/" replace />}
              />
            </>
          )}
        {user && user.data && user.data.role === "admin" && (
          <>
            <Route
              path="/admin/allproducts"
              element={<AdminAllProductsPage />}
            />
            <Route path="/admin/allorders" element={<AdminAllOrdersPage />} />
            <Route
              path="/admin/orders/:id"
              element={<AdminOrderDetalisPage />}
            />
            <Route path="/admin/addbrand" element={<AdminAddbrandPage />} />
            <Route
              path="/admin/addcategory"
              element={<AdminAddCategoryPage />}
            />
            <Route path="/admin/allUsers" element={<AdminAllUsersPage />} />
            <Route path="/admin/addcoupon" element={<AdminAddCouponPage />} />
            <Route
              path="/admin/editproduct/:id"
              element={<AdminEditProductsPage />}
            />
            <Route
              path="/admin/addsubcategory"
              element={<AdminAddSubCategoryPage />}
            />
            <Route
              path="/admin/addproduct"
              element={<AdminAddProductsPage />}
            />
          </>
        )}

        <Route path="/user/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/user/verify-code" element={<VerifyPasswordPage />} />
        <Route path="/user/reset-password" element={<ResetPasswordPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/warranty-policy" element={<WarrantyPolicy />} />
        <Route path="/shipping-delivery" element={<ShippingDelivery />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
