import { CREATE_REVIEW,GET_ALL_REVIEWS,DELETE_REVIEW,UPDATE_REVIEW_ON_PRODUCT } from "../type";
import { useGetData } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import useDeleteData from './../../hooks/useDeleteData';
import { useUpdateData } from "../../hooks/useUpdateData";
//get all category
export const createReview = (prodID,body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/products/${prodID}/reviews`,body);
    dispatch({
      type: CREATE_REVIEW,
      payload: response,
    });
    
    return response
  } catch (e) {
    dispatch({
      type: CREATE_REVIEW,
      payload: e.response,
    });
  }
};




export const getAllReviews = (prodID,page,limit) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/products/${prodID}/reviews${page ? `?page=${page}` : ''}&limit=${limit}`);
    dispatch({
      type: GET_ALL_REVIEWS,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_REVIEWS,
      payload: e.response,
    });
  } 
};

export const deleteReview = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/reviews/${id}`);
    dispatch({
      type: DELETE_REVIEW,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: DELETE_REVIEW,
      payload: e.response,
    });
  } 
};
export const updateReviewOnProduct = (id,body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/reviews/${id}`,body);
    dispatch({
      type: UPDATE_REVIEW_ON_PRODUCT,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_REVIEW_ON_PRODUCT,
      payload: e.response,
    });
  } 
};

