import  { useState ,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { forgetPasswordUser } from '../../redux/actions/authAction'
import notify from '../useNotifaction'
import { useNavigate } from 'react-router-dom'

const ForgetPasswordHook = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState('')
  const [isPress, setIsPress] = useState(false)
  const onChangeEmail = (e)=>{
    setEmail(e.target.value)
  }
  const onSubmit =async ()=>{

    if(email === ""){
        notify('الرجاء ادخال البريد الالكتروني','error')
        return;
    }
    setLoading(true)
    setIsPress(true)
    await dispatch(forgetPasswordUser({email:email}))
    navigate('/user/verify-code', { state: { email } });

    setLoading(false)
    setIsPress(false)
  }
  const res = useSelector(state=>state.authReducer.forgetPassword)
  useEffect(()=>{
    if(loading === false){
        if(res){
            if(res.data){

                if(res.data.status === 'Success'){
                notify('تم ارسال الكود بنجاح','success')
                setTimeout(()=>{
                    navigate('/user/verify-code')
                },1000)
            }
            if(res.data.status === 'fail'){
                notify('هذا الحساب غير موجود لدينا','error')
            }
        }
        }
    }
  },[loading ])
  return [  email,
    onChangeEmail,
    loading,
    onSubmit,isPress]

}

export default ForgetPasswordHook