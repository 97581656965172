import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductsHome,
  getAllProductsHomeSort,
} from "../../redux/actions/productsAction";

const ViewHomeProductsHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      await Promise.all([
        dispatch(getAllProductsHome()),
        dispatch(getAllProductsHomeSort("-sold")),
      ]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, []); // Dependencies array is empty, so it runs only once during component mount

  const allProducts = useSelector((state) => state.allProducts.allProductsHome);
  const allProductsSort = useSelector(
    (state) => state.allProducts.allProductsHomeSort
  );

  const items = allProducts?.data?.slice(0,6) || [];
  const fullItems = allProducts?.data || [];
  const itemsSort = allProductsSort?.data?.slice(0, 6) || [];

  return [items, itemsSort, fullItems, loading];
};

export default ViewHomeProductsHook;
