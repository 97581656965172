import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import add from "../../images/add.png";
import Multiselect from "multiselect-react-dropdown";
import MultiImageInput from "react-multiple-image-input";
import { SketchPicker } from "react-color";
import "./delete-color.css";
import { ToastContainer } from "react-toastify";
import AdminEditProductsHook from "../../hook/products/edit-product-hook";
import { Editor } from "@tinymce/tinymce-react"; //1. Import TinyMCE Editor
import { X } from "phosphor-react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const AdminEditProducts = () => {
  const { id } = useParams();
  const [inStock, setInStock] = useState(true);
  const [selectedColor, setSelectedColor] = useState("");

  const [
    onChangeDesName,
    onChangeQty,
    onChangeColor,

    onChangeProdName,
    showColor,
    category,
    brand,
    images,
    setImages,
    onSelect,
    onRemove,
    options,
    onSelectCategory,
    handelSubmit,
    onSelectBrand,
    colors,
    qty,
    prodDescription,
    prodName,
    hideDeleteColorConfirmation,
    confirmDeleteColor,
    tempColor,
    setTempColor,
    showDeleteColorConfirmation,
    BrandID,
    CatID,
    onSaveButtonClick,
    isDeleteColorConfirmationVisible,
    setSelectedSubID,
    selectedSubID,
    setProdDescription,
    priceAfter,
    priceAfterDollar,
    priceBefore,
    priceDollar,
    onChangePriceAfter,
    onChangePriceAfterDollar,
    onChangePriceDollar,
    onChangePriceBefor,
    setDescImages,
    descImages,
    newSize,
    handleSizeChange,
    handleAddSize,
    sizes,
    handleDeleteSize,
    lowerStock,
    onChangeLowerStock,
    codeProd,
    onChangeCodeProd,
    setColors,
    setSizes,
    newQtySize,
    handleQtySizeChange,
    handleColorNameChange,
    colorName,
    setColorName,
    handleAddColorName,
  ] = AdminEditProductsHook(id, setInStock, inStock, selectedColor);
  const [prodDescriptiona, setProdDescriptiona] = useState("");
  const [bool, setBool] = useState(true);
  useEffect(() => {
    if (bool) {
      if (colors && colors.length == 1 && colors[0].color) {
        setSelectedColor(colors[0].color);
      }
      if (colors && colors.length > 1) {
        setSelectedColor(colors[0].color);
        setBool(false);
      }
    }
  }, [bool, colors]);
  const imgDescCon = async () => {
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }

    //convert url to file
    const convertURLtoFile = async (url) => {
      const response = await fetch(url, { mode: "cors" });
      const data = await response.arrayBuffer();
      const ext = url.split(".").pop();
      const filename = url.split("/").pop();
      const metadata = { type: `image/${ext}` };
      return new File([data], filename, metadata);
    };

    let imagesDesc = [];

    // استخدم Promise.all لانتظار تحميل جميع الصور
    Promise.all(
      Array.from(Array(Object.keys(images).length).keys()).map(
        (item, index) => {
          if (images[index].length <= 1000) {
            return convertURLtoFile(images[index]);
          } else {
            return Promise.resolve(
              dataURLtoFile(images[index], Math.random() + ".png")
            );
          }
        }
      )
    ).then((imageFiles) => {
      imagesDesc = imageFiles;
    });
    const updateDescriptionWithImages = () => {
      // قم بنسخ محتوى prodDescription لاستخدامه في الإضافة
      let updatedDescription = prodDescriptiona;

      // استدعي Promise.all لتحويل وإدراج الصور
      Promise.all(
        imagesDesc.map((imageFile) => {
          return new Promise(async (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              const dataURL = e.target.result;
              const file = dataURLtoFile(dataURL, imageFile.name);

              // استبدل أي رابط بالصورة الجديدة في الوصف
              updatedDescription = updatedDescription.replace(
                imageFile.url,
                URL.createObjectURL(file)
              );

              resolve();
            };

            reader.onerror = (error) => {
              console.error("Error reading image:", error);
              reject(error);
            };

            reader.readAsDataURL(imageFile);
          });
        })
      ).then(() => {
        // بمجرد إدراج الصور بنجاح، قم بتحديث prodDescription
        setProdDescriptiona(updatedDescription);
      });
    };

    // ابدأ عملية تحويل وإدراج الصور
    updateDescriptionWithImages();
  };
  const handleStockChange = (e) => {
    setInStock(e.target.value);
  };

  const [selectedSubNames, setSelectedSubNames] = useState([]);
  const dispatch = useDispatch();
  const [done, setDone] = useState(false);
  useEffect(() => {
    if (selectedSubID && selectedSubID.length > 0) {
      const updatedSelectedSubNames = selectedSubID
        .map((subID) => {
          return options.find((option) => option._id === subID);
        })
        .filter(Boolean);

      setSelectedSubNames(updatedSelectedSubNames);
      setDone(true);
    } else {
      setSelectedSubNames([]);
      setDone(false);
    }
  }, [selectedSubID, options]);

  const [newSelected, setNewSelected] = useState([...selectedSubNames]);

  useEffect(() => {
    // إذا تغير selectedSubID، قم بإزالة القيم التي تمثل نصوصًا وثم إضافته إلى newSelected بجانب selectedSubNames
    const filteredSubID = selectedSubID.filter(
      (item) => typeof item !== "string"
    );
    setNewSelected([...selectedSubNames, ...filteredSubID]);
  }, [selectedSubID, selectedSubNames]);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">تعديل المنتج - {prodName}</div>
        <Col sm="8">
          <div className="text-form pb-2"> صور للمنتج</div>

          <MultiImageInput
            allowCrop={false}
            images={images}
            setImages={setImages}
            theme={"light"}
            max={"30"}
          />

          <input
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder="اسم المنتج"
            value={prodName}
            onChange={onChangeProdName}
          />
          <input
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder="كود المنتج"
            value={codeProd}
            onChange={onChangeCodeProd}
          />
          <br />
          <Editor
            apiKey="7r3ddz9yloeafi51zmys1p21aa5jz31guzuhuu2eat2bnd7r"
            init={{
              plugins:
                "ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              mergetags_list: [
                { value: "First.Name", title: "First Name" },
                { value: "Email", title: "Email" },
              ],

              ai_request: (request, respondWith) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
            }}
            initialValue={prodDescription}
            onChange={onChangeDesName}
          />
          <MultiImageInput
            allowCrop={false}
            images={descImages}
            setImages={setDescImages}
            theme={"light"}
            max="7"
          />
          <input
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder="السعر بالتركي قبل الخصم"
            value={priceBefore}
            onChange={onChangePriceBefor}
          />
          <input
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder="السعر بالتركي بعد الخصم"
            value={priceAfter}
            onChange={onChangePriceAfter}
          />
          <input
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder="سعر بالدولار قبل الخصم"
            value={priceDollar}
            onChange={onChangePriceDollar}
          />
          <input
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder="سعر بالدولار بعد الخصم"
            value={priceAfterDollar}
            onChange={onChangePriceAfterDollar}
          />
          <label
            htmlFor="sizeInput"
            style={{
              margin: "10px 0",
            }}
          >
            حجم المنتج:
          </label>

          <select
            name="cat"
            className="select input-form-area mt-3 px-2 "
            style={{ marginBottom: "15px" }}
            onChange={(e) => onSelectCategory(e.target.value)}
            value={CatID}
          >
            <option value="noneCat">اختر تصنيف رئيسي</option>
            {category.data && category.data.length > 0
              ? category.data.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))
              : null}
          </select>
          <Multiselect
            key={newSelected.length} // قم بتغيير المفتاح عند تحديث selectedSubNames
            className="mt-2 text-end"
            placeholder="اختر التصنيف الفرعي"
            options={options}
            selectedValues={newSelected} // استخدم selectedSubNames بدلاً من selectedSubID
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="name"
          />
          <select
            name="inStock"
            id="inStock"
            onChange={handleStockChange} // اتصال الدالة بـ onChange
            value={inStock}
            className="select input-form-area mt-3 px-2 "
          >
            <option value={true}>في المخزون</option>
            <option value={false}>انتهت من المخزون</option>
          </select>
          <select
            name="inStock"
            className="select input-form-area mt-3 px-2 "
            id="inStock"
            onChange={onChangeLowerStock}
            value={lowerStock}
          >
            <option value={true}>بقي القليل في المخزون</option>
            <option value={false}>يوجد في المخزون </option>
          </select>

          {/* <select
            name="brand"
            id="brand"
            className="select input-form-area mt-3 px-2 "
            onChange={(e) => onSelectBrand(e.target.value)}
            value={BrandID}
          >
            <option value="0">اختر ماركة</option>
            {brand.data && brand.data.length > 0
              ? brand.data.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))
              : null}
          </select> */}
          <input
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder="الكمية المتاحة"
            value={qty}
            onChange={onChangeQty}
          />
          <div className="text-form mt-3 "> الالوان المتاحه للمنتج</div>
          <div
            className="mt-1 d-flex"
            style={{ flexWrap: "wrap", gap: "11px", marginBottom: "12px" }}
          >
            {colors
              ? colors.map((co, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    // className="color-box"
                  >
                    <div
                      key={index}
                      onClick={() => showDeleteColorConfirmation(co.color)}
                      className=" ms-2 border  mt-1"
                      style={{
                        backgroundColor: `${co.color}`,
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <span>اسم اللون : {co.colorName}</span>
                  </div>
                ))
              : null}

            <img
              src={add}
              onClick={onChangeColor}
              alt=""
              width="30px"
              height="35px"
              style={{ cursor: "pointer" }}
            />
            {showColor ? (
              <div>
                <SketchPicker
                  onChangeComplete={(color) => setTempColor(color.hex)} // حفظ اللون المؤقت عند التغيير
                  color={tempColor}
                />
                <button onClick={onSaveButtonClick} className="btn-save mt-2">
                  حفظ
                </button>
              </div>
            ) : null}
          </div>
          {colors && colors.length > 0 && (
            <>
              <select
                style={{ width: "100%", padding: "10px 14px" }}
                onChange={(e) => {
                  const selectedColorValue = e.target.value;
                  setSelectedColor(selectedColorValue);
                  // استخدام القيمة المباشرة لتحديد اللون الحالي
                  setSizes(
                    (prev) =>
                      colors.find((co) => co.color === selectedColorValue)
                        ?.sizes ||
                      prev ||
                      []
                  );
                  setColorName(
                    (prev) =>
                      colors.find((co) => co.color === selectedColorValue)
                        ?.colorName || " "
                  );
                }}
              >
                {colors
                  ? colors.map((co, index) => (
                      <option
                        key={index}
                        onClick={() => showDeleteColorConfirmation(co.color)}
                        className="color ms-2 border  mt-1"
                        style={{
                          backgroundColor: `${co.color}`,
                          padding: "10px 14px",
                        }}
                      >
                        {co.color}
                      </option>
                    ))
                  : null}
              </select>
              <br />

              <label
                htmlFor="colorName"
                style={{
                  margin: "10px 0",
                }}
              >
                اسم اللون:
              </label>
              <input
                type="text"
                id="colorName"
                value={colorName}
                onChange={handleColorNameChange}
                placeholder="ادخل اسم اللون "
                className="input-form d-block mt-3 px-3"
              />
              <button onClick={handleAddColorName} className="btn-save mt-2">
                اضافة اسم للون
              </button>
              <label
                htmlFor="sizeInput"
                style={{
                  margin: "10px 0",
                }}
              >
                حجم المنتج:
              </label>
              <input
                type="text"
                id="sizeInput"
                value={newSize}
                onChange={handleSizeChange}
                placeholder="ادخل حجم"
                className="input-form d-block mt-3 px-3"
              />
            </>
          )}
          {newSize && (
            <>
              <input
                type="number"
                id="sizeInput"
                value={newQtySize}
                onChange={handleQtySizeChange}
                placeholder="ادخل الكمية المتوفرة لهذا الحجم"
                className="input-form d-block mt-3 px-3"
              />
              <button onClick={handleAddSize} className="btn-save mt-2">
                اضافة مقاس مع العدد
              </button>
            </>
          )}
          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {sizes &&
              sizes.length > 0 &&
              sizes.map((size, index) => (
                <div className="remove-size" key={index}>
                  {size.size}

                  <span style={{ color: "red", paddingRight: "5px" }}>
                    {size.quantity}
                  </span>
                  <button
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      color: "red",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteSize(index)}
                  >
                    <X />
                  </button>
                </div>
              ))}
          </div>
        </Col>
      </Row>

      {isDeleteColorConfirmationVisible && (
        <div className="delete-color-confirmation">
          <p>هل تريد حقًا حذف هذا اللون؟</p>
          <div>
            <button onClick={confirmDeleteColor}>نعم</button>
            <button onClick={hideDeleteColorConfirmation}>لا</button>
          </div>
        </div>
      )}
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={handelSubmit} className="btn-save d-inline mt-2">
            حفظ التعديلات
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminEditProducts;
