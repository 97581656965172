import React from "react";
import Slider from "../../Components/Home/Slider";
import HomeCategory from "../../Components/Home/HomeCategory";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import ViewHomeProductsHook from "../../hook/products/view-home-products-hook";
import IconsSectionHome from "../../Components/Home/IconsSectionHome";
import ReviewsSection from "../../Components/Home/ReviewsSection";
import { Helmet } from "react-helmet";
import AdCom from "../../Components/AdsCompnents/AdCom";
const Home = () => {
  const [items, itemsSort, fullItems, loading] = ViewHomeProductsHook();
  console.log(fullItems)
  return (
    <div className="font min-height-e">
      <Helmet>
        <title>الصفحة الرئيسية - Kiwi Fashion</title>
        <meta
          name="description"
          content="تسوق احدث وافضل الملابس العربية والتركية من شركة كيوي - Kiwi Fashion"
        />
        <meta property="og:title" content={`الصفحة الرئيسية في Kiwi Fashion`} />
        <meta
          property="og:description"
          content={`تسوق افضل الالبسة من شركة كيوي مع شحن الى جميع انحاء العالم`}
        />
        <meta
          property="og:image"
          content={`https://www.kiwifashion.net/logo-ki.jpeg`}
        />
        <meta property="og:url" content={`https://www.kiwifashion.net`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Slider />
      <HomeCategory />
      <CardProductsContainer
        loading={loading}
        gridfour={true}
        products={itemsSort}
        title={"الاكثر مبيعا"}
        btntitle={"المزيد"}
        pathText={"/products"}
      />
      {/* <DiscountSection /> */}
      <br />
      <br />
      <CardProductsContainer
        loading={loading}
        gridfour={true}
        products={items}
        title={"احدث المنتجات"}
        btntitle={"المزيد"}
        pathText={"/products"}
      />
      {/* <ImageHome
        src={sandalye}
        hrefImg={"/products?category=6502f2eacbe853c23f66d560"}
      /> */}
      {/* <CardProductsContainer
        loading={loading}
        gridfour={true}
        products={fullItems}
        cat={"658811a08997d1ce72815349"}
        title={"حجابات وشالات "}
        btntitle={"المزيد"}
        pathText={"/products?category=658811a08997d1ce72815349"}
      /> */}

      <CardProductsContainer
        loading={loading}
        gridfour={true}
        products={fullItems}
        title={"سبورات وجواكيت"}
        btntitle={"المزيد"}
        cat={"6588115f8997d1ce72815340"}
        pathText={"/products?category=6588115f8997d1ce72815340"}
      />
      <CardProductsContainer
        loading={loading}
        gridfour={true}
        products={fullItems}
        cat={"6587ff1788fe28029202411a"}
        title={"مكياج وهدايا وعطور "}
        btntitle={"المزيد"}
        pathText={"/products?category=6587ff1788fe28029202411a"}
      />
      {/* <ImageHome
        src={kaneb}
        hrefImg={"/products?category=6502f2d7cbe853c23f66d55a"}
      /> */}
      <CardProductsContainer
        loading={loading}
        gridfour={true}
        products={fullItems}
        title={"مانطو وترانشكوت "}
        btntitle={"المزيد"}
        cat={"658811788997d1ce72815343"}
        pathText={"/products?category=658811788997d1ce72815343"}
      />
      <CardProductsContainer
        loading={loading}
        gridfour={true}
        products={fullItems}
        title={"عبايات وكلابيات "}
        btntitle={"المزيد"}
        cat={"658811878997d1ce72815346"}
        pathText={"/products?category=658811878997d1ce72815346"}
      />
      {/* <ImageHome
        src={masa}
        hrefImg={"/products?category=6502f2e1cbe853c23f66d55d"}
      /> */}

      {/* <CardProductsContainer
        gridfour={true}
        products={fullItems}
        title={"توينزات"}
        btntitle={"المزيد"}
        cat={"657db213a367caf134609571"}
        pathText={"/products?category=657db213a367caf134609571"}
      />
      <CardProductsContainer
        gridfour={true}
        products={fullItems}
        title={"سبورات وجواكيت"}
        btntitle={"المزيد"}
        cat={"657db1afa367caf13460956e"}
        pathText={"/products?category=657db1afa367caf13460956e"}
      /> */}
      <AdCom slot="2496514241" />

      <ReviewsSection />
      <IconsSectionHome />
    </div>
  );
};

export default Home;
