import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOneAddress, updateAddress } from '../../redux/actions/userAdressesAction'
import notify from '../useNotifaction'
import { useParams } from 'react-router-dom'

const UpdateAddressHook = () => {
    const {userAddress} = useParams()
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(true)
    const [loadingEdit,setLoadingEdit] = useState('')
    const [item,setItem] = useState([])
    useEffect(()=>{
      const fetch = async()=>{
        setLoading(true)
        await dispatch(getOneAddress(userAddress))
        setLoading(false)
      }
      fetch()
    },[])
    const res = useSelector(state=>state.userAddressReducer.getOneAddress)
    const [alias,setAlias] = useState('')
    const [details,setDetails] = useState('')
    const [phone,setPhone] = useState('')
    const [country,setCountry] = useState('')
    const [city,setCity] = useState('')
    const [postalCode,setPostalCode] = useState('')
  
    const onChangeAlias = (e)=>{
        setAlias(e.target.value)
    }
    const onChangeDetails = (e)=>{
        setDetails(e.target.value)
    }
    const onChangePhone = (e)=>{
        setPhone(e)
    }
    const onChangePostalCode = (e)=>{
        setPostalCode(e.target.value)
    }
    const onChangeCity = (e)=>{
        setCity(e.target.value)
    }
    const onChangeCountry = (e)=>{
        setCountry(e.target.value)
    }
    useEffect(()=>{
      if(res && res.data){
        setItem(res)
      }
    },[res])
  
    useEffect(()=>{
      if(item && item.data){
        setAlias(item.data.alias)
        setDetails(item.data.details)
        setPhone(item.data.phone)
        setCountry(item.data.country)
        setCity(item.data.city)
        setPostalCode(item.data.postalCode)
      }
    },[item])
  
    const onSubmit = async()=>{
  
      setLoadingEdit(true)
      await dispatch(updateAddress(userAddress,{
        alias:alias,
        city:city,
        country:country,
        details:details,
        phone:phone,
        postalCode:postalCode,
      }))
      setLoadingEdit(false)
    }
    const resEdit = useSelector(state => state.userAddressReducer.updateAddress)
    useEffect(()=>{
      if(loadingEdit === false){
        if(resEdit){
          if(resEdit.status === 200){
            notify('تم تحديث العنوان بنجاح','success')
            setTimeout(()=>{
              window.location.replace('/user/addresses')
            },1500)
          }else {
            notify('هناك مشكلة في تحديث العنوان','error')
  
          }
        }
      }
    },[loadingEdit])

    return [
        alias,details,phone,country,city,postalCode,
        onChangeAlias,onChangeDetails,onChangePhone,
        onChangeCountry,onChangeCity,onChangePostalCode,
        onSubmit,loadingEdit
    ]
}

export default UpdateAddressHook