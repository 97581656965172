import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import notify from './../useNotifaction';
import { addUserAddress } from '../../redux/actions/userAdressesAction';

const AddAddressHook = () => {

    const dispatch = useDispatch()
    const [alias,setAlias] = useState('')
    const [details,setDetails] = useState('')
    const [phone,setPhone] = useState('')
    const [country,setCountry] = useState('')
    const [city,setCity] = useState('')
    const [postalCode,setPostalCode] = useState('')
    const [loading,setLoading] = useState(true)

    const onChangeAlias = (e)=>{
        setAlias(e.target.value)
    }
    const onChangeDetails = (e)=>{
        setDetails(e.target.value)
    }
    const onChangePhone = (e)=>{
        setPhone(e)
    }
    const onChangePostalCode = (e)=>{
        setPostalCode(e.target.value)
    }
    const onChangeCity = (e)=>{
        setCity(e.target.value)
    }
    const onChangeCountry = (e)=>{
        setCountry(e.target.value)
    }

    const onSubmit = async()=>{
        if(alias === '' || details === '' || phone === ''){
            notify('يجب عليك تعبئة جميع الحقول','warn')
            return;
        }
        setLoading(true)
        await dispatch(addUserAddress({
            alias:alias,
            details:details,
            phone:phone,
            country:country,
            city:city,
            postalCode:postalCode,
        }))
        setLoading(false)
    }
    const res = useSelector(state=>state.userAddressReducer.addUserAddress)
    useEffect(()=>{
        if(loading === false){
            if(res){
                if(res && res.status === 200){
                    notify('تمت عملية الاضافة بنجاح','success')
                    setAlias('')
                    setDetails('')
                    setPhone('')
                    setCountry('')
                    setCity('')
                    setPostalCode(0)
                    setTimeout(()=>{
                        window.location.replace('/user/addresses')
                    },1000)

                }else {
                    notify('هناك مشكلة في عملية الاضافة','error')

                }
            }
       
        }

    },[loading])

    return [country,onChangeCountry,postalCode,onChangePostalCode,city,onChangeCity,alias,onChangeAlias,details,onChangeDetails,phone,onChangePhone,loading,onSubmit]
}

export default AddAddressHook