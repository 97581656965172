import React, { Suspense, lazy, useEffect, useState } from "react";
import CategoriesHeader from "../../Components/Category/CategoriesHeader";
import { Container } from "react-bootstrap";
import ProductDetails from "../../Components/Products/ProductDetails";
import RateContainer from "../../Components/Rate/RateContainer";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import { useParams } from "react-router-dom";
import ProductDesc from "../../Components/Products/ProductDesc";
import ViewProductDetailsHook from "../../hook/products/view-product-details-hook";
import ViewLikeProductHook from "../../hook/products/view-like-product-hook";
import { getProductLike } from "../../redux/actions/likeAction";
import { useDispatch, useSelector } from "react-redux";
import FiveAd from "../../Components/AdsCompnents/5Ad";
import ThirthAd from "../../Components/AdsCompnents/3Ad";
import FourthAd from "../../Components/AdsCompnents/4Ad";
import AdCom from "../../Components/AdsCompnents/AdCom";

const ProductDetalisPage = () => {
  let { id } = useParams();

  const [item] = ViewProductDetailsHook(id);

  const dispatch = useDispatch();
  const [loadingL, setLoadingL] = useState(true);
  // حالة لتخزين key تمثل المنتج الحالي

  // قم بتحميل البيانات الخاصة بـ `likeProduct` عند تحديث categoryId و id
  useEffect(() => {
    const fet = async () => {
      if (item.category) {
        setLoadingL(true);
        await dispatch(getProductLike(item.category));
        setLoadingL(false);
      }
    };
    fet();
  }, [item.category]);

  const productLike = useSelector((state) => state.likeProducts.productLike);

  let prod = [];

  if (productLike && productLike.data) {
    // قم بتصفية قائمة المنتجات المشابهة لإخفاء العناصر التي تحمل نفس item._id
    prod = productLike.data.filter((product) => product._id !== item._id);
  }
  if (item) {
    var rateAvg = item.ratingsAverage;
    var rateQty = item.ratingsQuantity;
  }
  return (
    <div style={{ margin: "auto", overflow: "hidden" }}>
      <CategoriesHeader />
      <div className="container-product">
        <ProductDetails />
        <ProductDesc />
        <RateContainer rateAvg={rateAvg} rateQty={rateQty} />
        {/* استخدم المصفوفة prod بدلاً من productLike.data */}
        <CardProductsContainer
          title={"منتجات قد تعجبك"}
          loading={loadingL}
          products={prod.slice(0, 6)}
        />
        {/* <AdCom slot={'6442578242'} keyS={'+2h+qa-30-3m+q3'} fuild={true} /> */}
        <AdCom slot="7372516538" />
      </div>
    </div>
  );
};

export default ProductDetalisPage;
