/* eslint-disable react-hooks/rules-of-hooks */
import {
  APPLY_COUPON_CART,
  UPDATE_CART_ITEM,
  DELETE_CART_ITEM,
  ADD_TO_CART,
  GET_ALL_CART_ITEMS,
  DELETE_ALL_CART_ITEMS,
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetDataToken } from "./../../hooks/useGetData";
import useDeleteData from "./../../hooks/useDeleteData";
import { useUpdateData } from "./../../hooks/useUpdateData";
export const addProductToCart = (data) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/cart", data);
    dispatch({
      type: ADD_TO_CART,
      payload: response,
      loading: true,
    });

    return response;
  } catch (e) {
    dispatch({
      type: ADD_TO_CART,
      payload: e.response,
    });
  }
};
export const getAllCartItems = () => async (dispatch) => {
  try {
    const response = await useGetDataToken("/api/v1/cart");
    dispatch({
      type: GET_ALL_CART_ITEMS,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_CART_ITEMS,
      payload: e.response,
    });
  }
};
export const clearAllCartItems = () => async (dispatch) => {
  try {
    const response = await useDeleteData("/api/v1/cart");
    dispatch({
      type: DELETE_ALL_CART_ITEMS,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: DELETE_ALL_CART_ITEMS,
      payload: e.response,
    });
  }
};
export const clearCartItem = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/cart/${id}`);

    dispatch({
      type: DELETE_CART_ITEM,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: DELETE_CART_ITEM,
      payload: e.response,
    });
  }
};
export const updateCartItem = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/cart/${id}`, body);

    dispatch({
      type: UPDATE_CART_ITEM,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: UPDATE_CART_ITEM,
      payload: e.response,
    });
    return e.response;
  }
};
export const applyCouponCart = (body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/cart/applyCoupon`, body);

    dispatch({
      type: APPLY_COUPON_CART,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: APPLY_COUPON_CART,
      payload: e.response,
    });
  }
};
