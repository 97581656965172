import {APPLY_COUPON_CART,UPDATE_CART_ITEM,DELETE_CART_ITEM,DELETE_ALL_CART_ITEMS, ADD_TO_CART,GET_ALL_CART_ITEMS} from "../type";

const inital = {
  addProductToCart: [],
  getAllCartItems: [],
  deleteAllCartItems:[],
  deleteCartItem:[],
  updateCartItem:[],
  applyCouponCart:[],
  loading: true,
};
const cartReducer = (state = inital, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        addProductToCart: action.payload,
        loading: false,
      };
    case GET_ALL_CART_ITEMS:
      return {
        ...state,
        getAllCartItems: action.payload,
        loading: false,
      };
    case DELETE_ALL_CART_ITEMS:
      return {
        ...state,
        deleteAllCartItems: action.payload,
        loading: false,
      };
    case DELETE_CART_ITEM:
      return {
        ...state,
        deleteCartItem: action.payload,
        loading: false,
      };
    case UPDATE_CART_ITEM:
      return {
        ...state,
        updateCartItem: action.payload,
        loading: false,
      };
    case APPLY_COUPON_CART:
      return {
        ...state,
        applyCouponCart: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default cartReducer;
