import React from "react";
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import UserSideBar from "../../Components/User/UserSideBar";
import UserAllAddress from './../../Components/User/UserAllAddress';

const UserAllAddressPage = () => {
  return (
    <div style={{width:'90%',margin:'0 auto'}}>
      <Row className="py-3">
        <Col  md="3">
          <UserSideBar />
        </Col>
        <Col sm="12" xs="12" md="9">
          <UserAllAddress />
        </Col>
      </Row>
    </div>
  );
};

export default UserAllAddressPage