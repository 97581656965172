/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Instagram,
  Facebook,
  SearchOutlined,
  MenuOutlined,
  CloseSharp,
  AddOutlined,
  RemoveOutlined,
} from "@mui/icons-material";
import logo from "../../images/logo-ki.jpeg";
import "./navbar.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoryNav } from "../../redux/actions/categoryAction";
import { getAllSubCategoryNav } from "../../redux/actions/subCategoryAction";
import navbarSearchHook from "../../hook/search/navbar-search-hook";
import { NavDropdown } from "react-bootstrap";
import { getProductWishList } from "../../redux/actions/wishlistAction";
import { useUser } from "../../context/getCurrentUser";
import { useChange } from "../../context/ChangeContext";
import GetAllUserCartHook from "../../hook/cart/get-all-user-cart-hook";
import {
  HeartBreak,
  HeartStraight,
  House,
  ShoppingCart,
  TelegramLogo,
  TiktokLogo,
  User,
} from "phosphor-react";
import { useChangeWishlist } from "../../context/ChangeWishlist";
import { useWishlist } from "../../context/WishlistRes";
import Skeleton from "react-loading-skeleton";

const NavBarLogin = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeAccordions, setActiveAccordions] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [searchWord, onChangeSearch, onSubmitSearch] = navbarSearchHook();

  let word = "";

  const dispatch = useDispatch();
  const [allSubCategory, setAllSubCategory] = useState([]);

  const { user, loading } = useUser();

  const logoutFn = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen((prevState) => !prevState);
    setActiveAccordions([]); // إغلاق جميع العناصر عند فتح القائمة المحمولة
  }, []);

  const handleAccordionClick = useCallback(
    (index) => {
      setActiveAccordions((prevState) => {
        if (prevState.includes(index)) {
          return prevState.filter((item) => item !== index);
        } else {
          return [...prevState, index];
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [activeAccordions]
  );

  const fetchCategoriesAndSubcategories = useCallback(async () => {
    await dispatch(getAllCategoryNav());
    let res = await dispatch(getAllSubCategoryNav());
    if (res && res.data) {
      setAllSubCategory(res.data);
    }
  }, [dispatch]);
  useEffect(() => {
    fetchCategoriesAndSubcategories();
  }, [fetchCategoriesAndSubcategories]);
  const categories = useSelector((state) => state.allCategory.navCategories);

  const allCategory = useMemo(() => categories?.data || [], [categories]);
  const filteration = (category) => {
    if (allSubCategory && allSubCategory.length) {
      const filteredSubCategories = allSubCategory
        ? allSubCategory.filter((item) => item.category === category._id)
        : [];

      if (filteredSubCategories.length > 0) {
        return filteredSubCategories.slice(0, 5).map((li, index) => (
          <li className="panel-list-item" key={index}>
            <a
              href={`/products?category=${category._id}&subcategory=${li._id}`}
            >
              {li.name}
            </a>
          </li>
        ));
      } else {
        <li className="panel-list-item" key={category._id}>
          Soon
          <a href="#">
            <img
              src={category.image}
              width="250"
              height="119"
              alt={category.name}
            />
          </a>
        </li>;
      }
    } else {
      return;
    }
  };
  const filteration2 = (category) => {
    if (allSubCategory && allSubCategory.length) {
      const filteredSubCategories = allSubCategory
        ? allSubCategory.filter((item) => item.category === category._id)
        : [];

      if (filteredSubCategories.length > 0) {
        return filteredSubCategories.slice(0, 5).map((li, index) => (
          <li className="submenu-category" key={index}>
            <a href="#" className={"submenu-title"}>
              {li.name}
            </a>
          </li>
        ));
      } else {
        return;
      }
    } else {
      return;
    }
  };
  if (localStorage.getItem("searchWord")) {
    word = localStorage.getItem("searchWord");
  }
  const dropdownMenu = useMemo(() => {
    return (
      <NavDropdown
        id="nav-dropdown-light-example"
        title={user && user.data && user.data.name}
        menuVariant="light"
      >
        <NavDropdown.Item href="/user/profile">حسابي</NavDropdown.Item>
        {/* <NavDropdown.Item href="#action/3.2">مساعدة</NavDropdown.Item> */}
        {user && user.data ? (
          user.data.role === "admin" ? (
            <NavDropdown.Item href="/admin/allorders">
              لوحة التحكم
            </NavDropdown.Item>
          ) : null
        ) : null}
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={logoutFn}>تسجيل الخروج</NavDropdown.Item>
      </NavDropdown>
    );
  }, [user]);
  const dropdownMenuMobile = useMemo(() => {
    return (
      <div className="dropdown-mobile">
        <NavDropdown
          id="nav-dropdown-light-example khara"
          title={user && user.data && user.data.name}
          menuVariant="light"
        >
          <NavDropdown.Item
            href="/user/profile"
            className="item-navdrop-mobile"
          >
            حسابي
          </NavDropdown.Item>
          {user && user.data ? (
            user.data.role === "admin" ? (
              <NavDropdown.Item href="/admin/allorders">
                لوحة التحكم
              </NavDropdown.Item>
            ) : null
          ) : null}
          {/* <NavDropdown.Item href="#action/3.2">مساعدة</NavDropdown.Item> */}
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={logoutFn}>تسجيل الخروج</NavDropdown.Item>
        </NavDropdown>
      </div>
    );
  }, [user]);
  const { changeWishlist, updateChangeWishlist } = useChangeWishlist();
  const { res: resWishlist } = useWishlist();

  const [favProd, setFavProd] = useState([]);
  useEffect(() => {
    if (resWishlist && resWishlist.data) {
      setFavProd(resWishlist.data);
    }
  }, [resWishlist, changeWishlist]);
  const [itemsNum] = GetAllUserCartHook();
  const handleScrollToBottom = () => {
    // يقوم بتمرير الصفحة إلى أسفل
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <header role="navigation">
      <div className="header-top">
        <div className="container">
          <ul className="header-social-container">
            {/* <li>
              <a href="#" className="social-link">
                <Facebook />
              </a>
            </li> */}
            <li>
              <a href="https://t.me/Rafifashion1" className="social-link">
                <TelegramLogo />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@kiwi.fashion1"
                className="social-link"
              >
                <TiktokLogo />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/kiwi.fashion1/"
                className="social-link"
              >
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/KiWicompany0?mibextid=JRoKGi"
                className="social-link"
              >
                <Facebook />
              </a>
            </li>
          </ul>
          <div className="header-alert-news">
            <p>
              <b style={{ color: "var(--salmon-green)" }}> توصيل سريع </b>
              في اسطنبول
            </p>
          </div>
          {/* <div className="header-top-actions">
            <select name="currency">
              <option value="tl">الليرة التركية</option>
              <option value="usd">الدولار الامريكي</option>
            </select>
            <select name="language">
              <option value="tr">التركية</option>
              <option value="ar">العربية</option>
            </select>
          </div> */}
        </div>
      </div>
      <div className="header-main">
        <div className="container">
          <a className="header-logo" href="/">
            <img src={logo} alt="logo" width="120" height="100" />
          </a>
          <div className="header-search-container">
            <input
              type="search"
              name="search"
              value={word}
              onChange={onChangeSearch}
              className="search-field"
              placeholder="ابحث عن منتجاتك"
            />
            <button onClick={onSubmitSearch} className="search-btn">
              <SearchOutlined />
            </button>
          </div>
          <div className="header-user-actions">
            <button
              className={`action-btn ${user && user.data ? "logged" : ""}`}
            >
              {localStorage.getItem("token") ? (
                user && user.data ? (
                  loading === true ? (
                    <Skeleton
                      width={46}
                      height={45}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    dropdownMenu
                  )
                ) : localStorage.getItem("token") && loading === true ? (
                  <Skeleton
                    width={46}
                    height={45}
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <a
                    href="/login"
                    style={{ fontSize: "40px" }}
                    className="material-symbols-outlined user-icon-nav"
                  >
                    <User />
                  </a>
                )
              ) : (
                <a
                  href="/login"
                  style={{ fontSize: "40px" }}
                  className="material-symbols-outlined user-icon-nav"
                >
                  <User />
                </a>
              )}
            </button>
            <button className="action-btn">
              <a href="/user/favoriteproducts">
                <HeartStraight
                  id={"heart-desktop"}
                  style={{ marginTop: "6px !important" }}
                />
                <h6 className="count">
                  {favProd && favProd.length > 0 ? favProd.length : "0"}
                </h6>
                <div className="count">
                  <span>{`${
                    favProd && favProd.length > 0 ? favProd.length : "0"
                  }`}</span>
                </div>
              </a>
            </button>

            <button className="action-btn">
              <a href="/cart">
                <ShoppingCart id={"shopping-bag"} />
                <div className="count">
                  <span>{`${itemsNum}`}</span>
                </div>{" "}
              </a>
            </button>
          </div>
        </div>
      </div>
      <nav className="desktop-navigation-menu">
        <div className="container">
          <ul className="desktop-menu-category-list">
            <li className="menu-category">
              <a href="/" className="menu-title">
                الصفحة الرئيسية
              </a>
            </li>
            <li className="menu-category">
              <a href="/products" className="menu-title">
                المتجر
              </a>
              <div className="dropdown-panel">
                {allCategory && allCategory.length
                  ? allCategory.slice(0, 4).map((item, index) => (
                      <ul key={index} className="dropdown-panel-list">
                        <li className="menu-title">
                          <a href={`/products?category=${item._id}`}>
                            {item.name}
                          </a>
                        </li>
                        {allSubCategory ? filteration(item) : null}
                        <li className="panel-list-item">
                          <a href={`/products?category=${item._id}`}>
                            <img
                              src={item.image}
                              width="250"
                              height="119"
                              alt={item.name}
                            />
                          </a>
                        </li>
                      </ul>
                    ))
                  : null}
              </div>
            </li>
            <li className="menu-category">
              <a href="/warranty-policy" className="menu-title">
                سياسة الضمان
              </a>
            </li>
            {/* <li className="menu-category">
              <a
                href="/products?category=6502f2e1cbe853c23f66d55d"
                className="menu-title"
              >
                طاولات
              </a>
            </li> */}
            <li className="menu-category">
              <a href="/about-us" className="menu-title">
                من نحن
              </a>
            </li>
            <li className="menu-category">
              <a href="#" className="menu-title" onClick={handleScrollToBottom}>
                اتصل بنا
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="mobile-bottom-navigation">
        <button
          className="action-btn"
          onClick={toggleMobileMenu}
          data-mobile-menu-open-btn
        >
          {isMobileMenuOpen ? <CloseSharp /> : <MenuOutlined />}
        </button>
        <a href="/cart" className="action-btn">
          <ShoppingCart id={"shopping-bag"} name="menu-outline" />
          <div className="count">
            <span>{`${itemsNum}`}</span>
          </div>
        </a>
        <button className="action-btn">
          <a href="/">
            <House size={27} />
          </a>
        </button>
        <a href={"/user/favoriteproducts"} className="action-btn">
          <HeartStraight name="menu-outline" id={"heart-desktop"} />
          <div className="count">
            <span>{`${
              favProd && favProd.length > 0 ? favProd.length : "0"
            }`}</span>
          </div>
        </a>
        <button className="action-btn">
          {localStorage.getItem("token") ? (
            user && user.data ? (
              dropdownMenuMobile
            ) : loading === true ? (
              <Skeleton
                width={46}
                height={45}
                style={{ borderRadius: "50%" }}
              />
            ) : (
              <a
                style={{
                  border: "1px solid #777",
                  width: "46px",
                  height: "45px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                href="/login"
                className="user-icon-nav-mobile"
              >
                <User style={{ fontSize: "22px" }} />
              </a>
            )
          ) : loading === true ? (
            <Skeleton width={46} height={45} style={{ borderRadius: "50%" }} />
          ) : (
            <a
              style={{
                border: "1px solid #777",
                width: "46px",
                height: "45px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              href="/login"
              className="user-icon-nav-mobile"
            >
              <User style={{ fontSize: "22px" }} />
            </a>
          )}
        </button>
      </div>
      <nav
        className={`mobile-navigation-menu ${
          isMobileMenuOpen ? "active" : ""
        } has-scrollbar`}
        data-mobile-menu
      >
        <div className="menu-top">
          <h2 className="menu-title">القائمة</h2>
          <button
            className="menu-close-btn"
            data-mobile-menu-close-btn
            onClick={toggleMobileMenu}
          >
            <CloseSharp />
          </button>
        </div>

        <ul className="mobile-menu-category-list">
          <li className="menu-category">
            <a href="/" className="menu-title">
              الصفحة الرئيسية
            </a>
          </li>
          {allCategory && allCategory.length
            ? allCategory.slice(0, 4).map((item, index) => (
                <li className="menu-category" key={index}>
                  <button
                    className={`accordion-menu ${
                      activeAccordions.includes(index) ? "active" : ""
                    }`}
                    data-accordion-btn
                    onClick={() => handleAccordionClick(index)}
                  >
                    {" "}
                    <a href={`/products?category=${item._id}`}>
                      <p className="menu-title">{item.name}</p>
                    </a>
                    <div>
                      <AddOutlined className="add-icon" />
                      <RemoveOutlined className="remove-icon" />
                    </div>
                  </button>
                  <ul
                    className={`submenu-category-list ${
                      activeAccordions.includes(index) ? "active" : ""
                    }`}
                  >
                    {filteration2(item)}
                  </ul>
                </li>
              ))
            : null}

          {/* <li className="menu-category">
            <a href="#" className="menu-title">
              المدنة
            </a>
          </li> */}

          <li className="menu-category">
            <a href="/products" className="menu-title">
              المتجر
            </a>
          </li>
          <li className="menu-category">
            <a href="/warranty-policy" className="menu-title">
              سياسة الضمان
            </a>
          </li>
          <li className="menu-category">
            <a href="/about-us" className="menu-title">
              من نحن
            </a>
          </li>
          <li className="menu-category">
            <a href="#" className="menu-title" onClick={handleScrollToBottom}>
              اتصل بنا
            </a>
          </li>
        </ul>
        <div className="menu-bottom">
          {/* <ul className="menu-category-list"> */}
          {/* <li className="menu-category">
              <button
                className={`accordion-menu ${
                  activeAccordions.includes(1000) ? "active" : ""
                }`}
                data-accordion-btn
                onClick={() => handleAccordionClick(1000)}
              >
                {" "}
                <p className="menu-title">اللغة</p>
                <ArrowBackOutlinedIcon className="caret-back" />
              </button>
              <ul
                className={`submenu-category-list ${
                  activeAccordions.includes(1000) ? "active" : ""
                }`}
              >
                {" "}
                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    التركية
                  </a>
                </li>
                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    العربية
                  </a>
                </li>
              </ul> */}
          {/* </li> */}
          {/* <li className="menu-category">
              <button
                className={`accordion-menu ${
                  activeAccordions.includes(1100) ? "active" : ""
                }`}
                data-accordion-btn
                onClick={() => handleAccordionClick(1100)}
              >
                {" "}
                <p className="menu-title">العملة</p>
                <ArrowBackOutlinedIcon className="caret-back" />
              </button>
              <ul
                className={`submenu-category-list ${
                  activeAccordions.includes(1100) ? "active" : ""
                }`}
              >
                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    الليرة التركية
                  </a>
                </li>
                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    الدولار الامريكي
                  </a>
                </li>
              </ul>
            </li> */}
          {/* </ul> */}
          <ul className="menu-social-container">
            <li>
              <a href="https://t.me/Rafifashion1" className="social-link">
                <TelegramLogo />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@kiwi.fashion1"
                className="social-link"
              >
                <TiktokLogo />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/kiwi.fashion1/"
                className="social-link"
              >
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/KiWicompany0?mibextid=JRoKGi"
                className="social-link"
              >
                <Facebook />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default NavBarLogin;
