import { useState, useEffect, createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedUser } from '../redux/actions/authAction';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { user, loading } = useGetCurrentUser();

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

const useGetCurrentUser = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const userRes = useSelector((state) => state.authReducer.getCurrentUser);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (localStorage.getItem("token")) {
          let token = localStorage.getItem("token");
          await dispatch(getLoggedUser(token));
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [dispatch]);

  useEffect(() => {
    if (userRes && userRes.data) {
      setUser(userRes);
    }
  }, [userRes]);

  return { user, loading };
};

export default useGetCurrentUser;
