import React from 'react'
import AdminAllOrdersItem from './AdminAllOrdersItem';
import { Row } from 'react-bootstrap';
import UserGetAllOrdersHook from '../../hook/user/user-get-all-orders-hook';
import Pagination from '../Utility/Pagination';

const AdminAllOrder = () => {
    const [userName,orders,result,pagination,onPress] = UserGetAllOrdersHook()
  return (
    <div>
        <div className="admin-content-text">ادارة جميع الطلبات</div>
        <Row>
        {orders && orders.length ? (
  orders.map((item, index) => {
    return <AdminAllOrdersItem key={index} item={item} />;
  })
) : (
  <h5>لا يوجد طلبات</h5>
)}

      <Pagination pageCount={pagination.numberOfPages ? pagination.numberOfPages : 0} onPress={onPress}/>
  

        </Row>
    </div>
  )
}

export default AdminAllOrder