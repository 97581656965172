import  { useState ,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ResetPasswordUser, VerifyPasswordUser } from '../../redux/actions/authAction'
import notify from '../useNotifaction'
import { useLocation, useNavigate } from 'react-router-dom'

const ResetPasswordHook = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState('')
  const location = useLocation();
  const { email } = location.state || {};
  const [isPress, setIsPress] = useState(false)
  const onChangePassword = (e)=>{
    setPassword(e.target.value)
  }
  const onChangeConfirmPassword = (e)=>{
    setConfirmPassword(e.target.value)
  }
  const onSubmit =async ()=>{
    if(password !== confirmPassword){
        notify('يجب ان تكون كلمة السر متطابقة','error')
        return;
    }
    if(password === "" && password.length < 6){
        notify('الرجاء ادخال كلمة سر','error')
        return;
    }
    setLoading(true)
    setIsPress(true)
    await dispatch(ResetPasswordUser({
      email:email,
        newPassword:password
    }))
    setLoading(false)
    setIsPress(false)
  }
  const res = useSelector((state)=>state.authReducer.verifyPassword)
  useEffect(()=>{
    if(loading === false){
        if(res){
            if(res.data){

           
            if(res.data.status === 'Success'){
                notify('تم تغيير كلمة السر بنجاح','success')
                setTimeout(()=>{
                    navigate('/user/profile')
                },1000)
            }
            else if(res.data.status === 'fail'){
                notify('من فضلك اطلب كود جديد','error')
            }
            else if(res.data.status === 'error'){
                notify('يوجد خطأ او الحساب غير موجود','error')
                // window.location.replace('/login')
            }
        }
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loading])
  return [  password,confirmPassword,onChangePassword,onChangeConfirmPassword,
    loading,
    onSubmit,isPress]

}

export default ResetPasswordHook