import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import "../wishlist/wishlist.css";
import SixAd from "../AdsCompnents/6Ad";
import AdCom from "../AdsCompnents/AdCom";
import logo from "../../images/logo-ki.jpeg";
import userLogo from "../../images/userLogo.png";
import {
  AddressBook,
  Heart,
  List,
  ListDashes,
  SignOut,
  SquaresFour,
  UserCircle,
} from "phosphor-react";
import { useUser } from "../../context/getCurrentUser";
import Skeleton from "react-loading-skeleton";
import "./sidebar.css";
import ListAltIcon from "@mui/icons-material/ListAlt";
const UserSideBar = ({ noSide }) => {
  const [show, setShow] = React.useState(false);
  const { user, loading } = useUser();
  const [showS, setShowS] = useState(false);
  let wid;
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setShowS(windowWidth > 800);
      if (windowWidth > 800) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wid = "desktop";
      } else if (windowWidth <= 800) {
        wid = "mobile";
      }
    };

    // تسجيل مستمع لتغيير حجم الشاشة
    window.addEventListener("resize", handleResize);

    // قم بتنفيذ handleResize عند تحميل المكون
    handleResize();

    // قم بإلغاء تسجيل المستمع عندما يتم تفريغ المكون
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <span className="menu-ham" onClick={() => setShowS(true)}>
        <MenuIcon /> اظهار القائمة
      </span>
      {/*<div className={`sidebar ${show ? "show" : ""}`}>
        <div className="d-flex flex-column">
          <span className="close-sidebar" onClick={() => setShow(false)}>
            <CloseIcon />
          </span>
          <a href="/user/allOrders" style={{ textDecoration: "none" }}>
            <div className="admin-side-text mt-3 border-bottom p-2 mx-auto text-center">
              اداره الطلبات
            </div>
          </a>
          <a href="/user/favoriteproducts" style={{ textDecoration: "none" }}>
            <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
              قائمه المفضله
            </div>
          </a>
          <Link to="/user/addresses" style={{ textDecoration: "none" }}>
            <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
              العنوان الشخصية
            </div>
          </Link>
          <a href="/user/profile" style={{ textDecoration: "none" }}>
            <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
              الملف الشخصي
            </div>
          </a>
          <AdCom slot="8536644146" fullWidth={true} />
        </div>
      </div> */}
      <div
        className={`sidebarNew ${showS && noSide !== true ? "active" : ""}${
          showS && noSide === true && window.innerWidth <= 800 ? "active" : ""
        }`}
      >
        <div className={"top"}>
          <div className={"logo"}>
            <img src={logo} alt="Kiwi Fashion " />
            <span
              onClick={() => (window.location.href = "/")}
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              ازياء كيوي
            </span>
          </div>
          <List
            className={"btn-link-side"}
            onClick={() => setShowS((prev) => !prev)}
          />
        </div>
        <div className={"userSide"}>
          <img src={userLogo} alt="" className={"userImg-sidebar "} />
          <div>
            <p
              className={"bold-sidebar"}
              style={{ color: "var(--salmon-green)" }}
            >
              {user && user.data.name}
            </p>
            {loading === true ? (
              <Skeleton
                width={186}
                height={25}
                style={{marginRight:'8px'}}
              />
            ) : (
              <p>{user && user.data.role}</p>
            )}
          </div>
        </div>
        <ul>
          <li>
            <a href="/user/profile">
              <UserCircle size={32} />

              <span className={"navItem-sidebar"}>الملف الشخصي</span>
            </a>
            <span className="tooltip">الملف الشخصي</span>
          </li>
          <li>
            <a href="/user/allorders">
              <ListDashes size={32} />

              <span className={"navItem-sidebar"}>الطلبات</span>
            </a>
            <span className="tooltip"> الطلبات</span>
          </li>
          <li>
            <a href="/user/favoriteproducts">
              <Heart size={32} />

              <span className={"navItem-sidebar"}>المنتجات المفضلة</span>
            </a>
            <span className="tooltip"> المنتجات المفضلة</span>
          </li>
          <li>
            <a href="/user/addresses">
              <AddressBook size={32} />

              <span className={"navItem-sidebar"}>العنواين الشخصية</span>
            </a>
            <span className="tooltip"> العنواين الشخصية</span>
          </li>
          <li>
            <a
              href="#"
              onClick={() => {
                localStorage.removeItem("token");
                window.location.reload();
              }}
            >
              <SignOut size={32} />

              <span className={"navItem-sidebar"}>تسجيل خروج</span>
            </a>
            <span className="tooltip"> تسجيل خروج</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserSideBar;
