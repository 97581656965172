import baseURL from "../Api/baseURL";
import qs from 'qs';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
  transformRequest: [(data) => qs.stringify(data)],
};

const useUpdateDataXX = async (url, params) => {
  try {
    const res = await baseURL.put(url, params, axiosConfig);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export { useUpdateDataXX };
