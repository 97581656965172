import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./redux/store";
import { Provider } from "react-redux";
import { UserProvider } from "./context/getCurrentUser";
import { ChangeProvider } from "./context/ChangeContext";
import { PaginationProvider } from "./context/getPaginationPage";
import { ChangeCartProvider } from "./context/ChangeCart";
import { ChangeWishlistProvider } from "./context/ChangeWishlist";
import { WishlistProvider } from "./context/WishlistRes";
import { DeleteUserChangeProvider } from "./context/DeleteUserChangeContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>

  <Provider store={store}>
    <BrowserRouter>
      <UserProvider>
        <GoogleOAuthProvider clientId="277936663064-lo8h0ij4inplfornlu7a4m3qlha6pkob.apps.googleusercontent.com">
          <ChangeCartProvider>
            <ChangeWishlistProvider>
              <ChangeProvider>
                <WishlistProvider>
                  <PaginationProvider>
                    <DeleteUserChangeProvider>
                      <App />
                    </DeleteUserChangeProvider>
                  </PaginationProvider>
                </WishlistProvider>
              </ChangeProvider>
            </ChangeWishlistProvider>
          </ChangeCartProvider>
        </GoogleOAuthProvider>
      </UserProvider>
    </BrowserRouter>
  </Provider>
  // /* </React.StrictMode>
);
