import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneProductImages } from "../../redux/actions/productsAction";
import Skeleton from "react-loading-skeleton";
import Gallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import "./ProductGallery.css";

const ProductGallery = ({ item, id }) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const loading = useSelector((state) => state.allProducts.loading);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const res = await dispatch(getOneProductImages(id));

        if (res.images && res.images.length > 0) {
          // قم بتحويل الصور إلى الشكل الذي يتوقعه مكتبة react-image-gallery
          const newImages = res.images.map((image) => ({
            original: image,
            thumbnail: image,  // استخدم نفس الصورة للمصغرة
            // description: "وصف الصورة",
          }));

          setImages(newImages);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [dispatch, id]);

  return (
    <div className="product-gallery-card">
      {loading && item.price === undefined ? (
        <Skeleton style={{ width: "100%", height: "400px" }} />
      ) : item.price !== undefined ? (
        <>
          <div className="thumnail-images" dir="rtl">
            <Gallery items={images} isRTL={true} />
          </div>
        </>
      ) : (
        <Skeleton style={{ width: "100%", height: "100%" }} />
      )}
    </div>
  );
};

export default ProductGallery;
