import React from "react";
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import UserSideBar from "../../Components/User/UserSideBar";
import UserProfile from "../../Components/User/UserProfile";
const UserProfilePage = () => {
  return (
    <div className="favorite-cont">
      <Row className="py-3">
        <Col md="3">
          <UserSideBar />
        </Col>
        <Col sm="12" xs="12" md="9">
          <UserProfile />
        </Col>
      </Row>
    </div>
  );
};
export default UserProfilePage