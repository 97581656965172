import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Avatar,
  Tab,
  Tabs,
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  Button,
  Stack,
} from "@mui/material";
import userImage from "../../images/userLogo.png";
import { Modal} from "react-bootstrap";
import { deleteUser } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { useDeleteChangeContext } from "../../context/DeleteUserChangeContext";

const AdminUserCard = ({ item }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const { deleteChange, updateDeleteChange } = useDeleteChangeContext();

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(false);
    await dispatch(deleteUser(item._id));
    setLoading(false);

    setShow(false);

    updateDeleteChange(Math.random(500 * 100));
  };
  return (
    <Card dir="ltr">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>تاكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل انت متاكد من انك تريد حذف المستخدم</Modal.Body>
        <Modal.Footer>
          <Button variant="contained" color="success"  onClick={handleClose}>
            تراجع
          </Button>
          <Button variant="contained" color="error"  onClick={handleDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <CardContent>
        <Box sx={{ flexGrow: 1, mb: 1 }}>
          <Tabs value={currentTab} onChange={handleTabChange} centered>
            <Tab label="معلومات المستخدم" style={{ fontFamily: "Tajawala" }} />
            <Tab label="العناوين" style={{ fontFamily: "Tajawala" }} />
          </Tabs>
          {/* Content for User Information Tab */}
          {/* You can put the user information here */}
          <TabPanel value={currentTab} index={1}>
            {/* Content for Addresses Tab */}
            {item && item.addresses.length > 0 ? (
              <TableContainer component={Paper} dir="rtl">
                <Table style={{ fontFamily: "Tajawala" }}>
                  <TableHead style={{ fontFamily: "Tajawala" }}>
                    <TableRow>
                      <TableCell style={{ fontFamily: "Tajawala" }}>
                        الاسم
                      </TableCell>
                      <TableCell style={{ fontFamily: "Tajawala" }}>
                        التفاصيل
                      </TableCell>
                      <TableCell style={{ fontFamily: "Tajawala" }}>
                        الهاتف
                      </TableCell>
                      <TableCell style={{ fontFamily: "Tajawala" }}>
                        المدينة
                      </TableCell>
                      <TableCell style={{ fontFamily: "Tajawala" }}>
                        الدولة
                      </TableCell>
                      <TableCell style={{ fontFamily: "Tajawala" }}>
                        الرمز البريدي
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.addresses.map((address, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontFamily: "Tajawala" }}>
                          {address.alias}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Tajawala" }}>
                          {address.details}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Tajawala" }}>
                          {address.phone}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Tajawala" }}>
                          {address.city}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Tajawala" }}>
                          {address.country}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Tajawala" }}>
                          {address.postalCode}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body2" style={{ fontFamily: "Tajawala" }}>
                لا توجد عناوين متاحة
              </Typography>
            )}
          </TabPanel>
        </Box>
        <TabPanel value={currentTab} index={0}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={4}>
              <Avatar
                alt={item.name}
                src={userImage}
                sx={{ width: "100%", height: "100%" }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5" component="div">
                {item.name}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Email: {item.email}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Phone: {item.phone}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Role: {item.role}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Active: {item.active ? "Yes" : "No"}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Created At: {new Date(item.createdAt).toString()}
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
      </CardContent>
      <Divider />

      <Stack
        className="btns-user-action"
        sx={{ width: "50%", display: "flex", gap: 2, m: "25px auto" }}
      >
        <Button variant="contained" color="error" onClick={handleShow}>
          Delete User
        </Button>
        <Button variant="contained">Edit User</Button>
      </Stack>
    </Card>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default AdminUserCard;
