import { GET_ALL_BRAND, GET_ERROR, CREATE_BRAND, GET_ONE_BRAND } from "../type";
import { useGetData } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
//get all category
export const getAllBrand = (limit) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/brands?limit=${limit}`);
    dispatch({
      type: GET_ALL_BRAND,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};
export const getOneBrand = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/brands/${id}`);
    dispatch({
      type: GET_ONE_BRAND,
      payload: response,
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};

//get all category with pagination
export const getAllBrandPage = (page) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/brands?limit=6&page=${page}`);
    dispatch({
      type: GET_ALL_BRAND,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};

//get all category with pagination
export const createBrand = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage("/api/v1/brands", formData);
    if (response.status === 201) {
      dispatch({
        type: CREATE_BRAND,
        payload: response,
        loading: false, // تحديد الحالة على false بعد الإرسال الناجح
      });
    } else {
      dispatch({
        type: GET_ERROR,
        payload: "هناك مشكلة في عملية الإضافة",
      });
    }
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
