import { UPDATE_USER, UPDATE_PASSWORD, GET_USERS, DELETE_USER } from "../type";

const inital = {
  updateUser: [],
  updatePassword: [],
  allUsers: [],
  deleteUser: [],
  loading: true,
};
const userReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        allUsers: action.payload,
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        allUsers: action.payload,
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateUser: action.payload,
        loading: false,
      };

    case UPDATE_PASSWORD:
      return {
        ...state,
        updatePassword: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default userReducer;
