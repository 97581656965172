import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import AddToCartHook from "../../hook/cart/add-to-cart-hook";
import { useParams } from "react-router-dom";
import { Divider } from "@mui/material";
import { StarFilled } from "@ant-design/icons";
import { Warning } from "phosphor-react";
import { Helmet } from "react-helmet";
import striptags from "striptags";
import { Done } from "@mui/icons-material";

const ProductsText = ({ item, cat, filteration }) => {
  console.log(item)
  let loading = useSelector((state) => state.allProducts.loading);
  const { id } = useParams();
  const [
    handleAddToCart,
    colorClick,
    indexColor,
    colorText,
    loadingCart,
    indexSize,
    sizeClick,
    sizeText,
  ] = AddToCartHook(id, item);
  // حالة لتخزين معلومات اللون المحدد
  const [selectedColor, setSelectedColor] = useState(null);

  // دالة لتحديث المعلومات عند تحديد لون
  const handleColorClick = (index, color, sizes, colorName) => {
    setSelectedColor({ index, color, colorName, sizes });
    colorClick(index, color, colorName);
  };
  const cleanedDescription = striptags(item.description);
  return (
    <div style={{ marginBottom: "80px" }}>
      {item.title ? (
        <Helmet>
          <title>{`${item.title} - Kiwi Fashion`}</title>
          <meta property="og:title" content={`${item.title}`} />
          <meta
            property="og:description"
            content={`وصف المنتج: ${cleanedDescription}. Kiwi Fashion,  ازياء كيوي`}
          />
          <meta property="og:image" content={`${item.images[0]}`} />

          <meta
            property="og:url"
            content={`https://www.kiwifashion.net/products/${item._id}`}
          />
          <meta property="og:type" content="website" />
        </Helmet>
      ) : (
        <Helmet>
          <title>{` Kiwi Fashion`}</title>
        </Helmet>
      )}
      <Row className="mt-2">
        <div className="category-product-text">
          {item.inStock === false ? (
            <span className="out-stock-circle">لقد نفذ هذا المنتج</span>
          ) : (
            ""
          )}
          {item.lowerStock === true ? (
            <span className="lower-stock-circle">
              بقي القليل من هذا المنتج <Warning />
            </span>
          ) : (
            ""
          )}
          <br />
          {loading && !item ? (
            <Skeleton width={80} height={10} />
          ) : typeof cat.name === "string" ? (
            <h6>{cat.name} :</h6>
          ) : (
            ""
          )}
          {loading && !item ? (
            <Skeleton width={80} height={10} />
          ) : item.code ? (
            <>
              <span style={{ color: "#777" }}>كود المنتج : {item.code}</span>
            </>
          ) : (
            ""
          )}
        </div>
      </Row>
      <Row>
        <Col
          md="11"
          style={{
            borderBottom: typeof item.title === "string" && "1px solid #dfdfdf",
            paddingBottom: "16px",
            position: "relative",
          }}
        >
          <h1 className="cat-title d-inline">
            {loading && !item ? (
              <Skeleton width={290} height={25} />
            ) : typeof item.title === "string" ? (
              `${item.title}`
            ) : (
              <Skeleton width={290} height={25} />
            )}
          </h1>
          <a className="mt-2" href="#rate-container">
            {loading && !item ? (
              <Skeleton width={290} height={25} />
            ) : (
              <div className="d-flex">
                {item.ratingsAverage ? (
                  <span
                    style={{
                      display: "flex",
                      fontSize: "18px",
                      color: "orange",
                      alignItems: "center",
                      marginLeft: "9px",
                    }}
                  >
                    <StarFilled style={{ marginLeft: "3px" }} />{" "}
                    {item.ratingsAverage}
                  </span>
                ) : loading === true ? (
                  <Skeleton width={290} height={25} />
                ) : (
                  <span
                    style={{
                      display: "flex",
                      fontSize: "18px",
                      color: "orange",
                      alignItems: "center",
                    }}
                  >
                    <StarFilled /> 0
                  </span>
                )}
                <span
                  style={{
                    color: "#777",
                    marginLeft: "10px",
                    fontSize: "16px",
                  }}
                >
                  {item.ratingsQuantity ? `${item.ratingsQuantity} تقييم` : ""}
                </span>
              </div>
            )}
          </a>
        </Col>
      </Row>
      {/* <Row>
        <Col md="9" className="mt-4">
          <div className="cat-text d-inline">الماركة :</div>
          <div className="barnd-text d-inline mx-1">{brand.name} </div>
        </Col>
      </Row> */}
      {item &&
      item.availableColorsPerSize &&
      item.availableColorsPerSize.length > 0 &&
      JSON.stringify(item.availableColorsPerSize) !== '[""]' &&
      JSON.stringify(item.availableColorsPerSize) !== '["none"]' ? (
        <Row className="mt-2">
          {loading ? (
            <Skeleton width={140} height={16} />
          ) : Array.isArray(item.availableColorsPerSize) ? (
            <div className="cat-text">الالوان المتاحة :</div>
          ) : (
            <Skeleton width={140} height={16} />
          )}
        </Row>
      ) : (
        ""
      )}
      {item.availableColorsPerSize &&
      Array.isArray(item.availableColorsPerSize) &&
      JSON.stringify(item.availableColorsPerSize) !== '[""]' &&
      JSON.stringify(item.availableColorsPerSize) !== '["none"]' ? (
        <Row className="mt-2">
          <Col
            md="10"
            className="mt-1 d-flex"
            style={{
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {item.availableColorsPerSize.map((color, index) => (
              <div key={index} className="color-box">
                <span className="color-name">{color.colorName}</span>
                <div
                  className={`color ms-2 ${
                    selectedColor && selectedColor.index === index && "act"
                  }`}
                  onClick={() =>
                    handleColorClick(
                      index,
                      color.color,
                      color.sizes,
                      color.colorName
                    )
                  }
                  style={{
                    backgroundColor: color.color,
                  }}
                >
                  {selectedColor && selectedColor.index === index && <Done />}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      ) : null}

      {/* عرض المقاسات عند تحديد لون */}
      {selectedColor && (
        <div
          className="sizes-container mt-3"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          {loading ? (
            <Skeleton width={140} height={16} />
          ) : Array.isArray(selectedColor.sizes) ? (
            <div className="cat-text">المقاسات المتاحة :</div>
          ) : (
            <Skeleton width={140} height={16} />
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {selectedColor.sizes.map((size, sizeIndex) => (
              <div
                className="select-size"
                key={sizeIndex}
                style={{
                  background:
                    indexSize === sizeIndex ? "var(--main-color)" : "#fff",
                  color: indexSize === sizeIndex ? "#fff" : "#111",
                  borderRadius: indexSize === sizeIndex ? "3px" : "9px",
                  transition: "0.15s linear",
                }}
                onClick={() => sizeClick(sizeIndex, size.size)}
              >
                <span>{size.quantity}</span>
                {size.size}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* {item.availableColors &&
      Array.isArray(item.availableColors) &&
      JSON.stringify(item.availableColors) !== '[""]' &&
      JSON.stringify(item.availableColors) !== '["none"]' ? (
        <Row>
          <Col
            md="10"
            className="mt-1 d-flex"
            style={{
              flexWrap: "wrap",
              marginTop: "10px !important",
              gap: "10px",
            }}
          >
            {item.availableColors
              ? item.availableColors.map((color, index) => (
                  <div
                    className="color ms-2"
                    key={index}
                    onClick={() => colorClick(index, color)}
                    style={{
                      backgroundColor: color,
                      border:
                        indexColor === index
                          ? "4px solid #666"
                          : "2px solid black",
                      outline:
                        indexColor === index
                          ? "2px solid #777"
                          : "2px solid black",
                    }}
                  ></div>
                ))
              : null}
          </Col>
        </Row>
      ) : null}
      {item.availableColors &&
      item.availableColors.length &&
      JSON.stringify(item.availableColors) !== '[""]' &&
      JSON.stringify(item.availableColors) !== '["none"]' ? (
        <Divider
          sx={{
            height: "1px",
            width: "100%",
            background: "rgba(0,0,0,0.5)",
            marginTop: "16px",
            marginBottom: "-9px",
          }}
        />
      ) : (
        ""
      )}
      {item && item.availableSizes && item.availableSizes.length > 0 ? (
        <Row className="mt-4">
          {loading ? (
            <Skeleton width={140} height={16} />
          ) : Array.isArray(item.availableSizes) ? (
            <div className="cat-text">الاحجام المتاحة :</div>
          ) : (
            <Skeleton width={140} height={16} />
          )}
        </Row>
      ) : (
        ""
      )}
      {item.availableSizes && Array.isArray(item.availableSizes) ? (
        <Row>
          <Col md="10" className="mt-1 d-flex" style={{ flexWrap: "wrap" }}>
            {item.availableSizes
              ? item.availableSizes.map((size, index) => (
                  <div
                    className="select-size"
                    key={index}
                    style={{
                      background:
                        indexSize === index ? "var(--main-color)" : "#fff",
                      color: indexSize === index ? "#fff" : "#111",
                      borderRadius: indexSize === index ? "3px" : "9px",
                      transition: "0.15s linear",
                    }}
                    onClick={() => sizeClick(index, size)}
                  >
                    {size}
                  </div>
                ))
              : null}
          </Col>
        </Row>
                  ) : null*/}
      {item &&
      item.availableColorsPerSize &&
      item.availableColorsPerSize.length &&
      JSON.stringify(item.availableColorsPerSize) !== '[""]' &&
      JSON.stringify(item.availableColorsPerSize) !== '["none"]' ? (
        <Divider
          sx={{
            height: "1px",
            width: "100%",
            background: "rgba(0,0,0,0.5)",
            marginTop: "16px",
            marginBottom: "0px",
          }}
        />
      ) : (
        ""
      )}
      <Row className="mt-2">
        <Col md="11">
          <div
            className="price-product-single d-flex"
            style={{ flexDirection: "row", textAlign: "right" }}
          >
            <div
              style={{
                marginLeft: "6px",
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item && item.priceAfter ? (
                <>
                  <span
                    className="before-dis"
                    style={{
                      textDecoration: "line-through",
                      fontSize: "14px",
                      color: "#999",
                      marginTop: "6px",
                      marginLeft: "3px",
                    }}
                  >
                    {item.price}TL
                  </span>
                  {item.priceAfter == 0 ? (
                    ""
                  ) : (
                    <span className="after-dis" style={{ marginLeft: "2px" }}>
                      {item.priceAfter}TL
                    </span>
                  )}
                </>
              ) : (
                <>
                  {loading && item.price === undefined ? (
                    <Skeleton width={190} height={26} />
                  ) : item.price !== undefined ? (
                    <span style={{ fontSize: "27px" }}>{item.price}TL</span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>

            <div
              style={{
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                marginTop: "1px",
              }}
            >
              {loading && item.priceDollar === undefined ? (
                <Skeleton width={190} height={26} />
              ) : item.priceDollar !== undefined && item.priceDollarAfter ? (
                <>
                  <span
                    className="before-dis"
                    style={{
                      textDecoration: "line-through",
                      fontSize: "14px",
                      color: "#999",
                      marginTop: "6px",
                      marginLeft: "3px",
                    }}
                  >
                    {`${item.priceDollar}$`}
                  </span>
                  {item.priceDollarAfter == 0 ? (
                    ""
                  ) : (
                    <span className="after-dis" style={{ marginLeft: "7px" }}>
                      {`${item.priceDollarAfter}$`}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {loading && item.priceDollar === undefined ? (
                    <Skeleton width={190} height={26} />
                  ) : item.priceDollar !== undefined && item.priceDollar ? (
                    <span style={{ fontSize: "26px" }}>
                      {item.priceDollar}$
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="12">
          {loading && item.price === undefined ? (
            <Skeleton width={320} height={17} />
          ) : item.price !== undefined ? (
            <div
              onClick={item.inStock ? handleAddToCart : null}
              className={`product-cart-add ${
                item.inStock === false ? "btn-disabled" : ""
              }`}
              disabled={item.inStock === false}
            >
              {loadingCart === true ? (
                <Spinner animation="border" variant="dark" />
              ) : (
                "اضف الى السلة"
              )}
              <AddShoppingCartIcon />
            </div>
          ) : (
            <Skeleton width={320} height={17} />
          )}
        </Col>
      </Row>
      <Row>
        <Col md="11">
          <div className="product-contact-container">
            {loading && item.price === undefined ? (
              <Skeleton width={420} height={25} />
            ) : item.price !== undefined ? (
              <>
                <br />
                <div>ايميل التواصل: kiwi@kiwifashion.net</div>
              </>
            ) : (
              <Skeleton width={420} height={25} />
            )}
            {loading && item.price === undefined ? (
              <Skeleton width={420} height={25} />
            ) : item.price !== undefined ? (
              <>
              <div> رقم التواصل: 00905395955434 </div>
              <div> رقم التواصل: 00905511535307 </div>
              </>
            ) : (
              <Skeleton width={420} height={25} />
            )}
            {loading && item.price === undefined ? (
              <Skeleton width={420} height={25} />
            ) : item.price !== undefined ? (
              <div style={{ color: "red" }}>
                {" "}
                ملاحظة: يجب اختيار لون او مقاس ان وجدوا
              </div>
            ) : (
              <Skeleton width={420} height={25} />
            )}
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md="12">
          {loading && !item ? (
            <Skeleton width={350} height={20} />
          ) : item.subcategory && filteration() ? (
            <>
              <h4 className="tags-title">التصنيفات الفرعية</h4>

              <div className="tags-product">{filteration()}</div>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProductsText;
