import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../redux/actions/categoryAction";
import "./cats.css";
import { X } from "phosphor-react";
import { useParams } from "react-router-dom";
import ViewProductDetailsHook from "../../hook/products/view-product-details-hook";
const CategoriesHeader = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  // let { id } = useParams();
  // const [
  //   item,
  //   goToNextImage,
  //   goToPreviousImage,
  //   images,
  //   currentImageIndex,
  //   cat,
  //   brand,
  //   filteration,
  // ] = ViewProductDetailsHook(id);
  useEffect(() => {
    const res = async () => {
      dispatch(getAllCategory());
    };
    
    res();
 
  }, []);
  const category = useSelector((state) => state.allCategory.category);
  return (
    <>
      { !category.data ? (
        ""
      ) : (
        <>
          {visible ? (
            <div className={`popup-cats ${visible ? "active" : ""}`}>
              <div id="cont-rel">
                <span
                  className="close-popup-cats"
                  onClick={() => setVisible(false)}
                >
                  <X />
                </span>
                <h4>التصنيفات</h4>
                <ul>
                  {category &&
                    category.data &&
                    category.data.map((item, index) => (
                      <li key={index}>
                        <a href={`/products?category=${item._id}`}>
                          {" "}
                          {item.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="cat-header">
            <Container>
              <Row style={{ maxHeight: "max-content" }}>
                <Col className="d-flex justify-content-start py-2 flex-wrap">
                  <div
                    className="cat-text-header "
                    onClick={() => setVisible(true)}
                  >
                    الكل
                  </div>
                  {category &&
                    category.data &&
                    category.data.slice(0, 5).map((item, index) => (
                      <a
                        className="cat-text-header"
                        key={index}
                        href={`/products?category=${item._id}`}
                      >
                        {item.name}
                      </a>
                    ))}

                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default CategoriesHeader;
