import React from 'react'
import './sections.css'
import { CheckSquare, Wallet } from 'phosphor-react';
import { LocalShipping } from '@mui/icons-material';
const IconsSectionHome = () => {
  return (
    <div className="container-icons">
         <div className="card-icon">
        <CheckSquare  />
        <h3 className="title-icon">
          جودة عالية
        </h3>
        <span className="sub-title-icon">ومنتجات مضمونة</span>
        </div>
        <div className="card-icon">
        <LocalShipping  />
        <h3 className="title-icon">
            شحن امن وسريع
        </h3>
        <span className="sub-title-icon">لجميع انحاء العالم</span>
        </div>
        <div className="card-icon">
        <Wallet  />
        <h3 className="title-icon">
            اسعار مناسبة
        </h3>
        <span className="sub-title-icon">ودفع امن</span>
        </div>
    </div>
  )
}

export default IconsSectionHome