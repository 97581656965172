import React, { useRef } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import AddCouponHook from '../../hook/coupon/add-coupon-hook'
import AdminCouponCard from './AdminCouponCard'

const AdminAddCoupon = () => {
  const [
    couponDiscount,
    onChangeCouponDiscount,
    couponName,
    onChangeCouponName,
    couponDate,
    onChangeCouponDate,
    handelSubmit,
    loading,
    items,
  ]= AddCouponHook()
  const dateRef = useRef()
  return (
    <div>
    <Row className="justify-content-start ">
      <div className="admin-content-text pb-4">اضف كوبون جديد</div>
      <Col sm="8">
      <label htmlFor='name'>اسم الكوبون</label>

        <input
          type="text"
          className="input-form d-block mt-3 px-3"
          placeholder="اسم الكوبون"
          value={couponName}
          id="name"
          onChange={onChangeCouponName}
        />
        <label htmlFor='date' style={{marginTop:"16px"}}>تاريخ انتهاء الكوبون</label>

        <input
          type="date"
          ref={dateRef}
          className="input-form d-block mt-3 px-3"
          placeholder="تاريخ الانتهاء"
          onFocus={()=> dateRef.current.type = 'date'}
          onBlur={()=> dateRef.current.type = 'text'}
          onChange={onChangeCouponDate}
          value={couponDate}
          id="date"
        />
        <label htmlFor='discount' style={{marginTop:"16px"}}>نسبة الخصم</label>
        <input
          type="number"
          id='discount'
          value={couponDiscount}
          onChange={onChangeCouponDiscount}
          className="input-form d-block mt-3 px-3"
          placeholder="نسبة خصم الكوبون"
      
        />
      </Col>
    </Row>
    <Row>
      <Col sm="8" className="d-flex justify-content-end ">
        <button
          onClick={handelSubmit}
          className="btn-save d-inline mt-2 "
          style={{ fontSize: "16px" }}
        >
             {
        loading === true ? (
          <Spinner animation="border" variant="dark" />
        ) : '   حفظ التعديلات'
       }
        </button>
      </Col>
   
    </Row>
    {/* <br/><br/><br/>
    <p>
      <b style={{color:'red'}}>ملاحظة: </b>
      <br/>
      1: يجب عدم اضافة صورة براند كان قد تم رفعها من قبل على براند مختلف
      <br/>
      2: يجب عدم اضافة اسم براند تم اسخدامه مع براند مختلف
    </p> */}
    <ToastContainer />
    <br/>
    <br/>
    <br/>
    <h4>الكوبونات الموجودة</h4>
    {items && items.data && items.data.map((item,index)=>(

    <AdminCouponCard item={item} key={index}/>
    ))}
  </div>  
  )
}

export default AdminAddCoupon