import {
  GET_ERROR,
  GET_ONE_SUB_CATEGORY,
  CREATE_SUB_CATEGORY,
  GET_ALL_SUB_CATEGORY,
  GET_SUB_CATEGORY,
  GET_ALL_SUB_CATEGORY_NAV,
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";
export const getAllSubCategoryNav = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/subcategories`);
    dispatch({
      type: GET_ALL_SUB_CATEGORY_NAV,
      payload: response,
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};
export const getAllSubCategory = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/subcategories`);
    dispatch({
      type: GET_ALL_SUB_CATEGORY,
      payload: response,
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};

export const getOneCategory = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/categories/${id}/subcategories`);
    dispatch({
      type: GET_SUB_CATEGORY,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};
export const getOneCategoryS = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/categories/${id}/subcategories`);
    dispatch({
      type: GET_SUB_CATEGORY,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};
export const getOneSubCategory = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/subcategories/${id}`);
    dispatch({
      type: GET_ONE_SUB_CATEGORY,
      payload: response,
      loading: false,
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};

//get all category with pagination
export const createSubCategory = (data) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/subcategories", data);
    if (response.status === 201) {
      dispatch({
        type: CREATE_SUB_CATEGORY,
        payload: response,
        loading: false, // تحديد الحالة على false بعد الإرسال الناجح
      });
    } else {
      dispatch({
        type: GET_ERROR,
        payload: "هناك مشكلة في عملية الإضافة",
      });
    }
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
