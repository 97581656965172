import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../redux/actions/categoryAction";
import { getAllSubCategory } from "../../redux/actions/subCategoryAction";
import "./sideFilter.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Link } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ViewSearchProductsHook from "../../hook/products/view-search-product-hook";
import FirstAd from "../AdsCompnents/1Ad";
import AdCom from "../AdsCompnents/AdCom";
const SideFilter = () => {
  const categories = useSelector((state) => state.allCategory.category);
  const subcategory = useSelector((state) => state.allSubCategory.subcategory);
  const [activeAccordions, setActiveAccordions] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState(4);
  const [resetFiltersVisible, setResetFiltersVisible] = useState(false); // حالة زر إعادة الفلاتر

  const handleShowMoreCategories = () => {
    // عند النقر على الزر "عرض المزيد"
    if (visibleCategories < allCategory.length) {
      setVisibleCategories(allCategory.length);
    } else {
      setVisibleCategories(4); // عرض فقط 4 تصنيفات
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategory());
    dispatch(getAllSubCategory());
  }, []);
  const handleAccordionClick = (index) => {
    if (activeAccordions.includes(index)) {
      setActiveAccordions(activeAccordions.filter((item) => item !== index));
    } else {
      setActiveAccordions([...activeAccordions, index]);
    }
  };
  const allCategory = categories.data || [];
  const allSubCategory = subcategory.data || [];
  const categoriesWithSubcategories = allCategory.filter((category) => {
    return allSubCategory.some((sub) => sub.category === category._id);
  });

  // قائمة التصنيفات بدون تصنيفات فرعية
  const categoriesWithoutSubcategories = allCategory.filter((category) => {
    return !allSubCategory.some((sub) => sub.category === category._id);
  });
  const filteration2 = (category) => {
    if (allSubCategory && allSubCategory.length) {
      const filteredSubCategories = allSubCategory
        ? allSubCategory.filter((item) => item.category === category._id)
        : [];

      if (filteredSubCategories.length > 0) {
        return filteredSubCategories.map((li, index) => (
          <li className="submenu-category submenu-slide" key={index}>
            <a
              onClick={handleCategorySelection}
              href={`/products?category=${li.category}&subcategory=${li._id}`}
              className={"submenu-title"}
            >
              {li.name}
            </a>
          </li>
        ));
      } else {
        return;
      }
    } else {
      return;
    }
  };
  // عندما يتم اختيار فئة أو فئة فرعية، قم بجعل زر إعادة الفلاتر مرئيًا
  const handleCategorySelection = (e) => {
    localStorage.setItem("resetFiltersVisible", true);
  };

  useEffect(() => {
    // تحقق من localStorage لمعرفة ما إذا كان يجب عرض زر إعادة الفلاتر
    const isVisible = localStorage.getItem("resetFiltersVisible");
    if (isVisible === "true") {
      setResetFiltersVisible(true);
    } else {
      setResetFiltersVisible(false);
    }
    const currentPath = window.location.pathname;
    if (currentPath === "/products" || currentPath === "/products/") {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.toString() === "") {
        // يتحقق من عدم وجود query parameters
        localStorage.removeItem("resetFiltersVisible");
        setFromPrice("اختر سعر");
        setToPrice("اختر سعر");
      }
    }
  }, [localStorage.getItem("resetFiltersVisible")]);

  const [
    items,
    pagination,
    onPress,
    getProduct,
    results,
    fromPrice,
    setFromPrice,
    toPrice,
    setToPrice,
    filterByPrice,
    loading,
    filterBySize,
    setSize,
    sizeS,
    resetFilters,
  ] = ViewSearchProductsHook();
  const [val, setVal] = useState(false);

  return (
    <>
      <span className="show-filters" onClick={() => setVal(true)}>
        عرض الفلترات
        <MenuOutlinedIcon />
      </span>
      <div
        className={`side-filter ${val ? "activeFil" : ""}`}
        style={{
          background: "white",
          padding: "10px 12px",
          borderRadius: "8px",
        }}
      >
        <span className="close-filter" onClick={() => setVal(false)}>
          <CloseOutlinedIcon />
        </span>
        <center>الفلترات</center>
        <Row>
          <div className="d-flex flex-column mt-2">
            {resetFiltersVisible && (
              <button
                onClick={() => {
                  localStorage.removeItem("resetFiltersVisible");
                }}
                className="btn-more"
                style={{ marginBottom: "15px" }}
              >
                <a
                  href="/products"
                  style={{ color: "white" }}
                  onClick={resetFilters}
                >
                  العودة الى الاعدادات الافتراضية
                </a>
              </button>
            )}
            <div className="filter-title">الفئة</div>
            <ul className="mobile-menu-category-list mobile-menu-in-slide">
              {allCategory && allCategory.length
                ? categoriesWithSubcategories
                    .concat(categoriesWithoutSubcategories)
                    .slice(0, visibleCategories)
                    .map((item, index) => (
                      <li
                        className="menu-category filter-category-side"
                        key={index}
                      >
                        <button
                          className={`accordion-menu ${
                            activeAccordions.includes(index) ? "active" : ""
                          }`}
                          data-accordion-btn
                          onClick={() => handleAccordionClick(index)}
                        >
                          {" "}
                          <a
                            href={`/products?category=${item._id}`}
                            className="menu-title"
                            onClick={handleCategorySelection}
                          >
                            {item.name}
                          </a>
                          <div>
                            {filteration2(item) &&
                            filteration2(item).length > 0 ? (
                              <>
                                <AddOutlined className="add-icon" />
                                <RemoveOutlined className="remove-icon" />
                              </>
                            ) : null}
                          </div>
                        </button>
                        <ul
                          className={`submenu-category-list submenu-categories-slide ${
                            activeAccordions.includes(index) ? "active" : ""
                          }`}
                        >
                          {filteration2(item)}
                        </ul>
                      </li>
                    ))
                : null}
              {allCategory && allCategory.length > 4 && (
                <button
                  onClick={handleShowMoreCategories}
                  className="btn-morea"
                >
                  {visibleCategories < allCategory.length
                    ? "عرض المزيد"
                    : "عرض أقل"}
                </button>
              )}
            </ul>
          </div>

          {/* <div className="d-flex flex-column mt-2">
          <div className="filter-title mt-3">الماركة</div>
          <div className="d-flex mt-3">
            <input type="checkbox" value="" />
            <div className="filter-sub me-2 ">الكل</div>
          </div>
          <div className="d-flex mt-2">
            <input type="checkbox" value="" />
            <div className="filter-sub me-2 ">ابل</div>
          </div>
          <div className="d-flex mt-2">
            <input type="checkbox" value="" />
            <div className="filter-sub me-2 ">سامسونج</div>
          </div>
        </div> */}
          <div className="filter-title my-3">السعر</div>
          <div className="filter-by-price">
            <div>
              <span className="filter-sub me-2">من:</span>
              <input
                className="m-2 text-center form-control"
                type="number"
                value={fromPrice}
                onChange={(e) => setFromPrice(e.target.value)}
              />
            </div>
            <div>
              <span className="filter-sub me-2">الى:</span>
              <input
                className="m-2 text-center form-control"
                type="number"
                value={toPrice}
                onChange={(e) => setToPrice(e.target.value)}
              />
            </div>
          </div>
          <button onClick={filterByPrice} className="btn-primary">
            تطبيق الفلتر
          </button>
          <div className="filter-title my-3">المقاس</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <p
                className=" me-2"
                style={{ fontSize: "15px", textAlign: "center", color: "#444" }}
              >
                مثال :
                <br />
                (S,M,L,X,38,39,40)
              </p>
              <div>
                <input
                  className="m-2 text-center form-control"
                  type="text"
                  value={sizeS}
                  placeholder="ادخل مقاس"
                  onChange={(e) => {
                    setSize(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <button onClick={filterBySize} className="btn-primary">
            تطبيق الفلتر
          </button>
        </Row>
      </div>
    </>
  );
};

export default SideFilter;
