import React from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import rate from "../../images/rate.png";
import deleteicon from '../../images/delete.png'
import editicon from '../../images/edit-icon.png'
import DeleteRateHook from "../../hook/review/delete-rate-hook";
import EditRateHook from "../../hook/review/edit-rate-hook";
import ReactStars from "react-rating-stars-component";
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
const RateItem = ({item}) => {
const [isUser, loadingG,show,handleDelete,handleClose,handleShow] = DeleteRateHook(item)
  const [onChangeRateValue,newRateValue, loadingE,showEdit,handleEdit,handleCloseEdit,handleShowEdit,newRateText,onChangeNewRateText] = EditRateHook(item)
  const setting = {
    size: 20,
    count: 5,
    color: "#979797",
    activeColor: "#ffc107",
    value: newRateValue,
    a11y: true,
    isHalf: true, // تعيين قيمة false لعدم عرض النصف نجمة
    emptyIcon: <StarOutlineOutlinedIcon />,
    halfIcon: <StarHalfOutlinedIcon />,
    filledIcon: <StarOutlinedIcon />,
    onChange: (newValue) => {
 
  
      // إذا كانت القيمة المدخلة أقل من 1، استخدام قيمة الحد الأدنى 1
      onChangeRateValue(newValue);
    }
  };
  
  return (
    <div>
        <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>تاكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل انت متاكد من انك تريد حذف التقييم</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            تراجع
          </Button>
          {loadingG === true ? (
            <Button variant="danger">
                        <Spinner animation="border" variant="light" />

          </Button>
          ):(
            <Button variant="danger" onClick={handleDelete}>
            حذف
          </Button>
          )}
        
        </Modal.Footer>
      </Modal>
        <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        className={'font-just'}
  >
        <Modal.Header>
          <Modal.Title> تعديل التقييم</Modal.Title>
        </Modal.Header>
        <ReactStars {...setting}/>
        <input type="text" value={newRateText} onChange={onChangeNewRateText} 
         style={{width:'90%',padding:'8px 14px',outline:'0',margin:'auto',border:'1px solid #222'}} />       
          <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            تراجع
          </Button>
          {loadingE === true ? (
            <Button variant="primary">
                        <Spinner animation="border" variant="light" />

          </Button>
          ):(
            <Button variant="primary" onClick={handleEdit}>
            تعديل
          </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Row className="mt-3">
        <Col className="d-felx me-5" style={{display:'flex',alignItems:'center'}}>
          <div className="rate-name  d-inline ms-2">{item.user.name}</div>
          <img className="" src={rate} alt="" height="16px" width="16px" />
          <div className="cat-rate  d-inline  p-1 pt-2" style={{fontSize:'14px',color:'orange'}}>{item.rating}</div>
        </Col>
      </Row>
      <Row className="border-bottom mx-2">
        <Col className="d-felx me-4 pb-2">
          <div className="rate-description  d-inline ms-2">
    {item.review}
          </div>
          {isUser ? (
            <div className="d-inline d-flex justify-content-end">
            <img src={deleteicon} onClick={handleShow} width={'20px'} height={'20px'} style={{cursor:'pointer'}} alt="delete" />
            <img src={editicon} onClick={handleShowEdit} width={'20px'} height={'20px'} style={{cursor:'pointer'}} alt="delete" />
          </div>
          ):''}
        </Col>
      </Row>
    </div>
  );
};

export default RateItem;
