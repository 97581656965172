import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Pagination from "../Utility/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getProductWishList } from "../../redux/actions/wishlistAction";
import { HeartOutlined } from "@ant-design/icons";
import CardProductsContainer from "../Products/CardProductsContainer";
import { useChange } from "../../context/ChangeContext";
import { useChangeWishlist } from "../../context/ChangeWishlist";
import { useWishlist } from "../../context/WishlistRes";
import AdCom from "../AdsCompnents/AdCom";
const UserFavoriteProduct = () => {
  const disptach = useDispatch();
  const [items, setItems] = useState(true);
  const { res, loading } = useWishlist();

  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.data) {
          setItems(res.data);
        }
      }
    }
  }, [loading]);
  return (
    <div>
      <div className="wishlist-title">
        <HeartOutlined />
        <h2>قائمة المفضلات</h2>
      </div>

      {loading ? (
        <div style={{ width: "100vw", margin: "155px" }}>
          {" "}
          <Spinner animation="border" variant="dark  " />
        </div>
      ) : (
        <CardProductsContainer products={items} imgUrl={true} />
      )}
      <AdCom slot={'4582701660'} />
    </div>
  );
};

export default UserFavoriteProduct;
