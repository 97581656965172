import React, { useEffect, useState } from "react";
import ViewSearchProductsHook from "../products/view-search-product-hook";

const NavbarSearchHook = () => {
  const [searchWord, setSearchWord] = useState("");
  const [items, pagination, onPress, getProduct] = ViewSearchProductsHook();
  const onChangeSearch = (e) => {
    localStorage.setItem("searchWord", e.target.value);
    setSearchWord(e.target.value);
  };

  const onSubmitSearch = () => {
    const currentPath = window.location.pathname;

    // إذا كان المستخدم في رابط /products، قم بتنفيذ الكود الإضافي
    if (currentPath === "/products") {
      // الكود الإضافي يأتي هنا
    } else {
      // إذا كان المستخدم ليس في رابط /products، قم بتوجيهه إلى /products
      window.location.href = "/products";
    }

    getProduct();
  };
  useEffect(() => {
    setTimeout(() => {
      getProduct();
    }, 1000);
  }, [searchWord]);
  return [searchWord, onChangeSearch, onSubmitSearch];
};

export default NavbarSearchHook;
