import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LazyImage from "../Products/LazyImage";

const CategoryCard = ({ id, name, img, background, home, lg, md, sm, xs }) => {
  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      className={`my-4 ${home ? "" : "col-cat-home"}`}
    >
      <div className="allCard mb-3 d-flex">
        <div>
          <LazyImage imgUrl={img} isCat={true} />
          <img alt="zcv" src={img} className="category-card-img" />
          <p className="category-card-text my-2">{name}</p>
          <a className="title-hover" href={`/products?category=${id}`}>
            المزيد
          </a>
        </div>
      </div>
    </Col>
  );
};

export default CategoryCard;
