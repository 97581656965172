import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ForgetPasswordHook from "../../hook/auth/forget-password-hook";

const ForgetPasswordPage = () => {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.replace("/user/profile");
    }
  }, []);

  const [
    email,
    onChangeEmail,
    loading,
    onSubmit,
    isPress
  ] = ForgetPasswordHook();
  
  return (
    <Container className="min-height-e">
      <Row className="py-5 d-flex justify-content-center ">
        <Col sm="12" className="d-flex flex-column " dir="ltr">
          <label className="mx-auto title-login"> نسيت كلمة السر</label>
          <input
            placeholder="الايميل..."
            type="email"
            value={email}
            onChange={onChangeEmail}
            className="user-input my-3 text-center mx-auto"
          />
         
          <button className="btn-login mx-auto mt-3" onClick={onSubmit}>
            {isPress === true ? (
              loading === true ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "ارسال الكود"
              )
            ) : (
              "ارسال الكود"
            )}
          </button>
   
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default ForgetPasswordPage;
