import React from "react";
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import UserSideBar from "../../Components/User/UserSideBar";
import UserAddAddress from "../../Components/User/UserAddAddress";

const UserAddAddressPage = () => {
  return (
    <Container>
      <Row className="py-3">
        <Col md="2">
          <UserSideBar noSide={true} />
        </Col>
        <Col sm="12" xs="12" md="10">
          <UserAddAddress />
        </Col>
      </Row>
    </Container>
  );
};
export default UserAddAddressPage