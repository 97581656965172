import React from "react";
import "./else.css";
import SecondAd from "../../Components/AdsCompnents/2Ad";
import AdCom from "../../Components/AdsCompnents/AdCom";
const ShippingDelivery = () => {
  return (
    <div className="container-else">
      <h2>الشحن والتوصيل</h2>
      <p>
        يهمنا في شركة كيوي ان نقدم لك افضل تجربة شراء ولذلك نحرص على تجهيز طلبك
        بأسرع وقت وشحنه في نفس اليوم , وبقية الوقت يعتمد على شركة الشحن ويتم
        متابعتهم من قبل فريقنا حتى تصلك بأقرب وقت ممكن, ولا ينتهي دورنا حتى
        نتأكد من وصلوها لك بأمان
        <br />
        <br />
        <b>الشحن والتوصيل</b>
        <br />
        <br />
        <b>التوصيل والشحن داخل وخارج تركيا </b>
        <br />
        يسعدنا إبلاغكم بأننا نوفّر خدمة التوصيل حاليا داخل تركيا ومدة التوصيل
        غالباً 3 أيام وكحد اقصى 5 يوم عمل وخارج تركيا غالبا 7 أيام وكحدد اقصى 12
        يوم عمل , ولمعرفة أية تفاصيل أخرى متعلقة بأوقات التوصيل، يرجى التواصل
        معنا.
        <br />
        <br />
        <b>الغاء الطلب</b>
        <br />
        <br />
        إذا كنت ترغب بإلغاء طلبك قبل استلامه، اتصل بخدمة العملاء وسنبذل قصارى
        جهدنا لإلغائه، شريطة ألا يكون طلبك قد تم إرساله. في حال كان تم إرسال
        الطلب، فإن الإلغاء سيتم كاسترجاع للسلعة. يُرجى الرجوع إلى صفحة الاسترجاع
        أدناه لمزيد من المعلومات.
        <br />
        <br />
        <b>التوصيل خلال إجازات العيد</b>
        <br />
        <br />
        بسبب ضيق توفر خدمات التوصيل خلال فترة الأعياد؛ فإن أي طلب سيتم قبل
        الإجازة بعشرة ايام، سيتم توصيله بعد إنتهاء الإجازة بثلاث أيام.
        <br />
        <br />
        <b>في حال حدوث أضرار أو فقدان للمنتج</b>
        <br />
        <br />
        بالرغم من عنايتنا الشديدة أثناء التغليف، قد يلحق الضرر أحياناً بالسلع
        أثناء نقلها، وإن حدث ذلك نرجو منك مساعدتنا لرفع الحالة وتعويضك عن
        الأضرار الناتجة باتباع الخطوات التالية:
        <br />
        <ul>
          <li>توقيع إيصال الاستلام بعد الانتهاء من الفحص الكامل للسلعة.</li>
          <li>يرجى التحقق من المنتج جيدًا أمام ممثل شركة الشحن.</li>
          <li>
            في حال وجود أي ضرر في المنتج (المنتجات)، يرجى كتابة التفاصيل في
            ايصال الاستلام، مع طلب استرجاع المنتج في غضون فترة لا تتجاوز أول
            سبعة أيام من التوصيل. شركة كيوي لن تتحمل مسؤولية المنتجات المتضررة
            بعد هذه الفترة.
          </li>
          <li>احرص على تصوير الضرر في المنتج.</li>
          <li>عدم التخلص من الغلاف الداخلي أو الخارجي للمنتج.</li>
          <li>
            الاتصال بخدمة العملاء مباشرةً بعد اكتشاف العطل، كما يرجى منك
            الاستعداد برقم طلبك حتى يمكننا مساعدتك بأسرع ما يمكن.
          </li>
          <li>
            استلام الشحنة من شركة الشحن يعتبر إقرارا من العميل أن الشحنة سليم
            وبحالة جيدة ولم يتم فتحه قبل الإستلام.
          </li>
        </ul>
      </p>
      <AdCom slot={'7372516538'} />
    </div>
  );
};

export default ShippingDelivery;
