import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AddCategoryHook from "../../hook/category/add-category-hook";
const AdminAddCategory = () => {
  const [
    img,
    name,
    setName,
    loading,
    isPress,
    handelSubmit,
    onImageChange,
    onChangeName,
    aspectRatio,
    width,
  ] = AddCategoryHook();
  const memoizedImg = useMemo(() => img, [img]);
  const memoizedName = useMemo(() => name, [name]);
  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">اضف تصنيف جديدة</div>
        <Col sm="8">
          <div className="text-form pb-2">صورة التصنيف</div>
          <label htmlFor="upload-photo">
            <img
              src={memoizedImg}
              alt=""
              style={{
                width: `${width}px`,
                objectFit: "cover",
              }}
            />
          </label>
          <input
            type="file"
            id="upload-photo"
            name="photo"
            onChange={onImageChange}
            hidden
          />
          <input
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder="اسم التصنيف"
            value={memoizedName}
            onChange={onChangeName}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={handelSubmit} className={`btn-save d-inline mt-2 `}>
            Up Cat
          </button>
        </Col>
        {isPress ? (
          loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <h4>تم الانتهاء</h4>
          )
        ) : null}
      </Row>
      <br />
      <br />
      <br />
      <p>
        <b style={{ color: "red" }}>ملاحظة: </b>
        <br />
        1: يجب عدم اضافة صورة تصنيف كان قد تم رفعها من قبل على تصنيف مختلف
        <br />
        2: يجب عدم اضافة اسم تصنيف تم اسخدامه مع تصنيف مختلف
      </p>
      <ToastContainer />
    </div>
  );
};

export default AdminAddCategory;
