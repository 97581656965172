import React, { useEffect, useState } from "react";
import notify from "./../useNotifaction";
import { useDispatch, useSelector } from "react-redux";
import { createNewUser } from "../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";

const RegisterHook = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePhone = (value) => {
    setPhone(value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validationValues = () => {};

  const res = useSelector((state) => state.authReducer.createUser);
  function isValidEmail(email) {
    // يستخدم تعبيراً مناسباً لفحص صحة البريد الإلكتروني
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const onSubmit = async () => {
    if (name === "") {
      notify("من فضلك ادخل الاسم", "error");
      return;
    }
    if (email === "") {
      notify("من فضلك ادخل الايميل", "error");
      return;
    }
    
    if (!isValidEmail(email)) {
      notify("الرجاء إدخال بريد إلكتروني صحيح", "error");
      return;
    }
    
    if (phone.length === 0) {
      notify("الرجاء ادخال رقم ", "error");
    }
    if (phone.length < 9 || phone.length > 12) {
      notify("الرجاء ادخال رقم صحيح", "error");
      return;
    }

    if (password === "") {
      notify("من فضلك ادخل الايميل", "error");
      return;
    }
    if (confirmPassword === "") {
      notify("من فضلك ادخل تاكيد كلمة السر", "error");
      return;
    }
    if (password !== confirmPassword) {
      notify("كلمة السر غير متطابقة", "error");
      return;
    }
    setLoading(true);
    await dispatch(
      createNewUser({
        name: name,
        email: email,
        password: password,
        passwordConfirm: confirmPassword,
        phone: phone,
        isGoogle:false,
      })
    );
    setLoading(false);
  };
  useEffect(() => {
    if (loading === false) {
      if (res) {
        if(res.data){

       
        if (res.data.token) {
          localStorage.setItem("token", res.data.token);
          notify("تم انشاء الحساب بنجاح", "success");
          setTimeout(()=>{
              window.location.href = '/user/profile'
          },500)
        }
        if (res.data.errors) {
          if (res.data.errors[0].msg === "E-mail already in use") {
            notify("هذا الايميل موجود من قبل", "error");
          }
          if (res.data.errors[0].msg === "E-mail already in use") {
            notify("يجب ان تتكون كلمة السر من 6 حروف او اكثر", "error");
          }
        }
      }
      }
    }
  }, [loading]);
  return [
    name,
    email,
    phone,
    password,
    confirmPassword,
    onChangeName,
    onChangeEmail,
    onChangePhone,
    onChangePassword,
    onChangeConfirmPassword,
    loading,
    onSubmit,
  ];
};

export default RegisterHook;
