import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getAllProductsPage,
} from "../../redux/actions/productsAction";

const ViewProductsAdminHook = () => {
  const dispatch = useDispatch();
  let limit = 4;
  useEffect(() => {
    dispatch(getAllProducts(limit));
  }, []);

  const allProducts = useSelector((state) => state.allProducts.allProducts);
  const onPress = async (page) => {
    await dispatch(getAllProductsPage(limit, page));
  };
  let items = [];
  let pagination = [];
  try {
    if (allProducts) {
      items = allProducts;
    } else items = [];

    if (allProducts) {
      if (allProducts.paginationResult) {
        pagination = allProducts.paginationResult;
      } else pagination = [];
    } else pagination = [];
  } catch (e) {
    throw e;
  }
  return [items, pagination, onPress];
};

export default ViewProductsAdminHook;
