import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import orderReducer from './../../redux/reducers/orderReducer';
import { getOneOrder } from '../../redux/actions/orderAction';

const GetOrderDetailsHook = (id) => {
  const dispatch = useDispatch()
  const [loading,setLoading] = useState('')
    const [orderData,setOrderData] = useState([])
    const [cartItems,setCartItems] = useState([])
    useEffect(()=>{
        const get = async ()=>{
            setLoading(true)
            await dispatch(getOneOrder(id))
            setLoading(false)
        }
        get()
    },[])
    const resOneOrder = useSelector(state=>state.orderReducer.getOneOrder)
    useEffect(()=>{
        if(loading === false){
            if(resOneOrder){
                if(resOneOrder.data){
                    setOrderData(resOneOrder.data)
                    setCartItems(resOneOrder.data.cartItems)
                }
            }
        }
    },[loading])
    return [orderData,cartItems]
}

export default GetOrderDetailsHook