import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteReview } from '../../redux/actions/reviewAction';
import notify from '../useNotifaction';
import { useUser } from '../../context/getCurrentUser';
import { useChange } from '../../context/ChangeContext';

const DeleteRateHook = (item) => {
  const dispatch = useDispatch();
  const [isUser, setIsUser] = useState(false);
  const [loadingG, setLoadingG] = useState('');
  const [show, setShow] = useState(false);
  const { change, updateChange } = useChange();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  const { user } = useUser();

  useEffect(() => {
    // Check user data and item user data
    if(user){

      if (user.data && item.user && item.user._id === user.data._id) {
        setIsUser(true);
      }
    }
  }, [user, item.user]);

  const handleDelete = async () => {
    try {
      setLoadingG(true);
      await dispatch(deleteReview(item._id));
      setLoadingG(false);
      handleClose();
    } catch (error) {
      console.error('Error deleting review:', error);
      setLoadingG(false);
      handleClose();
    }
  };
const res = useSelector(state=>state.reviewReducer.deleteReview)
useEffect(() => {
    if (loadingG === false) {
      if (res ==='') {
        notify('تمت عملية المسح بنجاح', 'success');
        updateChange(Math.random(100 * 500))
      }
    }
  }, [loadingG]);
  return [isUser, loadingG,show,handleDelete,handleClose,handleShow];
};

export default DeleteRateHook;
