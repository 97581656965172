import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CartItem from "../../Components/Cart/CartItem";
import CartCheckout from "../../Components/Cart/CartCheckout";
import GetAllUserCartHook from "../../hook/cart/get-all-user-cart-hook";
import { ToastContainer } from "react-toastify";
import { ClipLoader } from "react-spinners";
const CartPage = () => {
  const [
    itemsNum,
    cartItems,
    totalPrice,
    couponName,
    totalPriceAfter,
    cartID,
    loadingCart
  ] = GetAllUserCartHook();
  return (
    <>
      {loadingCart  ? (
        <div className="loader-container">
          <ClipLoader color="#36D7B7" loading={loadingCart} size={50} />
        </div>
      ) : (
        <Container className={"min-height-e"}>
          <Row>
            <div className="cart-title mt-4">عربة التسوق</div>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs="12" md="9">
              {cartItems.length >= 1 ? (
                cartItems.map((item, index) => {
                  return <CartItem key={index} item={item} />;
                })
              ) : (
                <h5 style={{ margin: "50px", textAlign: "center" }}>
                  لا يوجد منتجات في العربة
                </h5>
              )}
            </Col>
            <Col xs="10" md="3">
              <CartCheckout
                cartItems={cartItems}
                totalPrice={totalPrice}
                couponName={couponName}
                totalPriceAfter={totalPriceAfter}
              />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      )}
    </>
  );
};

export default CartPage;
