import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FacebookLogo, InstagramLogo, Phone, TelegramLogo, TiktokLogo } from "phosphor-react";
import { Email, Facebook } from "@mui/icons-material";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-footer">
          <div className="sec aboutus">
            <h2>من نحن</h2>
            <p>
              شركة كيوي هي شركة مختصة في بيع الالبسة نقوم ببيع افضل واجود انواع
              الالبسة العالمية ونوفر الكثير من الميزات لعملائنا منها العروض
              الدائمة والتوصيل السريع والدفع الامن مقرنا الرئيسي في اسطنبول
              ونتطلع الى افتتاح فروع في دول اخرى
            </p>
            <ul className="sci">
              <li>
                <a href="https://www.tiktok.com/@kiwi.fashion1">
                  <TiktokLogo icon="fa-brands fa-tiktok" />
                </a>
              </li>
              <li>
                <a href="https://t.me/Rafifashion1" className="social-link">
                  <TelegramLogo />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/kiwi.fashion1/"
                  className="social-link"
                >
                  <InstagramLogo icon="fa-brands fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/KiWicompany0?mibextid=JRoKGi"
                  className="social-link"
                >
                  <Facebook />
                </a>
              </li>
            </ul>
          </div>
          <div className="sec quicklinks">
            <h2>روابط مهمة</h2>
            <ul>
              <li>
                <a href="/warranty-policy">سياسة الضمان</a>
              </li>
              <li>
                <a href="/about-us">عن شركة Kiwi </a>
              </li>
              <li>
                <a href="/shipping-delivery">الشحن والتوصيل </a>
              </li>
              <li>
                <a href="/return-policy">سياسة الاستبدال والاسترجاع</a>
              </li>
              {/* <li>
                <Link to="/furnishing-homes-and-projects">
                  تاثيث المنازل والمشاريع
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="sec quicklinks">
            <h2>تسوق</h2>
            <ul>
              <li>
                <a href="/products?category=658811a08997d1ce72815349">
                  شالات وحجابات
                </a>
              </li>
              <li>
                <a href="/products?category=658811878997d1ce72815346">
                  عبايات وكلابيات
                </a>
              </li>
              <li>
                <a href="/products?category=658811788997d1ce72815343">
                  مانطو وترانشكوت
                </a>
              </li>
              <li>
                <a href="/products?category=6588115f8997d1ce72815340">
                  سبورات وجاكيت
                </a>
              </li>
              <li>
                <a href="/products?category=6588112a8997d1ce7281533d">
                  بنطال وفيزون وتنورة
                </a>
              </li>
              <li>
                <a href="/products?category=6588111b8997d1ce7281533a">
                  بيجامة وتوابعها
                </a>
              </li>
              <li>
                <a href="/products?category=6588110c8997d1ce72815337">
                  لانجري وقطع داخلية
                </a>
              </li>
            </ul>
          </div>
          <div className="sec contact">
            <h2>تواصل معنا</h2>
            <ul className="info">
              <li>
                <span>
                  <Phone icon="fa-solid fa-phone" />
                </span>
                <p>
                  <a href="tel:00905395955434">00905395955434</a>
                </p>

                <span>
                  <Phone icon="fa-solid fa-phone" />
                </span>
                <p>
                  <a href="tel:00905511535307">00905511535307</a>
                </p>
              </li>
              <li>
                <span>
                  <Email icon="fa-solid fa-envelope" />
                </span>
                <p>
                  <a href="mailto:kiwi@kiwifashion.net">kiwi@kiwifashion.net</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="copyrightText">
        <p>جميع الحقوق محفوظة 2023 2024, شركة Kiwi</p>
      </div>
    </>
  );
};

export default Footer;
