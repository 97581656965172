import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../redux/actions/cartAction";
import notify from "../useNotifaction";
import { useChange } from "../../context/ChangeContext";
import { useChangeCart } from "../../context/ChangeCart";

const AddToCartHook = (id, item) => {
  const dispatch = useDispatch();
  const [loadingCart, setLoadingCart] = useState("");
  const [indexColor, setIndexColor] = React.useState("");
  const [colorText, setColorText] = React.useState("");
  const [colorName, setColorName] = React.useState("");
  const [indexSize, setIndexSize] = React.useState("");
  const [sizeText, setSizeText] = React.useState("");
  const [stopAdd, setStopAdd] = React.useState(true);
  const colorClick = (index, color,colorName) => {
    setIndexColor(index);
    setColorText(color);
    setColorName(colorName)
    setIndexSize(null);
    setSizeText("");
  };
  const sizeClick = (index, color) => {
    setIndexSize(index);

    setSizeText(color);
  };

  const handleAddToCart = async () => {
    if (!localStorage.getItem("token")) {
      notify("قم بتسجيل الدخول اولا", "warn");
      return;
    }
    if (colorText === "") {
      notify("الرجاء اختيار لون", "error");
      return;
    }

    setLoadingCart(true);
    let colorFrom = item.availableColorsPerSize.find(
      (c) => c.color === colorText
    );
    if (colorFrom && colorFrom.sizes) {
      let sizeF = colorFrom.sizes.find((s) => s.size === sizeText);
      if (sizeF && sizeF.quantity) {
        let sizeQ = sizeF.quantity;
      }
    }


    if (colorText && sizeText) {
      let res = await dispatch(
        addProductToCart({
          productId: id,
          color: colorText,
          size: sizeText,
          colorName:colorName, 
        })
      );
    } else if (!sizeText) {
      notify("الرجاء اختيار حجم", "error");
    } else {
      notify("لقد تجاوزت الحد الاقصى للمخزون", "warn");
    }
    setLoadingCart(false);
  };
  const { changeCart, updateChangeCart } = useChangeCart();

  const res = useSelector((state) => state.cartReducer.addProductToCart);
  useEffect(() => {
    if (loadingCart === false) {
      if (res && res.status === 500) {
        notify("قم بتسجيل الدخول اولا", "warn");
        return;
      }
      if (res && res.status === 200) {
        notify("تمت اضافة المنتج الى العربة", "success");
        updateChangeCart(Math.random(100 * 500));
      }
      if (res && res.status === 501) {
        notify("لقد تجاوزت الحد الاقصى للمخزون", "warn");
      }
    }
  }, [loadingCart]);

  return [
    handleAddToCart,
    colorClick,
    indexColor,
    colorText,
    loadingCart,
    indexSize,
    sizeClick,
    sizeText,
  ];
};

export default AddToCartHook;
