import React, { useEffect, useState } from 'react'
import { useUser } from "../../context/getCurrentUser";
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrders } from '../../redux/actions/orderAction';

const UserGetAllOrdersHook = () => {
  const {user} = useUser()
  const dispatch = useDispatch()
  const [loading,setLoading]= useState('')
  const [result,setResult]= useState('')
  const [orders,setOrders]= useState('')
  const [pagination,setPagination] = useState({})
  let userName = ''
  if(user != null){
    userName = user.data.name
  }
  const res = useSelector(state=>state.orderReducer.getAllOrders)
  useEffect(()=>{
     const get = async ()=>{
        setLoading(true)
        await dispatch(getAllOrders(7,''))
        setLoading(false)
     }
     get()
  },[])
  const onPress = async (page)=>{
    setLoading(true)
    await dispatch(getAllOrders(7,page))
    setLoading(false)
  }
  useEffect(()=>{
    if(loading === false){
        if(res){
            if(res.results){
                setResult(res.results)
            }
            if(res.paginationResult){
                setPagination(res.paginationResult)                
            }
            if(res.data){
                setOrders(res.data)

            }
        }
    }
  },[loading])

  return [userName,orders,result,pagination,onPress,loading]
}

export default UserGetAllOrdersHook