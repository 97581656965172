import baseURL from "../Api/baseURL";
let token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZmVlYTNhNDQ2MmVjOTNiZmViMTM5ZSIsImlhdCI6MTY5NDQyODE3NCwiZXhwIjoxNzAyMjA0MTc0fQ.SKE8evMUbDe22zRo3c-1twn8OuYT1Am8y8PkTBzi78E";

const useInsertData = async (url, params) => {
  // تمرير التوكن كمعامل
  try {
    let config;
    if (localStorage.getItem("token")) {
      config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
    }
    const res = await baseURL.post(
      url,
      params,
      localStorage.getItem("token") && config
    );
    return res;
  } catch (error) {
    throw error;
  }
};

const useInsertDataWithImage = async (url, params) => {
  // تمرير التوكن كمعامل
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  try {
    const res = await baseURL.post(url, params, config);
    return res;
  } catch (error) {
    throw error;
  }
};

export { useInsertDataWithImage, useInsertData };
