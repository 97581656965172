import React from "react";
import { Col, Row } from "react-bootstrap";
import "./AddAddress.css";
import AddAddressHook from "../../hook/user/add-address-hook";
import { ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-input-2";
const UserAddAddress = () => {
  const [
    country,
    onChangeCountry,
    postalCode,
    onChangePostalCode,
    city,
    onChangeCity,
    alias,
    onChangeAlias,
    details,
    onChangeDetails,
    phone,
    onChangePhone,
    loading,
    onSubmit,
  ] = AddAddressHook();
  return (
    <div className="user-add-address">
      <Row className="justify-content-center">
        <div className="admin-content-text pb-2 text-center">
          اضافة عنوان جديد
        </div>
        <Col sm="8">
          <input
            type="text"
            value={alias}
            onChange={onChangeAlias}
            className="input-form d-block mt-3 px-3"
            placeholder="تسمية العنوان مثلا(المنزل - العمل)"
          />
          <textarea
            value={details}
            onChange={onChangeDetails}
            className="input-form-area p-2 mt-3"
            rows="4"
            cols="50"
            placeholder="العنوان بالتفصيل الرجاء وضع اسم المنطقة مع العنوان "
          />
          {/* <input
            type="text"
            value={phone}
            onChange={onChangePhone}
            className="input-form d-block mt-3 px-3"
            placeholder="رقم الهاتف"
          /> */}

          <PhoneInput
            country={"tr"} // اختيار الدولة الافتراضية
            value={phone}
            onChange={onChangePhone}
            inputExtraProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
          />
          <input
            type="text"
            value={country}
            onChange={onChangeCountry}
            className="input-form d-block mt-3 px-3"
            placeholder="الدولة"
          />
          <input
            type="text"
            value={city}
            onChange={onChangeCity}
            className="input-form d-block mt-3 px-3"
            placeholder="المدينة"
          />
          <input
            type="number"
            value={postalCode}
            onChange={onChangePostalCode}
            className="input-form d-block mt-3 px-3"
            placeholder="الرمز البريدي"
          />
          <span style={{ margin: "12px 0" }}>
            اذا لم يكن لديك رمز بريدي ضع 0000 فقط
          </span>
          <br />
          <button className="btn-save" onClick={onSubmit}>
            اضافة عنوان
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default UserAddAddress;
