import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "./../useNotifaction";
import {
  createNewUser,
  getLoggedUser,
  loginUser,
} from "../../redux/actions/authAction";
import { jwtDecode } from "jwt-decode";
const LoginHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [isPress, setIsPress] = React.useState(false);
  const [noReg, setNoReg] = useState(true);
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const res = useSelector((state) => state.authReducer.loginUser);
  const responseGoogle = async (response) => {
    try {
      let res; // تعريف res في نطاق أوسع

      // التحقق من وجود profileObj قبل الوصول إلى googleId
      if (!response.clientId) {
        return;
      }


      const newEs = jwtDecode(response.credential);


      // استخدم newEs.nbf ككلمة مرور
      // const newPassword = newEs.nbf;

      // استخدم newPassword مباشرة ككلمة مرور في الطلبات
      setLoading(true);
      if (newEs.aud) {
        res = await dispatch(
          createNewUser({
            GoogleId: newEs.aud,
            name: newEs.name,
            email: newEs.email,
            isGoogle: true,
          })
        );
        setLoading(false);
  
        if (res && response.clientId) {
          if (res.data) {
            notify("تم تسجيل الدخول بنجاح ", "success");
            localStorage.setItem("token", res.data.token);
          }
        }
      }

      setTimeout(() => {
        if (!res) {
          notify("حدثت مشكلة في تسجيل الدخول الرجاء المحاولة لاحقاً", "error");
          return;
        }
        if (localStorage.getItem("token")) {
          window.location.href = "/";
        }
      }, 1400);
    } catch (error) {
      console.error("Error processing Google login response:", error);
    }
  };

  const onSubmit = async () => {
    if (email === "") {
      notify("الرجاء ادخال البريد الالكتروني", "error");
      return;
    }
    if (password === "") {
      notify("الرجاء ادخال كلمه السر", "error");
      return;
    }
    setLoading(true);
    setIsPress(true);
    await dispatch(
      loginUser({
        email,
        password,
      })
    );
    setIsPress(false);

    setLoading(false);
  };
  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.data) {
          if (res.data.token) {
            localStorage.setItem("token", res.data.token);
            notify("تم تسجيل الدخول بنجاح", "success");
            setTimeout(() => {
              window.location.replace("/");
              dispatch(getLoggedUser());
            }, 1500);
          } else {
            localStorage.removeItem("token");
          }
          if (res.data.errors) {
            if (res.data.errors[0].msg === "Invalid email formate") {
              localStorage.getItem("token") && localStorage.removeItem("token");
              notify("الرجاء ادخال ايميل صالح", "error");
            }
          }

          if (res.data.message === "Incorrect email or password") {
            localStorage.getItem("token") && localStorage.removeItem("token");
            notify("كلمة السر او الايميل غير صحيحين", "error");
          }

          setLoading(true);
        }
      }
    }
  }, [loading]);
  return [
    email,
    password,
    onChangeEmail,
    onChangePassword,
    loading,
    onSubmit,
    isPress,
    responseGoogle,
  ];
};

export default LoginHook;
