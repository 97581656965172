import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToCart,
  clearAllCartItems,
  clearCartItem,
  getAllCartItems,
  updateCartItem,
} from "../../redux/actions/cartAction";
import notify from "../useNotifaction";
import { useChangeCart } from "../../context/ChangeCart";
const DeleteCartHook = (item) => {
  const dispatch = useDispatch();
  const [loadingDeleteOne, setLoadingDeleteOne] = useState("");
  const [loadingDelete, setLoadingDelete] = useState("");
  const [itemCount, setItemCount] = useState(item ? item.count : 0);
  const { changeCart, updateChangeCart } = useChangeCart();

  const onChangeCount = (e) => {
    setItemCount(e.target.value);
  };

  const handleDeleteCart = async () => {
    setLoadingDelete(true);
    await dispatch(clearAllCartItems());
    setLoadingDelete(false);
  };
  const res = useSelector((state) => state.cartReducer.deleteAllCartItems);

  useEffect(() => {
    if (loadingDelete === false) {
      if (res === "") {
        notify("تم حذف منتجات العربة بنجاح", "success");
        updateChangeCart(Math.random(100 * 500));
      }
    }
  }, [loadingDelete]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDeleteOneProd = async () => {
    setLoadingDeleteOne(true);
    await dispatch(clearCartItem(item._id));
    setLoadingDeleteOne(false);
  };
  const resCart = useSelector((state) => state.cartReducer.deleteCartItem);
  useEffect(() => {
    if (loadingDeleteOne === false) {
      if (resCart && resCart.status === "success") {
        notify("تم حذف المنتج  من العربة بنجاح", "success");
        updateChangeCart(Math.random(100 * 500));
        setShow(false);
      } else {
        notify("حدث خطأ اثناء الحذف", "error");
        setShow(false);
      }
    }
  }, [loadingDeleteOne]);

  const handleUpdateCart = async () => {
    let res = await dispatch(updateCartItem(item._id, { count: itemCount }));
    if (res && res.status === 501) {
      notify(
        `لقد تخطيت حد المخزون يمكنك زيادة الكمية الى ${res.data.message}`,
        "error"
      );
      return;
    }
    window.location.reload()
  };

  return [
    handleDeleteCart,
    loadingDelete,
    handleDeleteOneProd,
    show,
    handleClose,
    handleShow,
    loadingDeleteOne,
    itemCount,
    onChangeCount,
    handleUpdateCart,
  ];
};

export default DeleteCartHook;
