import  { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { getAllCategory } from '../../redux/actions/categoryAction'

const HomeCategoryHook = () => {

    const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getAllCategory());
  }, [dispatch]);

  const category = useSelector((state) => state.allCategory.category);
  const loading = useSelector((state) => state.allCategory.loading);
  const categories = category.data;

  const colors = [
    "#ffd3e8",
    "#f4d8a5",
    "#55cfdf",
    "#ffd3e8",
    "#f4d8a5",
    "#ffd3e8",
  ];

    return [category,categories, loading, colors]
};

export default HomeCategoryHook;
