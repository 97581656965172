import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneCategory } from "../../redux/actions/subCategoryAction";
import { getAllCategory } from "../../redux/actions/categoryAction";
import { getAllBrand } from "./../../redux/actions/brandAction";
import {
  getOneProduct,
  updateProducts,
} from "../../redux/actions/productsAction";
import notify from "./../../hook/useNotifaction";

const AdminEditProductsHook = (id, setInStock, inStock, selectedColor) => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.allProducts.oneProduct);
  useEffect(() => {
    const run = async () => {
      await dispatch(getOneProduct(id));
      await dispatch(getAllCategory());
      await dispatch(getAllBrand());
    };
    run();
  }, [dispatch]);

  const category = useSelector((state) => state.allCategory.category);
  const brand = useSelector((state) => state.allBrand.brand);
  const subCat = useSelector((state) => state.allSubCategory.subcategory);

  const [images, setImages] = useState([]);

  const [prodName, setProdName] = useState("");
  const [prodDescription, setProdDescription] = useState("");
  const [priceBefore, setPriceBefore] = useState("السعر قبل الخصم");
  const [priceAfter, setPriceAfter] = useState("السعر بعد الخصم");
  const [priceAfterDollar, setPriceAfterDollar] = useState("السعر بعد الخصم");
  const [priceDollar, setPriceDollar] = useState("السعر بالدولار");
  const [qty, setQty] = useState("الكمية المتاحة");
  const [CatID, setCatID] = useState(0);
  const [BrandID, setBrandID] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedSubID, setSelectedSubID] = useState([]);
  const [colorName, setColorName] = useState("");
  const [showColor, setShowColor] = useState(false);
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState("#111");
  const [tempColor, setTempColor] = useState(color); // حالة مؤقتة لتخزين اللون المؤقت
  const [options, setOptions] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [newSize, setNewSize] = useState("");
  const [codeProd, setCodeProd] = useState("");
  const [newQtySize, setNewQtySize] = useState("");

  const [fullObjSize, setFullObjSize] = useState({});
  const handleSizeChange = (e) => {
    setNewSize(e.target.value);
  };
  const handleQtySizeChange = (e) => {
    setNewQtySize(e.target.value);
  };
  const handleColorNameChange = (e) => {
    setColorName(e.target.value);
  };
  const [descImages, setDescImages] = useState([]);

  const [lowerStock, setLowerStock] = useState(false);

  useEffect(() => {
    if (item) {
      if (item.data) {
        setColors(item.data.availableColorsPerSize);
        if (item.data.availableColorsPerSize) {
          let newSizesLa = colors.find((c) => c.color === selectedColor);
          if (newSizesLa) {
            setSizes(newSizesLa.sizes);
            setColorName(newSizesLa.colorName);
          }
        }
        setProdName(item.data.title);
        setCodeProd(item.data.code);
        setProdDescription(item.data.description);
        setPriceBefore(
          item && item.data && item.data.price ? item.data.price : ""
        );
        setPriceAfter(
          item && item.data && item.data.priceAfter ? item.data.priceAfter : ""
        );
        setPriceAfterDollar(
          item && item.data && item.data.priceDollarAfter
            ? item.data.priceDollarAfter
            : ""
        );
        setPriceDollar(
          item && item.data && item.data.priceDollar
            ? item.data.priceDollar
            : ""
        );
        setQty(item.data.quantity);
        setCatID(item.data.category);
        setSelectedSubID(item.data.subcategory);
        setBrandID(item.data.brand);
        setImages(item.data.images);
        setInStock(item.data.inStock);
        setLowerStock(item.data.lowerStock);
      }
    }
  }, [item]);
  useEffect(() => {
    if (item) {
      if (item.data) {
        if (item.data.category) {
          dispatch(getOneCategory(item.data.category));
        }
      }
    }
  }, [CatID, selectedSubID, item]);
  const onSelect = (selectedList, selectedItem) => {
    // استخدم القيمة المحدثة مع دالة setSelectedSubID
    setSelectedSubID(selectedList);
  };

  const onRemove = (selectedList, selectedItem) => {
    // الحصول على الـ title من الـ selectedItem
    const selectedTitle = selectedItem.name; // افترضت أن property name هي التي تحمل الـ title

    // إزالة العنصر المحدد باستخدام الـ title من selectedSubID
    const updatedSelectedSubID = selectedSubID.filter((subID) => {
      const subItem = options.find((option) => option.name === selectedTitle);
      return subItem && subItem._id != subID;
    });

    // تحديث selectedSubID
    setSelectedSubID(updatedSelectedSubID);
  };
  const onSelectCategory = async (selectedCategory) => {
    await setCatID(selectedCategory);
  };

  useEffect(() => {
    if (CatID != 0) {
      const rundis = async () => {
        await dispatch(getOneCategory(CatID));
      };
      rundis();
    }
  }, [CatID]);
  useEffect(() => {
    if (subCat.data) {
      setOptions(subCat.data);
    }
  }, [subCat]);
  const onSelectBrand = (selectedBrand) => {
    setBrandID(selectedBrand);
  };
  useEffect(() => {
    if (newSize && newQtySize) {
      setFullObjSize({
        size: newSize,
        quantity: parseFloat(newQtySize),
      });
    }
  }, [newSize, newQtySize]);

  const handleAddSize = () => {
    if (selectedColor) {
      if (fullObjSize && fullObjSize.size && fullObjSize.quantity) {
        // إضافة الحجم إلى اللون المحدد
        const updatedColors = colors.map((co) =>
          co.color === selectedColor
            ? {
                ...co,
                ...co.colorName,
                sizes: Array.isArray(co.sizes)
                  ? [...co.sizes, fullObjSize]
                  : [fullObjSize], // إذا لم يكن هناك sizes، قم بإنشاء مصفوفة جديدة للحجم
              }
            : co
        );
        // تحديث الـ sizes مع حجم جديد
        setSizes((prevSizes) => [...prevSizes, fullObjSize]);

        setColors(updatedColors);
        setNewSize(""); // إعادة تعيين القيمة الخاصة بالحجم
        setNewQtySize("");
        setFullObjSize({});
      }
    }
  };
  const handleAddColorName = () => {
    if (selectedColor && colorName) {
      // إضافة اسم اللون
      const updatedColors = colors.map((co) =>
        co.color === selectedColor
          ? {
              ...co,
              colorName,
            }
          : co
      );
      setColors(updatedColors);
      setColorName("");
    }
  };
  const handleDeleteSize = (index) => {
    // نسخ قيمة الـ sizes
    const updatedSizes = [...sizes];
    // حذف الحجم من الـ sizes
    updatedSizes.splice(index, 1);
    // تحديث قيمة الـ sizes
    setSizes(updatedSizes);

    // إذا كنت تستخدم الألوان والحجوم معًا
    // يمكنك تحديث الألوان أيضًا
    const updatedColors = colors.map((co) => {
      if (co.color === selectedColor && Array.isArray(co.sizes)) {
        // نسخ قيمة الحجوم
        const updatedSizesForColor = [...co.sizes];
        // حذف الحجم من الحجوم لللون المحدد
        updatedSizesForColor.splice(index, 1);
        // تحديث الحجوم للون المحدد
        return { ...co, sizes: updatedSizesForColor };
      }
      return co;
    });
    // تحديث الألوان
    setColors(updatedColors);
  };
  const onSaveButtonClick = () => {
    setColors([
      ...colors,
      {
        color: tempColor,
        colorName,
        sizes: [],
        totalQuantity: 0,
      },
    ]);

    setShowColor(false);
  };

  const [isDeleteColorConfirmationVisible, setDeleteColorConfirmationVisible] =
    useState(false);
  const [colorToDelete, setColorToDelete] = useState(""); // لتخزين اللون الذي يجب حذفه

  const showDeleteColorConfirmation = (color) => {
    setColorToDelete(color);
    setDeleteColorConfirmationVisible(true);
  };
  const hideDeleteColorConfirmation = () => {
    setColorToDelete("");
    setDeleteColorConfirmationVisible(false);
  };
  const confirmDeleteColor = () => {
    // قم بحذف اللون هنا
    const updatedColors = colors.filter((co) => co.color !== colorToDelete);
    setColors(updatedColors);

    // قم بإخفاء مربع الحوار بعد حذف اللون
    hideDeleteColorConfirmation();
  };

  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  //convert url to file
  const convertURLtoFile = async (url) => {
    if(url){

      const response = await fetch(url, { mode: "cors" });
      const data = await response.blob();
      const ext = url.split(".").pop();
      const filename = url.split("/").pop();
      const metadata = { type: `image/${ext}` };
      return new File([data], Math.random(), metadata);
    }
  };
  //to save data
  const [itemImages, setItemImages] = useState([]);
  const handelSubmit = async (e) => {
    e.preventDefault();
    if (
      CatID === 0 ||
      prodName === "" ||
      images.length <= 0 ||
      priceBefore <= 0
    ) {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }
    if (inStock === false || lowerStock === true) {
      notify(
        "اذا لم يكن هناك بالمخزون يجب تغيير حالة بقي القليل في المخزون الى يوجد في المخزون",
        "warn"
      );
      return;
    }
    let imgCover = "";
    if (images[0] && images[0].includes("https://kiendback.tech")) {
      imgCover = images[0];
    } else if (images[0].length <= 1000) {
      await convertURLtoFile(images[0]).then((val) => (imgCover = val));
    } else {
      imgCover = dataURLtoFile(images[0], Math.random() + ".jpg");
    }
    let itemImagese = [];

    Object.keys(images).forEach((index) => {
      const imageUrl = images[index];

      if (imageUrl.includes("https://www.kiendback.tech")) {
        itemImagese.push(imageUrl);
      } else if (imageUrl.length <= 1000) {
        convertURLtoFile(imageUrl).then((val) => itemImagese.push(val));
      } else {
        itemImagese.push(dataURLtoFile(imageUrl, Math.random() + ".png"));
      }
    });
    const formData = new FormData();
    formData.append("title", prodName);
    formData.append("description", prodDescription);
    formData.append("quantity", qty);
    formData.append("price", priceBefore);
    if (priceAfterDollar) {
      formData.append("priceDollarAfter", priceAfterDollar);
    }
    if (priceAfter && priceAfter != "0") {
      formData.append("priceAfter", priceAfter);
    } else {
      formData.append("priceAfter", 0);
    }
    if (priceDollar) {
      formData.append("priceDollar", priceDollar);
    }
    formData.append("code", codeProd);
    formData.append("category", CatID);
    formData.append("inStock", inStock);
    formData.append("lowerStock", lowerStock);
    // sizes.map((size) => formData.append("availableSizes", size));

    setSelectedSubID((sele) => sele.filter((subID) => subID));
    if (colors && colors.length > 0) {
      const colorsData = colors.map((color) => ({
        color: color.color,
        colorName: color.colorName,
        sizes: color.sizes.map((size) => ({
          size: size.size,
          quantity: size.quantity,
        })),
        totalQuantity: color.totalQuantity,
      }));
      // أضف البيانات إلى FormData
      colorsData.forEach((colorData, index) => {
        const colorKey = `availableColorsPerSize[${index}]`;
        formData.append(`${colorKey}[color]`, colorData.color);
        formData.append(`${colorKey}[colorName]`, colorData.colorName);
        colorData.sizes.forEach((size, sizeIndex) => {
          const sizeKey = `${colorKey}[sizes][${sizeIndex}]`;
          formData.append(`${sizeKey}[size]`, size.size);
          formData.append(`${sizeKey}[quantity]`, size.quantity);
        });
        formData.append(`${colorKey}[totalQuantity]`, colorData.totalQuantity);
      });
    } else {
      const colorsDataEmp = [
        {
          color: "#111",
          sizes: [
            {
              size: "S",
              quantity: 0,
            },
          ],
          totalQuantity: 0,
        },
      ];
      const jsonString = JSON.stringify(colorsDataEmp);
      formData.set("availableColorsPerSize", jsonString);
    }

    if (selectedSubID.length === 0) {
      // formData.append("subcategory", []);
    } else if (selectedSubID.length > 0) {
      selectedSubID.map((subCategoryID) => {
        if (typeof subCategoryID === "string") {
          formData.append("subcategory", subCategoryID);
        } else if (subCategoryID._id) {
          formData.append("subcategory", subCategoryID._id);
        }
      });
    }

    setTimeout(() => {
      formData.append("imageCover", imgCover);

      itemImagese.map((item) => {
        formData.append("images", item);
      });
      if (descImages && descImages.length > 0) {
        descImages.map((item) => formData.append("elseImages", item));
      } else {
        formData.append("elseImages", []);
      }
    }, 1000);
    setTimeout(async () => {
      setLoading(true);
      let res = await dispatch(updateProducts(id, formData));
      setLoading(false);
    }, 1500);
  };
  useEffect(() => {
    if (subCat.data) {
      setOptions(subCat.data);
    }
  }, [CatID, item]);
  // في useEffect الثاني بعد إرسال النموذج بنجاح
  const product = useSelector((state) => state.allProducts.updateProducts);
  useEffect(() => {
    if (loading === false) {
      setTimeout(() => setLoading(true), 1500);
      if (product) {
        if (product.status === 200) {
          notify("تم التعديل بنجاح", "success");
        } else {
          notify("هناك مشكله", "error");
        }
      }
    }
  }, [loading]);

  const onChangeProdName = useCallback((e) => {
    e.persist();
    setProdName(e.target.value);
  }, []);

  const onChangeDesName = useCallback((e) => {
    // e.persist();
    
    setProdDescription(e.target.value);
  }, []);
  const onChangeCodeProd = (e) => {
    setCodeProd(e.target.value);
  };
  const onChangePriceBefor = (e) => {
    e.persist();
    setPriceBefore(e.target.value);
  };
  const onChangePriceDollar = (e) => {
    e.persist();
    setPriceDollar(e.target.value);
  };
  const onChangeLowerStock = (e) => {
    setLowerStock(e.target.value);
  };
  const onChangePriceAfter = (e) => {
    e.persist();
    setPriceAfter(e.target.value);
  };
  const onChangePriceAfterDollar = (e) => {
    e.persist();
    setPriceAfterDollar(e.target.value);
  };
  const onChangeQty = useCallback((e) => {
    e.persist();
    setQty(e.target.value);
  }, []);
  const onChangeColor = useCallback((e) => {
    e.persist();
    setShowColor(!showColor);
  }, []);

  return [
    onChangeDesName,
    onChangeQty,
    onChangeColor,
    onChangeProdName,
    showColor,
    category,
    brand,
    images,
    setImages,
    onSelect,
    onRemove,
    options,
    onSelectCategory,
    handelSubmit,
    onSelectBrand,
    colors,
    qty,
    prodDescription,
    prodName,
    hideDeleteColorConfirmation,
    confirmDeleteColor,
    tempColor,
    setTempColor,
    showDeleteColorConfirmation,
    BrandID,
    CatID,
    onSaveButtonClick,
    isDeleteColorConfirmationVisible,
    setSelectedSubID,
    selectedSubID,
    setProdDescription,
    priceAfter,
    priceAfterDollar,
    priceBefore,
    priceDollar,
    onChangePriceAfter,
    onChangePriceAfterDollar,
    onChangePriceDollar,
    onChangePriceBefor,
    setDescImages,
    descImages,
    newSize,
    handleSizeChange,
    handleAddSize,
    sizes,
    handleDeleteSize,
    lowerStock,
    onChangeLowerStock,
    codeProd,
    onChangeCodeProd,
    setColors,
    setSizes,
    newQtySize,
    handleQtySizeChange,
    handleColorNameChange,
    colorName,
    setColorName,
    handleAddColorName,
  ];
};

export default AdminEditProductsHook;
