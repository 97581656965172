import baseURL from "../Api/baseURL";


const axiosConfig = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,  },
};

const useUpdateData = async (url, params) => {
  try {
    const res = await baseURL.put(url, params, axiosConfig);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const useUpdateDataWithImage = async (url, params) => {
  try {
    const response = await baseURL.put(url, params, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...axiosConfig.headers,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { useUpdateDataWithImage, useUpdateData };
