import {CREATE_ORDER_CASH} from "../type";

const inital = {
  createCashOrder: [],
  loading: true,
};
const checkoutReducer = (state = inital, action) => {
  switch (action.type) {
    case CREATE_ORDER_CASH:
      return {
        ...state,
        createCashOrder: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default checkoutReducer;
