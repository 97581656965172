import { DELETE_ADDRESS,ADD_USER_ADDRESS,GET_USER_ADDRESSES,GET_ONE_ADDRESS,UPDATE_ADDRESS } from "../type";

const inital = {
  addUserAddress:[],
  getUserAddresses:[],
  getOneAddress:[],
  updateAddress:[],
  deleteAddress:[],
  loading: true,
};
const userAddressReducer = (state = inital, action) => {
  switch (action.type) {
    case ADD_USER_ADDRESS:
      return {
        ...state,
        addUserAddress: action.payload,
        loading: false,
      };
    case GET_USER_ADDRESSES:
      return {
        ...state,
        getUserAddresses: action.payload,
        loading: false,
      };
    case GET_ONE_ADDRESS:
      return {
        ...state,
        getOneAddress: action.payload,
        loading: false,
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        updateAddress: action.payload,
        loading: false,
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        deleteAddress: action.payload,
        loading: false,
      };
  
    default:
      return state;
  }
};
export default userAddressReducer;
