import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import url from "../../Api/url";

const LazyImage = ({ imgUrl, items ,isCat}) => {
  const ref = useRef();
  const [inView, setInView] = useState(false);
  let callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setInView(true);
      }
    });
  };
  useEffect(() => {
    let observer = new IntersectionObserver(callback);

    if (ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      //   if (ref?.current) {
      //     observer.unobserve(ref.current);
      //   }
      observer.disconnect();
    };
  }, []);
  return inView ? (
    <img
      ref={ref}
      src={isCat ? imgUrl :
        imgUrl
          ? `${items?.images && items.images[0]}`
          : `${url}/products/${items?.images && items.images[0]}`
      }
      alt={`${items.title} - Kiwi Fashion`}
      className="product-img default"
      width="300"
    />
  ) : (
    <img
      ref={ref}
      style={{ background: "#eee" }}
      className="product-img default"
      width="300"
    />
  );
};

export default LazyImage;
