import  { useState ,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VerifyPasswordUser } from '../../redux/actions/authAction'
import notify from '../useNotifaction'
import { useLocation, useNavigate } from 'react-router-dom'

const VerifyPasswordHook = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState('')
  const location = useLocation();
  const { email } = location.state || {};

  const [isPress, setIsPress] = useState(false)
  const onChangeCode = (e)=>{
    setCode(e.target.value)
  }
  const onSubmit =async ()=>{
    if(code === "" && code.length < 6){
        notify('الرجاء ادخال الكود','error')
        return;
    }
    setLoading(true)
    setIsPress(true)
    await dispatch(VerifyPasswordUser({
        resetCode:code
    }))
    setLoading(false)
    setIsPress(false)
  }
  const res = useSelector((state)=>state.authReducer.verifyPassword)
  useEffect(()=>{
    if(loading === false){
        if(res){
            if(res.data){

           
            if(res.data.status === 'Success'){
                notify('الكود صحيح تم الارسال بنجاح','success')
                setTimeout(()=>{
                     

                    navigate('/user/reset-password', { state: { email } })
                },1000)
            }
            else if(res.data.status === 'fail'){
                notify('الكود خاطئ او انتهت صلاحيته','error')
            }
            else if(res.data.status === 'error'){
                notify('يوجد خطأ او الحساب غير موجود','error')
            }
        }
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loading])
  return [  code,
    onChangeCode,
    loading,
    onSubmit,isPress]

}

export default VerifyPasswordHook