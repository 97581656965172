import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import AddRateHook from "../../hook/review/add-rate-hook";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
const PostRate = () => {
  const { id } = useParams();

  const [
    rateText,
    onChangeRateText,
    onChangeRateValue,
    rateValue,
    setRateValue,
    loading,
    onSubmit,
    isPress,
    user,
  ] = AddRateHook(id);
  if (user) {
    var nameUser = "";
    if (user.data) {
      nameUser = user.data.name;
    }
  }

  const setting = {
    size: 20,
    count: 5,
    color: "#979797",
    activeColor: "#ffc107",
    value: rateValue,
    a11y: true,
    isHalf: true, // تعيين قيمة false لعدم عرض النصف نجمة
    emptyIcon: <StarOutlineOutlinedIcon />,
    halfIcon: <StarHalfOutlinedIcon />,
    filledIcon: <StarOutlinedIcon />,
    onChange: (newValue) => {
      onChangeRateValue(newValue);
    },
  };

  return (
    <div>
      <Row className="mt-3 ">
        <Col sm="12" className="me-5  d-flex">
          <div className="rate-name  d-inline ms-3 mt-1 ">{nameUser}</div>
          <ReactStars {...setting} />
        </Col>
      </Row>
      <Row className="border-bottom mx-2">
        <Col className="d-felx me-4 pb-2">
          <textarea
            value={rateText}
            onChange={onChangeRateText}
            className="input-form-area p-2 mt-3"
            rows="2"
            cols="20"
            placeholder="اكتب تعليقك...."
          />
          <div className=" d-flex justify-content-end al">
            {loading === true ? (
              <div className="product-cart-add">
                {" "}
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div onClick={onSubmit} className="product-cart-add">
                اضف تقييم
              </div>
            )}
          </div>
        </Col>
      </Row>
      <NotificationContainer/>

    </div>
  );
};

export default PostRate;
