export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_ERROR = "GET_ERROR";
export const GET_ALL_BRAND = "GET_ALL_BRAND";
export const CREATE_BRAND = "CREATE_BRAND";
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
export const GET_ALL_SUB_CATEGORY = "GET_ALL_SUB_CATEGORY";
export const GET_ONE_SUB_CATEGORY = "GET_ONE_SUB_CATEGORY";
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const CREATE_PRODUCTS = "CREATE_PRODUCTS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY";
export const GET_ALL_CATEGORY_NAV = "GET_ALL_CATEGORY_NAV";
export const GET_ONE_BRAND = "GET_ONE_BRAND";
export const GET_PRODUCT_LIKE = "GET_PRODUCT_LIKE";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const GET_PRODUCT_IMAGES = "GET_PRODUCT_IMAGES";
export const GET_ALL_SUB_CATEGORY_NAV = "GET_ALL_SUB_CATEGORY_NAV";
export const GET_ALL_PRODUCTS_HOME = "GET_ALL_PRODUCTS_HOME";
export const GET_ALL_PRODUCTS_HOME_SORT = "GET_ALL_PRODUCTS_HOME_SORT";
export const GET_ALL_PRODUCTS_NEW = "GET_ALL_PRODUCTS_NEW";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const LOGIN_USER = "LOGIN_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CREATE_REVIEW = "CREATE_REVIEW";
export const GET_ALL_REVIEWS = "GET_ALL_REVIEWS";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const UPDATE_REVIEW_ON_PRODUCT = "UPDATE_REVIEW_ON_PRODUCT";
export const ADD_PRODUCT_TO_WISHLIST = "ADD_PRODUCT_TO_WISHLIST";
export const REMOVE_PRODUCT_FROM_WISHLIST = "REMOVE_PRODUCT_FROM_WISHLIST";
export const USER_WISHLIST = "USER_WISHLIST";
export const ADD_COUPON = "ADD_COUPON";
export const GET_COUPONS = "GET_COUPONS";
export const DELETE_COUPON = "DELETE_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const GET_ONE_COUPON = "GET_ONE_COUPON";
export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS";
export const GET_USER_ADDRESSES = "GET_USER_ADDRESSES";
export const GET_ONE_ADDRESS = "GET_ONE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_ALL_CART_ITEMS = "GET_ALL_CART_ITEMS";
export const DELETE_ALL_CART_ITEMS = "DELETE_ALL_CART_ITEMS";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const APPLY_COUPON_CART = "APPLY_COUPON_CART";
export const CREATE_ORDER_CASH = "CREATE_ORDER_CASH";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ONE_ORDER = "GET_ONE_ORDER";
export const CHANGE_ORDER_PAY = "CHANGE_ORDER_PAY";
export const CHANGE_ORDER_UNPAY = "CHANGE_ORDER_UNPAY";
export const CHANGE_ORDER_DELIVER = "CHANGE_ORDER_DELIVER";
export const CHANGE_ORDER_UNDELIVER = "CHANGE_ORDER_UNDELIVER";
export const CHANGE_ORDER_MESSAGE = "CHANGE_ORDER_MESSAGE";
export const GET_ALL_PRODUCTS_PRESS = "GET_ALL_PRODUCTS_PRESS";
export const OUT_STOCK = "OUT_STOCK";
export const DELETE_USER = "DELETE_USER";
export const GET_USERS = "GET_USERS";
