import {
  CREATE_PRODUCTS,
  DELETE_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_HOME,
  GET_ALL_PRODUCTS_HOME_SORT,
  GET_ALL_PRODUCTS_NEW,
  GET_ERROR,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_IMAGES,
  UPDATE_PRODUCTS,
  GET_ALL_PRODUCTS_PRESS
} from "../type";
import { memo } from 'react';

const inital = {
  products: [],
  allProducts: [],
  oneProduct: [],
  oneProductImages: [],
  deleteProducts: [],
  allProductsHome:[],
  allProductsHomeSort: [],
  allProductsNew:[],
  updateProducts: [],
  allProductsPress:[],
  loading: true,
};

const productsReducer = (state = inital, action) => {
  switch (action.type) {
    case CREATE_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCTS_NEW:
      return {
        ...state,
        allProductsNew: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCTS_PRESS:
      return {
        ...state,
        allProductsNew: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCTS_HOME:
      return {
        ...state,
        allProductsHome: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCTS_HOME_SORT:
      return {
        ...state,
        allProductsHomeSort: action.payload,
        loading: false,
      };
    case GET_PRODUCT_DETAILS:
      return {
        oneProduct: action.payload,
        loading: false,
      };
    case GET_PRODUCT_IMAGES:
      return {
        oneProductImages: action.payload,
        loading: false,
      };
    case DELETE_PRODUCTS:
      return {
        ...state,
        deleteProducts: action.payload,
        loading: false,
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        updateProducts: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        loading: true,
        products: action.payload,
      };
    default:
      return state;
  }
};
export default productsReducer;
