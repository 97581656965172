import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProductWishList } from '../../redux/actions/wishlistAction'
import { useWishlist } from '../../context/WishlistRes'

const CardContainerHook = () => {
  
    const dispatch = useDispatch()
    const [favProd,setFavProd]= useState([])
    const {res,loading} = useWishlist()
    useEffect(()=>{
      if(loading === false){
        if(res){
          if(res.data && res.data.length){
  
            setFavProd(res.data.map(item=>item._id))
          }
        }
      }
    },[loading])

    return [favProd]
}

export default CardContainerHook