import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import VerifyPasswordHook from "../../hook/auth/verify-password-hook";
import { useLocation } from "react-router-dom";

const VerifyPasswordPage = () => {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.replace("/user/profile");
    }
  }, []);

  const [
    code,
    onChangeCode,
    loading,
    onSubmit,
    isPress
  ] = VerifyPasswordHook();

  return (
    <Container className="min-height-e">
      <Row className="py-5 d-flex justify-content-center ">
        <Col sm="12" className="d-flex flex-column " dir="ltr">
          <label className="mx-auto title-login">دخل كود التحقق</label>
          <input
            placeholder="الكود..."
            type="text"
            value={code}
            onChange={onChangeCode}
            className="user-input my-3 text-center mx-auto"
          />
         
          <button className="btn-login mx-auto mt-3" onClick={onSubmit}>
            {isPress === true ? (
              loading === true ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "تأكيد الكود"
              )
            ) : (
                "تأكيد الكود"            )}
          </button>
   
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default VerifyPasswordPage;
