import { Container, Stack } from "@mui/material";
import React from "react";
import AdminAllUsers from "../../Components/Admin/AdminAllUsers";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import ViewUsersAdminHook from "../../hook/admin/view-users-admin-hook";
import Pagination from "../../Components/Utility/Pagination";

const AdminAllUsersPage = () => {
  const [items, pagination, onPress, loading] = ViewUsersAdminHook();
  return (
    <Container>
      <Stack direction="row" gap={4} display={"flex"}>
        <Stack flex={0.3} sm={5} xs={12} md={3}>
          <AdminSideBar />
        </Stack>
        <Stack
          flex={1.7}
          sm={7}
          xs={12}
          md={9}
          direction={"column"}
          spacing={2}
        >
          <AdminAllUsers items={items} loading={loading} />
          
          <Pagination onPress={onPress} pageCount={pagination.numberOfPages} />
        </Stack>
      </Stack>
    </Container>
  );
};

export default AdminAllUsersPage;
