import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createBrand } from "../../redux/actions/brandAction";
import "react-toastify/dist/ReactToastify.css";
import notify from "../../hook/useNotifaction";
import avatar from "../../images/avatar.png";

const AddBrandHook = () => {
  //to change name state
  const onChangeName = (event) => {
    event.persist();
    setName(event.target.value);
  };

  //when image change save it
  const dispatch = useDispatch();
  const [img, setImg] = useState(avatar);
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  const aspectRatio = 16 / 9;
  //to change name state

  //when image change save it
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
    }
  };
  const res = useSelector((state) => state.allBrand.brand);

  //save data in database
  const handelSubmit = async (event) => {
    event.preventDefault();
    if (name === "" || selectedFile === null) {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", selectedFile);
    setLoading(true);
    setIsPress(true);
    try {
        await dispatch(createBrand(formData));
        setLoading(false);
    
        // بعد الإرسال بنجاح، قم بإعادة تعيين الحقول وإفراغ FormData
        setName("");
        setSelectedFile(null);
        formData.delete("name");
        formData.delete("image");
      } catch (error) {
        setLoading(false);
        setIsPress(false);
        console.error("حدث خطأ أثناء إرسال البيانات:", error);
        notify("حدث خطأ أثناء إرسال البيانات", "error");
      }
};
  useEffect(() => {
    if (loading === false) {
      setImg(avatar);
      setName("");
      setSelectedFile(null);
      setLoading(true);
      setIsPress(false)
      if (res.status === 201) {
        notify("تمت عملية الاضافة بنجاح", "success");
      } else {
        notify("هناك مشكله فى عملية الاضافة", "error");
      }
    }
  }, [loading]);

  // حساب العرض بناءً على الارتفاع ونسبة العرض إلى الارتفاع
  const width = 140 * aspectRatio;
  return [
    img,
    name,
    setName,
    loading,
    isPress,
    handelSubmit,
    onImageChange,
    onChangeName,
    aspectRatio,
    width
  ];
};

export default AddBrandHook;
