import { createContext, useContext, useEffect, useState } from "react";
import { getProductWishList } from "../redux/actions/wishlistAction";
import { useDispatch } from "react-redux";
import { useChangeWishlist } from "./ChangeWishlist";

const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const { changeWishlist, updateChangeWishlist } = useChangeWishlist();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState("");
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await dispatch(getProductWishList());
      if (response) {
        setRes(response);
      }
      setLoading(false);
    };
    fetch();
  }, [changeWishlist, dispatch]);

  return (
    <WishlistContext.Provider value={{ res, loading }}>
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => {
  return useContext(WishlistContext);
};
