import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Alert } from "react-bootstrap";
import "./UserProfile.css";
import { useUser } from "../../context/getCurrentUser";
import notify from "./../../hook/useNotifaction";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword, updateUser } from "../../redux/actions/userAction";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

const UserProfile = () => {
  const { user } = useUser();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState();
  const [loadingN, setLoadingN] = useState();
  const [name, setName] = useState("  ");
  const [email, setEmail] = useState("  ");
  const [phone, setPhone] = useState("  ");
  const [isGoogle, setIsGoogle] = useState(true);
  useEffect(() => {
    setLoadingN(true);
    if (user && user.data) {
      setName(user.data.name);
      setEmail(user.data.email);
      setPhone(user.data.phone);

      setLoadingN(false);
      if (!user.data.GoogleId) {
        setIsGoogle(false);
      }
    }
  }, [user]);

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePhone = (e) => {
    const inputValue = e.target.value.replace(/[^0-9\s]/g, "");
    setPhone(inputValue);
  };

  const isValidEmail = (email) => {
    // الصيغة الأساسية للتحقق من البريد الإلكتروني
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = async () => {
    if (phone === "" || name === "" || email === "") {
      notify("الرجاء إدخال بيانات صالحة", "error");
      return;
    }
    if (phone.length > 20) {
      notify("الرجاء إدخال رقم هاتف صالح", "error");
      return;
    }

    // التحقق من صحة البريد الإلكتروني
    if (!isValidEmail(email)) {
      notify("الرجاء إدخال بريد إلكتروني صحيح", "error");
      return;
    }

    setLoading(true);

    try {
      await dispatch(
        updateUser(user.data._id, {
          name: name,
          phone: phone,
          email: email,
          role: user.data.role,
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const res = useSelector((state) => state.userReducer.updateUser);
  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.status === 200) {
          notify("تم تعديل البيانات بنجاح", "success");
        } else {
          notify(
            "حديث خطأ اثناء تحديث البيانات الرجاء اعادة تحميل الصفحة والمحاولة مرة اخرى"
          );
        }
      }
    }
  }, [res, loading]);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  const [loadingPass, setLoadingPass] = useState("");
  const onSubmitPass = async () => {
    setLoadingPass(true);
    setLoading(true);
    if (user.data._id === "") {
      setLoading(false);
      notify("الرجاء الانتظار قليلا", "error");
      return;
    }
    await dispatch(
      updatePassword(user.data._id, {
        currentPassword: password,
        password: newPassword,
        passwordConfirm: newPassword,
      })
    );
    setLoadingPass(false);
    setLoading(false);
  };
  const resPass = useSelector((state) => state.userReducer.updatePassword);
  useEffect(() => {
    if (loadingPass === false) {
      if (resPass) {
        if (resPass.status === 200) {
          notify("تم تغيير كلمة السر", "success");
          setTimeout(() => {
            // localStorage.removeItem("token");
            // window.location.replace("/login");
            window.location.reload()
          }, 1500);
        }
      }
    }
  }, [loadingPass, resPass]);

  return (
    <div>
      <Helmet>
        <title> الصفحة الشخصية , Kiwi Fashion</title>
        <meta
          name="description"
          content={` الصفحة الشخصية  في كيوي, Kiwi Fashion `}
        />
      </Helmet>
      <div className="admin-content-text" style={{ marginBottom: "36px" }}>
        الصفحه الشخصية
      </div>
      <div className=" my-3 px-2 ">
        {loadingN === true ? (
          <Spinner animation="border" />
        ) : (
          <>
            {!phone && (
              <div style={{ width: "100% !important" }}>
                <Alert
                  severity="error"
                  style={{
                    fontFamily: "tajawala !important",
                    textAlign: "center",
                  }}
                >
                  الرجاء اضافة رقم هاتف
                </Alert>
              </div>
            )}
            <Row className={"user-info d-flex"}>
              <h2>معلومات حول حسابك</h2>
              <div className="form-user">
                <div>
                  <label htmlFor="input-profile-username"> الاسم:</label>
                  <input
                    type="text"
                    id="input-profile-username"
                    value={name}
                    onChange={onChangeName}
                  />
                </div>
                <div>
                  <label htmlFor="input-profile-email"> الايميل:</label>
                  <input
                    dir="ltr"
                    type="email"
                    id="input-profile-email"
                    value={email}
                    onChange={onChangeEmail}
                  />
                </div>
                <div>
                  <label htmlFor="input-profile-phone-number">
                    {" "}
                    رقم الهاتف:
                  </label>

                  <input
                    dir="ltr"
                    type="text"
                    id="input-profile-phone-number"
                    value={`${phone ? `+${phone}` : "لا يوجد رقم هاتف"}`}
                    onChange={onChangePhone}
                  />
                </div>
                <div className="btn-div">
                  <button onClick={onSubmit} className="btn-edit-profile">
                    تعديل
                  </button>
                </div>
              </div>
            </Row>

            {!isGoogle && isGoogle === false && (
              <Row className="mt-5 reset-password-profile">
                <Col xs="10" sm="8" md="6" className="change-password-form">
                  <div className="admin-content-text change-password-form">
                    تغيير كلمة المرور
                  </div>
                  <br />

                  <input
                    type="password"
                    className=" mt-1 px-3 change-password-form"
                    placeholder="ادخل كلمة المرور القديمة"
                    onChange={onChangePassword}
                    value={password}
                  />
                  <input
                    type="password"
                    className=" mt-3 px-3 change-password-form"
                    placeholder="ادخل كلمة المرور الجديده"
                    onChange={onChangeNewPassword}
                    value={newPassword}
                  />
                </Col>
                <Col
                  xs="10"
                  sm="8"
                  md="6"
                  className="d-flex justify-content-center "
                  style={{ margin: "auto" }}
                >
                  <button
                    onClick={onSubmitPass}
                    className={`btn-save mt-2 ${
                      loading === true ? "stop-btn-save" : ""
                    }`}
                  >
                    {/* {loading === true ? <Spinner animation="border" variant="light" /> : 'تغيير كلمة المرور'} */}
                    تغيير كلمة المرور
                  </button>
                </Col>
              </Row>
            )}
          </>
        )}
        <br />
      </div>
      <div style={{zIndex:'999999 !important'}}>

      <ToastContainer />
      </div>
    </div>
  );
};

export default UserProfile;
