import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminAddSubcategoryHook from "../../hook/subcategory/add-subcategory-hook";

const AdminAddSubCategory = () => {
  const [
    id,
    name,
    loading,
    category,
    subcategory,
    handelChange,
    handelSubmit,
    onChangeName,
  ] = AdminAddSubcategoryHook(); 

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">اضافه تصنيف فرعي جديد</div>
        <Col sm="8">
          <input
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder="اسم التصنيف الفرعي"
            // تعيين قيمة الـ name عند تغيير المدخلات في الـ <input>
            onChange={onChangeName}
            value={name}
          />
          <select
            name="languages"
            id="lang"
            className="select-sub-cat mt-3 px-2 "
            onChange={handelChange}
            disabled={!loading}
            value={id}
          >
            <option value="0">اختر تصنيف رئيسي</option>
            {category.data && category.data.length > 0 ? (
              category.data.map((item,index) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))
            ) : (
              <option value="loading">يتم التحميل...</option>
            )}
          </select>
          {!loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">يتم التحميل...</span>
            </Spinner>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={handelSubmit}
            className={`btn-save d-inline mt-2 ${
              !loading ? "btn-disabled" : ""
            }`}
          >
            حفظ التعديلات
          </button>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <p>
        <b style={{ color: "red" }}>ملاحظة: </b>
        <br />
        1: يجب عدم اضافة اسم تصنيف فرعي تم اسخدامه مع تصنيف فرعي اخر مختلف
      </p>
      <ToastContainer />
    </div>
  );
};

export default AdminAddSubCategory;
