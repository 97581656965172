import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAddresses } from '../../redux/actions/userAdressesAction'
import { useChange } from '../../context/ChangeContext'

const ViewUserAddressesHook = () => {
    const dispatch = useDispatch()
    const [items,setItems] = useState([])
    const [loading,setLoading] = useState(true)
    const {change,updateChange} = useChange()
    useEffect(()=>{
        const fetch = async ()=>{
            setLoading(true)
            await dispatch(getUserAddresses())
            setLoading(false)
        }
        fetch()
    },[change])

    const res = useSelector(state=>state.userAddressReducer.getUserAddresses)
    useEffect(()=>{
        if(res && res.data){
            setItems(res)
        }
    },[res,change])
    return [items,loading]
}

export default ViewUserAddressesHook