/* eslint-disable react-hooks/rules-of-hooks */
import { CREATE_NEW_USER, LOGIN_USER, GET_CURRENT_USER ,RESET_PASSWORD,FORGET_PASSWORD,VERIFY_PASSWORD} from "../type";
import {  useGetDataToken } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { useUpdateData } from "../../hooks/useUpdateData";
export const createNewUser = (data) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/auth/signup", data);
    dispatch({
      type: CREATE_NEW_USER,
      payload: response,
      loading: true,
    });
    return response 
  } catch (e) {
    dispatch({
      type: CREATE_NEW_USER,
      payload: e.response,
    });
  }
};
export const loginUser = (data) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/auth/login", data);

    dispatch({
      type: LOGIN_USER,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: LOGIN_USER,
      payload: e.response,
    });
  }
};
export const forgetPasswordUser = (data) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/auth/forgotPasswords", data);
    dispatch({
      type: FORGET_PASSWORD,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: FORGET_PASSWORD,
      payload: e.response,
    });
  }
};
export const VerifyPasswordUser = (data) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/auth/verifyResetCode", data);
    dispatch({
      type: VERIFY_PASSWORD,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: VERIFY_PASSWORD,
      payload: e.response,
    });
  }
};
export const ResetPasswordUser = (data) => async (dispatch) => {
  try {
    const response = await useUpdateData("/api/v1/auth/resetPassword", data);
    dispatch({
      type: RESET_PASSWORD,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: RESET_PASSWORD,
      payload: e.response,
    });
  }
};
export const getLoggedUser = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/users/getMe`);
    dispatch({
      type: GET_CURRENT_USER,
      payload: response,
      loading: true,
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_CURRENT_USER,
      payload: e.response,
    });
  }
};
