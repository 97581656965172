import { ADD_USER_ADDRESS,GET_USER_ADDRESSES,GET_ONE_ADDRESS,UPDATE_ADDRESS,DELETE_ADDRESS} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetDataToken } from './../../hooks/useGetData';
import { useUpdateData } from "../../hooks/useUpdateData";
import useDeleteData from "../../hooks/useDeleteData";
export const addUserAddress = (data) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/addresses", data);
    dispatch({
      type: ADD_USER_ADDRESS,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: ADD_USER_ADDRESS,
      payload: e.response,
    });
  }
};
export const getUserAddresses = () => async (dispatch) => {
  try {
    const response = await useGetDataToken("/api/v1/addresses");
    dispatch({
      type: GET_USER_ADDRESSES,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: GET_USER_ADDRESSES,
      payload: e.response,
    });
  }
};
export const getOneAddress = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/addresses/${id}`);
    dispatch({
      type: GET_ONE_ADDRESS,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: GET_ONE_ADDRESS,
      payload: e.response,
    });
  }
};
export const updateAddress = (id,body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/addresses/${id}`,body);
    dispatch({
      type: UPDATE_ADDRESS,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_ADDRESS,
      payload: e.response,
    });
  }
};
export const deleteAddress = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/addresses/${id}`);
    dispatch({
      type: DELETE_ADDRESS,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: DELETE_ADDRESS,
      payload: e.response,
    });
  }
};