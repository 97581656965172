import React, { Suspense, lazy } from "react";
import Carousel from "react-elastic-carousel";
import ReviewCard from "./ReviewCard";
import FirstAd from "../AdsCompnents/1Ad";
const ReviewsSection = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  return (
    <>

         {/* <FirstAd /> */}
        <div className="reviews-container">
        <h2 className="title-of-section">
          اراء <span>العملاء</span>
        </h2>
        <div className="slide-reviews">
          <Carousel breakPoints={breakPoints}>
            <ReviewCard userName={"Walaa alfahmi"} stars={5} sex={"female"}>
              وصلتني الطلبيه مغلفه تغليف يبرد القلب وصلتني سليمه ونفس الصوره
              واحلى الحمدلله .. الله يوفقهم ونشوف فروعهم في جميع انحاء العالم 😍
            </ReviewCard>
            <ReviewCard userName={"امل الاحمدي"} stars={5} sex={"female"}>
              اجمل متجر وصلت القطعه اجمل مماكنت اتصور شكرا لكم❤️❤️
            </ReviewCard>
            <ReviewCard userName={"عز الدين احمد"} stars={5} sex={"male"}>
              للامانه افضل متجر اشكركم على التغليف وعلى سرعة التوصيل وصصلني اجمل
              من الصوره
            </ReviewCard>
            <ReviewCard userName={"بسمة عسيري"} stars={5} sex={"female"}>
              جدا جميل وقطعهم مميزه
            </ReviewCard>
            <ReviewCard userName={"محمد العبدالله"} stars={5} sex={"male"}>
              سهل التسوق والاستجابه ومتجر حقيقي يستحق الجدارة
            </ReviewCard>
            <ReviewCard userName={"Maram saab"} stars={4} sex={"female"}>
              صراحه الحجم جدًا صدمه ومبالغ بالسعر انا توقعته اكبر على هالسعر بس
              جودتها جميلة ولونها مره حلو
            </ReviewCard>
            <ReviewCard userName={"Fehaid Alfehaid"} stars={5} sex={"male"}>
              المنتج ممتاز وجودته عاليه شكراً لكم
            </ReviewCard>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default ReviewsSection;
