import React from "react";
import { Row, Spinner } from "react-bootstrap";
import UserAllOrdersItem from "./UserAllOrdersItem";
import UserGetAllOrdersHook from "../../hook/user/user-get-all-orders-hook";
import Pagination from "../Utility/Pagination";
import SecondAd from "../AdsCompnents/2Ad";
import AdCom from "../AdsCompnents/AdCom";
const UserAllOrders = () => {
  const [userName, orders, result, pagination, onPress, loading] =
    UserGetAllOrdersHook();
  return (
    <div style={{boxShadow:'0 0 9px rgba(0,0,0,0.1)'}}>
      <div className="admin-content-text pb-4 pt-4" >
        مرحبا <b style={{color:"var(--salmon-green)"}}>{userName}</b> <br />{" "}
        {!result ? "لا يوجد لديك طلبات حالياً" : "هذه هي طلباتك "}
        <br />
        {loading ? "" : !result ? "" : ` عدد الطلبات هو #${result}`}
      </div>
      <Row className="justify-content-between" style={{padding:"35px"}}>
        {loading ? (
          <Spinner animation="border" />
        ) : orders && orders.length ? (
          orders.map((item, index) => {
            return <UserAllOrdersItem key={index} item={item} />;
          })
        ) : (
          <h5>لا يوجد طلبات</h5>
        )}
        {orders.length > 6 ? (
          <Pagination
            pageCount={pagination.numberOfPages ? pagination.numberOfPages : 0}
            onPress={onPress}
          />
        ) : (
          ""
        )}
      </Row>
      <AdCom slot="6876928171" />
    </div>
  );
};

export default UserAllOrders;
