import { GET_ALL_REVIEWS, CREATE_REVIEW ,DELETE_REVIEW,UPDATE_REVIEW_ON_PRODUCT} from "../type";

  
  const inital = {
    createReview: [],
    getAllReviews: [],
    deleteReview:[],
    updateReviewOnProduct:[],
    loading: true,
  };
  const reviewReducer = (state = inital, action) => {
    switch (action.type) {
      case CREATE_REVIEW:
        return {
          ...state,
          createReview: action.payload,
          loading: false,
        };
      case GET_ALL_REVIEWS:
        return {
          ...state,
          getAllReviews: action.payload,
          loading: false,
        };
      // eslint-disable-next-line no-duplicate-case
      case DELETE_REVIEW:
        return {
          ...state,
          deleteReview: action.payload,
          loading: false,
        };
      case UPDATE_REVIEW_ON_PRODUCT:
        return {
          ...state,
          updateReviewOnProduct: action.payload,
          loading: false,
        };

      default:
        return state;
    }
  };
  export default reviewReducer;
  