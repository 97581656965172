import React from "react";
const SubTitle = ({ title, btntitle, pathText, mgt }) => {
  return (
    <div
      className="d-flex justify-content-between pt-4"
      style={{ marginBottom: mgt ? mgt : "" }}
    >
      <div className="sub-tile">{title}</div>
      {btntitle ? (
        <button className="btn-more">
          <a href={`${pathText}`}>{btntitle}</a>
        </button>
      ) : null}
    </div>
  );
};

export default SubTitle;
