import {
  CHANGE_ORDER_MESSAGE,
  CHANGE_ORDER_UNDELIVER,
  CHANGE_ORDER_DELIVER,
  CHANGE_ORDER_UNPAY,
  GET_ALL_ORDERS,
  GET_ONE_ORDER,
  CHANGE_ORDER_PAY,
} from "../type";

const inital = {
  brand: [],
  getAllOrders: [],
  getOneOrder: [],
  changeOrderPay: [],
  changeOrderUnPay: [],
  changeOrderDeliver: [],
  changeOrderUnDeliver: [],
  changeOrderMessage: [],
  loading: true,
};
const orderReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS:
      return {
        ...state,
        getAllOrders: action.payload,
        loading: false,
      };
    case GET_ONE_ORDER:
      return {
        ...state,
        getOneOrder: action.payload,
        loading: false,
      };
    case CHANGE_ORDER_PAY:
      return {
        ...state,
        changeOrderPay: action.payload,
        loading: false,
      };
    case CHANGE_ORDER_UNPAY:
      return {
        ...state,
        changeOrderUnPay: action.payload,
        loading: false,
      };
    case CHANGE_ORDER_MESSAGE:
      return {
        ...state,
        changeOrderMessage: action.payload,
        loading: false,
      };
    case CHANGE_ORDER_DELIVER:
      return {
        ...state,
        changeOrderDeliver: action.payload,
        loading: false,
      };
    case CHANGE_ORDER_UNDELIVER:
      return {
        ...state,
        changeOrderUnDeliver: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default orderReducer;
