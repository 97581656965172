import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ResetPasswordHook from "../../hook/auth/reset-password-hook";

const ResetPasswordPage = () => {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.replace("/user/profile");
    }
  }, []);

  const [  password,confirmPassword,onChangePassword,onChangeConfirmPassword,
    loading,
    onSubmit,isPress] = ResetPasswordHook();

  return (
    <Container className="min-height-e">
      <Row className="py-5 d-flex justify-content-center ">
        <Col sm="12" className="d-flex flex-column " dir="ltr">
          <label className="mx-auto title-login">ادخل كلمة السر الجديدة</label>
          <input
            placeholder="كلمة السر"
            type="password"
            value={password}
            onChange={onChangePassword}
            className="user-input my-3 text-center mx-auto"
          />
          <input
            placeholder="تاكيد كلمة السر"
            type="password"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            className="user-input my-3 text-center mx-auto"
          />
         
          <button className="btn-login mx-auto mt-3" onClick={onSubmit}>
            {isPress === true ? (
              loading === true ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "تغيير كلمة السر"
              )
            ) : (
                "تغيير كلمة السر"            )}
          </button>
   
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default ResetPasswordPage;
