import { Col, Spinner } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserAddressCard from "./UserAddressCard";
import ViewUserAddressesHook from "../../hook/user/view-user-addresses-hook";

const UserAllAddress = () => {

  const [items,loading] = ViewUserAddressesHook()
  return (
    <div>
      <Row className="">
        <div className="admin-content-text">دفتر العنوانين</div>
        {loading === true ? (
          <Spinner animation="border" variant="dark" />
        ) : (
          items && items.data && items.data.length > 0 ? (
            items.data.slice().reverse().map((item, index) => (
              <UserAddressCard item={item} key={index} />
            ))
          ) : (
            <p className="text-center mt-4">لا توجد عناوين متاحة</p>
          )
        )}
        <Row className="justify-content-center">
          <Col sm="5" className="d-flex justify-content-center">
            <Link to="/user/add-address" style={{ textDecoration: "none" }}>
              <button className="btn-add-address">اضافه عنوان جديد</button>
            </Link>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default UserAllAddress