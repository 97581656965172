import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneProduct } from "../../redux/actions/productsAction";
import { getOneCategory } from "../../redux/actions/categoryAction";
import { getOneBrand } from "../../redux/actions/brandAction";
import {
  getAllSubCategory,
  getOneCategoryS,
  getOneSubCategory,
} from "../../redux/actions/subCategoryAction";
import { useChange } from "../../context/ChangeContext";

const ViewProductDetailsHook = (id) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState([]);
  const done = useRef(false);
  const [loading,setLoading] = useState(true)
  const { change, updateChange } = useChange();
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setLoading(true)
        const response = await dispatch(getOneProduct(id));
        const product = response && response.data ? response.data : [];
        setItem(product);
        // باقي الكود لا يتغير
        setLoading(false)
      }
    };
    fetchData();
  }, [id, change]);

  // const oneProduct = useSelector((state) => state.allProducts.oneProduct);

  // if (done.current) {
  //   if (oneProduct) {
  //     if (oneProduct.data) {
  //       item = oneProduct.data;
  //     } else {
  //       item = [];
  //     }
  //   }
  // }
  useEffect(() => {
    if (item.category) {
      dispatch(getOneCategory(item.category));
    }
  }, [item.category]);
  useEffect(() => {
    if (item.subcategory || item.category) {
      let res = dispatch(getOneCategoryS(item.category));

      // res.length > 0 && res.map((i)=>{
      // })
    }
  }, [item]);
  useEffect(() => {
    if (item.brand) {
      dispatch(getOneBrand(item.brand));
    }
  }, [item.brand]);

  const oneCategory = useSelector((state) => state.allCategory.oneCategory);
  // const oneSubCategory = useSelector((state) => state.allCategory.oneCategory);
  const oneBrand = useSelector((state) => state.allBrand.oneBrand);
  const subcategory = useSelector((state) => state.allSubCategory.subcategory);
  // const allSubCategory = item.subcategory ? item.subcategory : [];

  // Filteration Sub Categories Tags
  const filteration = () => {
    if (subcategory && subcategory.data && item.category) {
      let filterationSubCategories = [];

      item.subcategory.forEach((subID) => {
        const matchingCategories = subcategory.data.filter(
          (item) => item._id == subID
        );
        filterationSubCategories =
          filterationSubCategories.concat(matchingCategories);
      });

      if (filterationSubCategories.length > 0) {
        return filterationSubCategories.map((li, index) => (
          <span className="subcat-product" key={index}>
            <a
              href={`/products?category=${item.category}&subcategory=${li._id}`}
            >
              {li.name}
            </a>
          </span>
        ));
      }
    }
  };

  // Show images gallery
  let images = [];

  useEffect(() => {
    if (item.images) {
      images = item.images.map((img) => ({ src: img, alt: "" }));
    } else {
      images = [{}];
    }
  }, [item]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  //   To show single category

  let cat = [];
  if (oneCategory?.data) {
    cat = oneCategory.data;
  } else {
    cat = [];
  }
  let brand = [];
  if (oneBrand?.data) {
    brand = oneBrand.data;
  } else {
    brand = [];
  }

  return [
    typeof item === "object" && item ? item : "",
    goToNextImage,
    goToPreviousImage,
    images,
    currentImageIndex,
    cat,
    brand,
    filteration,
    loading,
  ];
};

export default ViewProductDetailsHook;
