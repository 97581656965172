import React, { useEffect, useState } from "react";
import CategoryContainer from "../../Components/Category/CategoryContainer";
import Pagination from "../../Components/Utility/Pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCategory,
  getAllCategoryPage,
} from "../../redux/actions/categoryAction";
import { Spinner } from "react-bootstrap";
import AllCategoryHook from "../../hook/category/all-category-page-hook";
import SecondAd from "../../Components/AdsCompnents/2Ad";
import AdCom from "../../Components/AdsCompnents/AdCom";
const AllCategoryPage = () => {
  const [categories,loading,pageCount,getPage] = AllCategoryHook()
  return (
    <div className="min-height-e">
      {loading ? (
        <div className="text-center" style={{ marginTop: "50px" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">يتم التحميل...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <CategoryContainer data={categories.data} loading={loading} />
          {pageCount > 1 ? (
            <Pagination pageCount={pageCount} onPress={getPage} />
          ) : null}
        </>
      )}
      <AdCom slot={'7372516538'}/>
    </div>
  );
};

export default AllCategoryPage;
