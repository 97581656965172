import React from "react";
import "./else.css";
import SecondAd from "../../Components/AdsCompnents/2Ad";
import AdCom from "../../Components/AdsCompnents/AdCom";
const ReturnPolicy = () => {
  return (
    <div className="container-else">
      <h2>سياسة الاستبدال و الإسترجاع</h2>
      <p>
        سياسة الاسترجاع :
        <br />
        إذا رغبت بإرجاع طلبك مقابل استرداد المبلغ المدفوع فإنه لديك مهلة 12 ساعة
        من تاريخ الطلب علما بأن عند الرغبة بإسترجاع المنتج على العميل تحويل قيمة
        الشحن على حساب شركة كيوي و يتحمل العميل طباعة بوصليه الشحن وذهاب
        لتسليمها لاقرب مكتب شحن و حيث يتم إرجاع تكلفة المنتج للعميل خلال 14 يوم
        عمل بعد استلام المرتجع .
        <br />
        تتطلب عملية الإرجاع :
        <br />
        1- أن يكون المنتج بصورته الأصلية بالكراتين ومغلف بإحكام .
        <br />
        2- ان يكون المنتج سليماً لم يطرأ عليه أي تغيير .
        <br />
        3- أي مواد تم إعطاؤها مع المنتجات المشتراه مجاناً يجب إرجاعها أيضا .
        <br />
        4- عدم تركيب المنتج عند رغبة العميل بالاسترجاع
        <br />
        <ul>
          <li>
            يتوجب عليك التعامل بحرص مع أي منتجات ترغب بإرجاعها وبالإضافة إلى عدم
            استخدامها بغرض يتعدى التجربة. لا نتحمل مسؤولية أي خسائر أو أضرار تقع
            للمنتجات أثناء تواجدها بحوزتك ويمكننا في هذه الحالة رفض طلبك للإرجاع
            .
          </li>
        </ul>
        <br />
        <br />
        <br />
        سياسة الاستبدال :
        <br />
        1- يمكن الاستبدال عند وصول المنتج متضرر .
        <br />
        2- في حال تأخر وصول المنتجات عن 30 يوم بإمكان العميل الغاء الطلب
        واسترجاع المبلغ المدفوع خلال 14 يوم عمل
        <br />
        3- لا يقبل بأي حال من الأحوال استرجاع أو استبدال المنتجات التي تم
        تصنيعها بطلب من العميل الا في الحالات التالية :
        <br />
        <ul>
          <li> وجود عيب في جودة صنع المنتج أو البضاعة .</li>
          <li> كان المنتج مخالفا للمواصفات المطلوبة او مخالفا للمقاس .</li>
        </ul>
        <br />
        ويجب أن يتم ذلك خلال 24 ساعه من تاريخ استلامه للبضاعة، ونحن غير مسؤولين
        عن أي تلف او ضرر بعد هذه المدة و بشرط أن يكون المنتج سليما لم يطرأ إليه
        أي تغيير،
        <br />
        *ملاحظة مهمة :*
        <br />
        درجة الألوان قد تظهر بشكل مختلف وهذا يرجع إلى حقيقة أن كل شاشة جهاز ذكي
        لديه اقدرة مختلفة على عرض الألوان ومع ذلك، نحن نبذل قصارى جهدنا للتأكد
        من أن صورنا على المتجر مطابقة لألوان المنتج الحقيقي قدر الإمكان. ولا
        يشمل الاستبدال و الاسترجاع المنتجات التي فيها اختلاف طفيف بدرجة اللون.
        <br />
        علما أن المنتجات سيتم عرضها بأقرب دقة ممكنة مع الأخذ بالاعتبار أن
        الألوان التي قد تراها تعتمد على نوع شاشة العرض لديك، لا نستطيع ضمان أن
        شاشة العرض الخاصة بك سوف تعرض ألوان المنتج بشكل دقيق
        <br />
        <br />
        <b>* قسم الطلب المسبق لا يحق للعميل بعد تأكيد الطلب الغاء الطلب</b>
        <br />
        <br />
        <ul>
          <li>
            في حال رفض العميل استلام الطلب من شركة الشحن لأي سبب ما ضمن المدة
            المتفق عليها وتم ارجاعها لمستودعات إتيكيت هوم الرئيسية بينبع البحر
            يتم اخلاء إتيكيت هوم من المسؤولية المترتبة على الشحن وسلامة المنتج
            من الاضرار التي قد تنتج من اعادة التحميل والتنزيل عدة مرات كما يتحمل
            العميل تكلفة اعادة الشحن او يتم استلامها من قبله بالمستودع الواقع ب
            بمدينة ينبع البحر وكما يتحمل تكلفة التخزين بواقع 3 ريال يوميا لكل
            كرتون حتى يتم شحن او استلام المنتج من قبله.
          </li>
        </ul>
      </p>
      <AdCom slot={'7372516538'} />
    </div>
  );
};

export default ReturnPolicy;
