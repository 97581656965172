import React, { useEffect, useState } from 'react'
import notify from '../useNotifaction'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCoupon, editCoupon, getOneCoupon } from '../../redux/actions/couponAction'
import { useChange } from '../../context/ChangeContext'
import { useChangeCart } from '../../context/ChangeCart'

const CouponCardHook = (item) => {
    const dateString = item.expire
    const dispatch = useDispatch()
    const [loading,setLoading]= useState('')
    const [loadingE,setLoadingE]= useState('')
    const {changeCart,updateChangeCart} = useChangeCart()
    const formatDate = (dateString)=>{
        const options = {year:'numeric',month:'long',day:'numeric'}
        return new Date(dateString).toLocaleDateString(undefined,options)
    }
    let dateOfCoupon = formatDate(dateString)

    const [show,setShow] = useState(false)
    const handleClose = ()=> setShow(false)
    const handleShow = ()=>setShow(true)
    const handleDelete = async () => {
        setLoading(true);
        try {
            await dispatch(deleteCoupon(item._id));
            
        } catch (error) {
            throw error
        } finally {
            setLoading(false);
            handleClose()
        }
    };

    const resDelete = useSelector(state=>state.couponReducer.deleteCoupon)
    useEffect(()=>{
        if(loading === false){
                  notify('تم الحذف بنجاح','success')
                  updateChangeCart(Math.random(100 * 500))

        }
    },[loading])

    const [showEdit,setShowEdit] = useState(false)
    const handleCloseEdit = ()=> setShowEdit(false)
    const handleShowEdit = ()=>setShowEdit(true)
    const dateStringa = item.expire

 const formatDatea = (dateStringa)=>{
        const options = {year:'numeric',month:'long',day:'numeric'}
        return new Date(dateStringa).toLocaleDateString(undefined,options)
    }
    const [couponName, setCouponName] = useState(item.name);
    const [couponDate, setCouponDate] = useState(formatDatea(dateStringa));
    const [couponDiscount, setCouponDiscount] = useState(item.discount);
     
    const onChangeCouponDiscount = (e) => {
        e.persist()
        setCouponDiscount(e.target.value)
      }
      const onChangeCouponName = (e) => {
            e.persist()
    
            setCouponName(e.target.value)
      }
      const onChangeCouponDate = (e) => {
          e.persist()
          setCouponDate(e.target.value)
      }

    const handleEdit = async () => {
        setLoading(true);
        try {
            await dispatch(editCoupon(item._id,{
                name:couponName,
                expire:couponDate,
                discount:couponDiscount,
            }));
      
        } catch (error) {
          throw error
        } finally {
            setLoading(false);
            handleClose()
        }
    };
    
    const resEdit = useSelector(state=>state.couponReducer.editCoupon)

    useEffect(()=>{
        if(loadingE === false){
            if(resEdit && resEdit.status  === 200){

                notify('تم التعديل بنجاح','success')
            }else {
                notify('فشل عملية التعديل','error')

            }

        }
    },[loadingE])

    return [show,
        handleClose,
        handleDelete,
        handleShow,
        dateOfCoupon,handleCloseEdit,showEdit,handleEdit,handleShowEdit,
        couponName,onChangeCouponName,couponDate,onChangeCouponDate,
        couponDiscount,onChangeCouponDiscount]
}

export default CouponCardHook