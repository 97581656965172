import {
  ADD_PRODUCT_TO_WISHLIST,
  REMOVE_PRODUCT_FROM_WISHLIST,
  USER_WISHLIST,
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import useDeleteData from "./../../hooks/useDeleteData";
import { useGetDataToken } from "../../hooks/useGetData";

export const addProductToWishList = (body) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/wishlist", body);

    dispatch({
      type: ADD_PRODUCT_TO_WISHLIST,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: ADD_PRODUCT_TO_WISHLIST,
      payload: e.response,
    });
  }
};
export const removeProductToWishList = (prodID) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/wishlist/${prodID}`);

    dispatch({
      type: REMOVE_PRODUCT_FROM_WISHLIST,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: REMOVE_PRODUCT_FROM_WISHLIST,
      payload: e.response,
    });
  }
};

export const getProductWishList = () => async (dispatch) => {
  try {
    if (localStorage.getItem("token")) {
      const response = await useGetDataToken(`/api/v1/wishlist`);

      dispatch({
        type: USER_WISHLIST,
        payload: response,
        loading: false, // تحديد الحالة على false بعد الإرسال الناجح
      });
      return response;
    }
  } catch (e) {
    dispatch({
      type: USER_WISHLIST,
      payload: e.response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  }
};
