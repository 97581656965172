import React, { useEffect } from "react";
import { Col, Container, Row,Spinner } from "react-bootstrap";
import CategoryCard from "../Category/CategoryCard";
import cloth from "../../images/clothe.png";
import { getAllCategory } from "../../redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
const CategoryContainer = ({data,loading}) => {
 
  const colors = [
    "#ffd3e8",
    "#f4d8a5",
    "#55cfdf",
    "#ffd3e8",
    "#f4d8a5",
    "#ffd3e8",
  ];

  return (
    <Container>
      <div className="admin-content-text mt-2">كل التصنيفات</div>
      <Row className="my-2 d-flex justify-content-start">

      {loading ? (
          <div
            className="text-center"
            style={{ margin: "auto", marginTop: "50px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">يتم التحميل...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {data ? (
              data.map((item, index) => (
                <CategoryCard
                  id={item._id}
                  name={item.name}
                  img={item.image}
                  key={item._id}
                  home={true}
                  lg={"3"}
                  md={"4"}
                  sm={"6"}
                  xs={"12"}
                  />
              ))
            ) : (
              <p>لا يوجد تصنيفات</p>
            )}
          </>
        )}      </Row>
    </Container>
  );
};

export default CategoryContainer;
