import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import rate from "../../images/rate.png";
import PostRate from "./PostRate";
import RateItem from "./RateItem";
import Pagination from "../Utility/Pagination";
import { useParams } from "react-router-dom";
import ViewAllReviewHook from "../../hook/review/view-all-review-hook";
import { ToastContainer } from "react-toastify";
const RateContainer = ({ rateAvg, rateQty }) => {
  const { id } = useParams();

  const [loading, reviews, onPress, limit] = ViewAllReviewHook(id);

  return (
    <Container className="rate-container" id={"rate-container"}>
      <Row>
        <Col className="d-flex">
          <div className="sub-tile d-inline p-1 ">التقييمات</div>
          <img className="mt-2" src={rate} alt="" height="16px" width="16px" />

          <div
            className="cat-rate  d-inline  p-1 pt-2"
            style={{ fontSize: "14px", color: "orange" }}
          >
            {rateAvg}
          </div>
          <div className="rate-count d-inline p-1 pt-2">
            (
            {loading === true ? (
              <Spinner animation="border" variant="light" />
            ) : (
              `${reviews.data ? reviews.data.length : "0"} تقييم `
            )}{" "}
            )
          </div>
        </Col>
      </Row>

      <PostRate />
      {reviews.data && reviews.data.length === 0 ? (
        <h5 style={{ textAlign: "center", padding: "20px 0" }}>
          لا يوجد تقييمات
        </h5>
      ) : (
        ""
      )}

      {loading === true ? (
        <Spinner animation="border" variant="light" />
      ) : (
        reviews.data &&
        reviews.data.map((item, index) => {
          return <RateItem key={index} item={item} />;
        })
      )}
      {reviews.paginationResult &&
      reviews.paginationResult.numberOfPages > limit ? (
        <Pagination
          onPress={onPress}
          pageCount={
            reviews.paginationResult
              ? reviews.paginationResult.numberOfPages
              : 0
          }
        />
      ) : (
        ""
      )}
      <ToastContainer />
    </Container>
  );
};

export default RateContainer;
