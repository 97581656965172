import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
const Slider = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      dir="ltr"
      activeIndex={index}
      onSelect={handleSelect}
      style={{ marginTop: "-12px !important", maxHeight: "650px" }}
    >
      <Carousel.Item
        className="slider-background2 slider-bg"
        interval={20000}
      ></Carousel.Item>
      <Carousel.Item className="slider-background4 slider-bg" interval={20000}>
        <div className="d-flex flex-row justify-content-center align-items-center slider-cont">
          {/* <div>
            <h3 className="slider-title">
              تسوقوا واحصلوا على افضل انواع الالبسة
            </h3>
            <Link to="products">تصفح المتجر</Link>
          </div> */}
        </div>
      </Carousel.Item>
      <Carousel.Item className="slider-background3 slider-bg" interval={20000}>
        <div className="d-flex flex-row justify-content-center align-items-center slider-cont">
            {/* <div>
              <h3 className="slider-title">
              Shop and get best clothes for yours
              </h3>

              <Link to="products">Shop now</Link>
            </div> */}
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
