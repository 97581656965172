import {
  GET_ALL_ORDERS,
  GET_ONE_ORDER,
  CHANGE_ORDER_PAY,
  CHANGE_ORDER_UNPAY,
  CHANGE_ORDER_DELIVER,
  CHANGE_ORDER_UNDELIVER,
  CHANGE_ORDER_MESSAGE,
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useUpdateDataXX } from "../../hooks/useUpdateDataxx";
import qs from "qs";

export const getAllOrders = (limit, page) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/orders?limit=${limit}&page=${page}`
    );
    dispatch({
      type: GET_ALL_ORDERS,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_ORDERS,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};

export const getOneOrder = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/orders/${id}`);
    dispatch({
      type: GET_ONE_ORDER,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
    return response;
  } catch (e) {
    dispatch({
      type: GET_ONE_ORDER,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};

export const changeOrderPay = (id) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/orders/${id}/pay`);
    dispatch({
      type: CHANGE_ORDER_PAY,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: CHANGE_ORDER_PAY,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};

export const changeOrderUnPay = (id) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/orders/${id}/unpay`);
    dispatch({
      type: CHANGE_ORDER_UNPAY,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: CHANGE_ORDER_UNPAY,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};

export const changeOrderDeliver = (id) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/orders/${id}/deliver`);

    dispatch({
      type: CHANGE_ORDER_DELIVER,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: CHANGE_ORDER_UNPAY,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};

export const changeOrderUnDeliver = (id) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/orders/${id}/undeliver`);

    dispatch({
      type: CHANGE_ORDER_UNDELIVER,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
  } catch (e) {
    dispatch({
      type: CHANGE_ORDER_UNDELIVER,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};

export const changeOrderMessage = (id, message) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/v1/orders/${id}/message`,
      message
    );

    dispatch({
      type: CHANGE_ORDER_MESSAGE,
      payload: response,
      loading: false, // تحديد الحالة على false بعد الإرسال الناجح
    });
    return response;
  } catch (e) {
    dispatch({
      type: CHANGE_ORDER_MESSAGE,
      payload: "حدث خطأ أثناء إرسال البيانات: " + e,
    });
  }
};
