import { Box, CircularProgress, LinearProgress } from "@mui/material";
import React from "react";
import AdminUserCard from "./AdminUserCard";
import { Spinner } from "react-bootstrap";

const AdminAllUsers = ({ items, loading }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1.5}>
      {loading === true ? (
        <div style={{ margin: "50px auto", width: "70%", textAlign: "center" }}>
          <Spinner animation="border" />
        </div>
      ) : items && items.length > 0 ? (
        items.map((item, index) => <AdminUserCard key={index} item={item} />)
      ) : (
        <p>No users to display.</p>
      )}
    </Box>
  );
};

export default AdminAllUsers;
