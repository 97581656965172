import React from "react";
import ReactPaginate from "react-paginate";
import { usePagination } from "../../context/getPaginationPage";

const Pagination = ({ onPress, pageCount, loading }) => {
  const { currentPage, onPageChange } = usePagination();
  const handlePageClick = (data) => {
    onPress(data.selected + 1);
    onPageChange(data.selected + 1);
  };

  return (
    <div className={`${loading === true ? "hidePage" : ""}`}>
      <ReactPaginate
        breakLabel="..."
        nextLabel="التالى"
        onPageChange={handlePageClick}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel="السابق"
        containerClassName={`pagination pagination-wrap justify-content-center p-3`}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"} // إضافة هذا لتحديد الصفحة الحالية
      />
    </div>
  );
};

export default Pagination;
