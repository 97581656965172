import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getAllProductsPage,
} from "../../redux/actions/productsAction";
import { getAllUsers, getAllUsersPage } from "../../redux/actions/userAction";
import { useDeleteChangeContext } from "../../context/DeleteUserChangeContext";

const ViewUsersAdminHook = () => {
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(true)
  const [pageCurrent,setPageCurrent] = useState(1)
  const {deleteChange,updateDeleteChange } = useDeleteChangeContext()
  let limit = 4;
  useEffect(() => {
      setLoading(true)
        let res =  dispatch(getAllUsers());
      setLoading(false)
  }, []);

  const allUsers = useSelector((state) => state.userReducer.allUsers);
  const onPress = async (page) => {
    setPageCurrent(page)
    setLoading(true)
    await dispatch(getAllUsersPage(limit, page));
    setLoading(false)
  };

  useEffect(()=>{
    onPress(pageCurrent)
  },[deleteChange])
  let items = [];
  let pagination = [];
  try {
    if (allUsers) {
      items = allUsers.data;
    } else items = [];

    if (allUsers) {
      if (allUsers.paginationResult) {
        pagination = allUsers.paginationResult;
      } else pagination = [];
    } else pagination = [];
  } catch (e) {
    throw e;
  }
  return [items, pagination, onPress,loading];
};

export default ViewUsersAdminHook;
