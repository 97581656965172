import { useState ,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  getAllReviews } from '../../redux/actions/reviewAction'
import { useChange } from '../../context/ChangeContext'
const ViewAllReviewHook = (id) => {
    const dispatch = useDispatch()
    const { change, updateChange } = useChange();

    const [loading, setLoading] = useState('')
    const [reviews,setReviews] = useState('')
    let limit = 8
    useEffect(() => {
            setLoading(true);
             dispatch(getAllReviews(id,1,limit));
 
            setLoading(false);
       

      }, [change]);
    const res = useSelector((state) => state.reviewReducer.getAllReviews);

useEffect(() => {
  if (res && res.data) {
    setReviews(res);
  }
}, [res]);

const onPress = async (page)=>{
    await  dispatch(getAllReviews(id,page,limit));

  }
    return [loading,reviews,onPress,limit,]
}

export default ViewAllReviewHook