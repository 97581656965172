import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import AdminSideBar from '../../Components/Admin/AdminSideBar'
import Pagination from '../../Components/Utility/Pagination'
import AdminAllOrderProduct from '../../Components/Admin/AdminAllOrderProduct'
import UserGetAllOrdersHook from '../../hook/user/user-get-all-orders-hook'
import AdminAllOrder from '../../Components/Admin/AdminAllOrder'

const AdminAllProductsPage = () => {
 

  return (
    <Container>
        <Row  className="py-3 ">
            <Col sm="3" xs="2" md="2"> 
            <AdminSideBar/>
            </Col>
            <Col sm="9" xs="10" md="10"> 
            <AdminAllOrder/>
            {/* <Pagination/> */}
            </Col>
        </Row>
        </Container>
  )
}

export default AdminAllProductsPage