import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { getAllCategory } from "../../redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import StarPurple500OutlinedIcon from "@mui/icons-material/StarPurple500Outlined";
import { deleteProducts } from "../../redux/actions/productsAction";

const AdminAllProductsCard = ({ item }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategory());
  }, []);
  const categories = useSelector((state) => state.allCategory.category);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = async () => {
    await dispatch(deleteProducts(item._id));
    setShow(false);
    window.location.reload();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
       
      >
        <Modal.Header>
          <Modal.Title>تاكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل انت متاكد من انك تريد حذف المنتج</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            تراجع
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="showcase">
        <Row className="d-flex justify-content-center px-2 mb-4">
          <Col className=" d-flex justify-content-between">
            <div className="d-inline item-delete-edit" onClick={handleShow}>
              ازاله
            </div>
            <a href={`/admin/editproduct/${item._id}`}>
              <div className="d-inline item-delete-edit">تعديل</div>
            </a>
          </Col>
        </Row>
        <div className="showcase-banner">
          {item.inStock === false ? (
            <p className="showcase-badge-admin angle red">انتهى</p>
          ) : (
            ""
          )}
          {item.lowerStock === true ? (
            <p className="showcase-badge-admin angleTwo orange">بقي القليل</p>
          ) : (
            ""
          )}
          <img
            // src={item.imageCover}
            src={ item.images[0]}
            alt="Pure Garment Dyed Cotton Shirt"
            className="product-img default"
            width="300"
          />
          <img
            // src={item.images.length === 1 ? item.imageCover : item.images[1]}
             src={ item.images[0]}
            alt="Pure Garment Dyed Cotton Shirt"
            className="product-img hover"
            width="300"
          />
        </div>

        <div className="showcase-content">
          <span className="showcase-category">
            {categories && categories.data
              ? categories.data
                  .filter((cat) => cat._id === item.category)
                  .map((filteredCat) => filteredCat.name)
              : null}
          </span>
          {/* <div className={"rating-cont"}>
            <span className="count">{item.ratingsQuantity}</span>
            <StarPurple500OutlinedIcon />
          </div> */}
          <h3>
            <a className="showcase-title" href={`/products/${item._id}`}>
              {" "}
              {item.title}
            </a>
          </h3>

          <div className="showcase-rating">
            <ion-icon
              name="star"
              role="img"
              className="md hydrated"
              aria-label="star"
            ></ion-icon>
            <ion-icon
              name="star"
              role="img"
              className="md hydrated"
              aria-label="star"
            ></ion-icon>
            <ion-icon
              name="star"
              role="img"
              className="md hydrated"
              aria-label="star"
            ></ion-icon>
            <ion-icon
              name="star-outline"
              role="img"
              className="md hydrated"
              aria-label="star outline"
            ></ion-icon>
            <ion-icon
              name="star-outline"
              role="img"
              className="md hydrated"
              aria-label="star outline"
            ></ion-icon>
          </div>

          <div className="price-box">
            <p className="price">{item.price} TL</p>
            <del>{""}</del>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAllProductsCard;
