import { UPDATE_USER, UPDATE_PASSWORD, GET_USERS, DELETE_USER } from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetData, useGetDataToken } from "./../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import useDeleteData from "../../hooks/useDeleteData";
export const updateUser = (id, data) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/users/${id}`, data);
    dispatch({
      type: UPDATE_USER,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_USER,
      payload: e.response,
    });
  }
};
export const updatePassword = (id, data) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/v1/users/change-password/${id}`,
      data
    );
    dispatch({
      type: UPDATE_PASSWORD,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_PASSWORD,
      payload: e.response,
    });
  }
};
export const getAllUsers = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/users?limit=4`);
    dispatch({
      type: GET_USERS,
      payload: response,
      loading: true,
    });
    return response
  } catch (e) {
    dispatch({
      type: GET_USERS,
      payload: e.response,
    });
  }
};
export const getAllUsersPage = (limit, page) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/users?limit=${limit}&page=${page}`
    );
    dispatch({
      type: GET_USERS,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: GET_USERS,
      payload: e.response,
    });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const response = useDeleteData("/api/v1/users/" + id);
    dispatch({
      type: DELETE_USER,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: DELETE_USER,
      payload: e.response,
    });
  }
};
