import React from 'react'
import { Container } from 'react-bootstrap'
import ChoosePayMethoud from '../../Components/Checkout/ChoosePayMethoud'

const ChoosePayMethoudPage = () => {
  return (
    <Container className="min-height-e">
        <ChoosePayMethoud/>
    </Container>
  )
}

export default ChoosePayMethoudPage