import React from "react";
import SubTitle from "../Utility/SubTitle";
import {  Row, Spinner } from "react-bootstrap";
import ProductCard from "./ProductCard";
import CardContainerHook from "../../hook/products/card-container-hook";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NotificationContainer } from "react-notifications";
const CardProductsContainer = ({
  title,
  btntitle,
  pathText,
  products,
  gridfour,
  cat,
  imgUrl,
  loading,
  refProp,
  noCaro,
}) => {
  let newProductsByCategory = [];
  if (cat && products.length > 0 && products) {
    newProductsByCategory = products.filter((item) => item.category === cat);
  } else {
    newProductsByCategory = [];
  }
  const [favProd] = CardContainerHook();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
   return (
    <div>
      {cat && typeof cat === "string" && newProductsByCategory.length <= 0 ? (
        ""
      ) : (
        <div className="container-cat" ref={refProp && refProp}>
          <Row className="my-2 d-flex justify-content-between">
            <SubTitle
              title={title}
              btntitle={btntitle}
              pathText={pathText}
              mgt="25px"
            />

            {noCaro !== false ? (
              <div
                className={`product-grid ${gridfour ? "four-grid" : ""}`}
                style={{ direction: "rtl" }}
              >
                <Carousel responsive={responsive} style={{ direction: "rtl" }}>
                  {loading ? (
                    <Spinner animation="border" variant="dark" />
                  ) : products && products.length > 0 ? (
                    cat ? (
                      newProductsByCategory
                        .slice(0, 7)
                        .map((item, index) => (
                          <ProductCard item={item} key={index} />
                        ))
                    ) : (
                      products.map((item, index) => (
                        <ProductCard
                          imgUrl={imgUrl}
                          favProd={favProd}
                          item={item}
                          key={index}
                        />
                      ))
                    )
                  ) : (
                    <h4 style={{ marginTop: "35px", marginRight: "35px" }}>
                      لا يوجد منتجات
                    </h4>
                  )}
                </Carousel>
              </div>
            ) : loading ? (
              <Spinner animation="border" variant="dark" />
            ) : products && products.length > 0 ? (
              cat ? (
                <div
                  className={`product-grid ${gridfour ? "four-grid" : ""}`}
                  style={{ display: "grid", direction: "rtl" }}
                >
                  {newProductsByCategory.slice(0, 7).map((item, index) => (
                    <ProductCard item={item} key={index} />
                  ))}
                </div>
              ) : (
                <div
                  className={`product-grid ${gridfour ? "four-grid" : ""}`}
                  style={{ display: "grid", direction: "rtl" }}
                >
                  {products.map((item, index) => (
                    <ProductCard
                      imgUrl={imgUrl}
                      favProd={favProd}
                      item={item}
                      key={index}
                    />
                  ))}
                </div>
              )
            ) : (
              <h4 style={{ marginTop: "35px", marginRight: "35px" }}>
                لا يوجد منتجات
              </h4>
            )}
          </Row>
        </div>
      )}
              <NotificationContainer />

    </div>
  );
};

export default CardProductsContainer;
