import {
  GET_ERROR,
  GET_ONE_SUB_CATEGORY,
  CREATE_SUB_CATEGORY,
  GET_ALL_SUB_CATEGORY,
  GET_SUB_CATEGORY,
  GET_ALL_SUB_CATEGORY_NAV,
} from "../type";

const inital = {
  subcategorynav:[],
  subcategory: [],
  oneSubCategory: [],
  loading: true,
};
const subCategoryReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_SUB_CATEGORY:
      return {
        ...state,
        subcategory: action.payload,
        loading: false,
      };
    case GET_ALL_SUB_CATEGORY_NAV:
      return {
        ...state,
        subcategorynav: action.payload,
        loading: false,
      };
    case GET_SUB_CATEGORY:
      return {
        subcategory: action.payload,
        loading: false,
      };
    case CREATE_SUB_CATEGORY:
      return {
        subcategory: action.payload,
        loading: false,
      };
    case GET_ONE_SUB_CATEGORY:
      return {
        ...state,
        oneSubCategory: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        loading: true,
        subcategory: action.payload,
      };
    default:
      return state;
  }
};
export default subCategoryReducer;
