import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToCart,
  getAllCartItems,
} from "../../redux/actions/cartAction";
import notify from "../useNotifaction";
import { useChangeCart } from "../../context/ChangeCart";

const GetAllUserCartHook = () => {
  const dispatch = useDispatch();
  const [loadingCart, setLoadingCart] = useState(true);
  const [itemsNum, setItemsNum] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceAfter, setTotalPriceAfter] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [couponName, setCouponName] = useState("");
  const [isNo,setIsNo] = useState(true)
  const { changeCart, updateChangeCart } = useChangeCart();
  useEffect(() => {
    const fetch = async () => {
      setLoadingCart(true); 
      await dispatch(getAllCartItems());
      setLoadingCart(false);
    };
    fetch();
  }, [changeCart,dispatch]);
  const res = useSelector((state) => state.cartReducer.getAllCartItems);
  const [cartID, setCartID] = useState("0");
  useEffect(() => {
    if (loadingCart === false) {
      if (res && res.status === "success") {
        setItemsNum(res.numOfCartItems);
        setCartItems(res.data.products);
        setTotalPrice(res.data.totalCartPrice);
        setCartID(res.data._id);
        if (res.data || res.data.coupon) {
          setCouponName(res.data.coupon);
        } else {
          setCouponName("");
        }
        if (res.data || res.data.totalAfterDiscount) {
          setTotalPriceAfter(res.data.totalAfterDiscount);
        } else {
          setTotalPriceAfter("");
        }
       
      } else {
        setCartID("0");
        setCouponName("");
        setTotalPriceAfter(0);
        setTotalPrice(0);
        setItemsNum(0);
        setCartItems([]);
      }
    }
  }, [loadingCart, changeCart]);
  // useEffect(() => {
  //   if (loadingCart === false) {
  //     if (res) {
  //       if (res.data) {
  //         setCartID(res.data._id);
  //       } else {
  //         setCartID("0");
  //       }
  //     }
  //   }
  // }, [res]);
 
    return [
      itemsNum,
      cartItems,
      totalPrice,
      couponName,
      totalPriceAfter,
      cartID,
      loadingCart,
    ];
  
};

export default GetAllUserCartHook;
