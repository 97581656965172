import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeOrderDeliver, changeOrderPay, changeOrderUnDeliver, changeOrderUnPay } from '../../redux/actions/orderAction'
import notify from '../useNotifaction'

const ChangeOrderStatusHook = (id) => {
    const dispatch = useDispatch()
    const [deliver,setDeliver] = useState('0')
    const [loadingD,setLoadingD] = useState('')
    const [loadingDUn,setLoadingDUn] = useState('')
    const onChangeDeliver = (e)=>{
        setDeliver(e.target.value)
      }
        
      const changeDeliverOrder = async ()=>{
      
        if(deliver === 'true'){
            setLoadingD(true)
            await dispatch(changeOrderDeliver(id))
            setLoadingD(false)
        }else if(deliver === '0'){
            notify('من فضلك اختر قيمة','error')
        } else if(deliver === 'false'){
            setLoadingDUn(true)
            await dispatch(changeOrderUnDeliver(id))
            setLoadingDUn(false)
        }
         
       
    }
 
    const resD = useSelector(state=>state.orderReducer.changeOrderDeliver)
        useEffect(()=>{
            if(loadingD === false){

                if(resD && resD.status === 200){
                    notify('تم تغيير الحالة بنجاح','success')
                    setTimeout(()=>{
                        window.location.reload()
                    },1500)
                }else {
                    notify('حدث خطا اثناء تغيير الحالة الرجاء المحاولة لاحقاً','error')
                }
            }
            
        },[loadingD])
        const resDUn = useSelector(state=>state.orderReducer.changeOrderUnDeliver)

        useEffect(()=>{
            if(loadingDUn === false){
                if(resD){
                    notify('تم تغيير الحالة بنجاح','success')
                    setTimeout(()=>{
                        window.location.reload()
                    },1500)
                }else {
                    notify('حدث خطا اثناء تغيير الحالة الرجاء المحاولة لاحقاً','error')
                }
            }
        },[loadingDUn,resDUn])
    const [pay,setPay] = useState('0')
    const [loading,setLoading] = useState('')
    const [loadingUn,setLoadingUn] = useState('')
    const onChangePaid = (e)=>{
        setPay(e.target.value)
      }
        
      const changePayOrder = async ()=>{
      
        if(pay === 'true'){
            setLoading(true)
            await dispatch(changeOrderPay(id))
            setLoading(false)
        }else if(pay === '0'){
            notify('من فضلك اختر قيمة','error')
        } else if(pay === 'false'){
            setLoadingUn(true)
            await dispatch(changeOrderUnPay(id))
            setLoadingUn(false)
        }
         
       
    }
 
    const res = useSelector(state=>state.orderReducer.changeOrderPay)
        useEffect(()=>{
            if(loading === false){

                if(res && res.status === 200){
                    notify('تم تغيير الحالة بنجاح','success')
                    setTimeout(()=>{
                        window.location.reload()
                    },1500)
                }else {
                    notify('حدث خطا اثناء تغيير الحالة الرجاء المحاولة لاحقاً','error')
                }
            }
            
        },[loading])
        const resUn = useSelector(state=>state.orderReducer.changeOrderUnPay)

        useEffect(()=>{
            if(loadingUn === false){
                if(resUn && resUn.status === 200){
                    notify('تم تغيير الحالة بنجاح','success')
                    setTimeout(()=>{
                        window.location.reload()
                    },1500)
                }else {
                    notify('حدث خطا اثناء تغيير الحالة الرجاء المحاولة لاحقاً','error')
                }
            }
        },[loadingUn])

 

  return [onChangePaid,pay,changePayOrder,onChangeDeliver,deliver,changeDeliverOrder]
}

export default ChangeOrderStatusHook