import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCoupon, getCoupons } from '../../redux/actions/couponAction'
import notify from '../useNotifaction'
import { useChange } from '../../context/ChangeContext'
import { useChangeCart } from '../../context/ChangeCart'

const AddCouponHook = () => {
  const dispatch = useDispatch()
  const [loading,setLoading] = useState(false)
  const [couponName,setCouponName] = useState('')
  const [couponDate,setCouponDate] = useState('')
  const [couponDiscount,setCouponDiscount] = useState(0)

  const onChangeCouponDiscount = (e) => {
    e.persist()
    setCouponDiscount(e.target.value)
  }
  const onChangeCouponName = (e) => {
        e.persist()

        setCouponName(e.target.value)
  }
  const onChangeCouponDate = (e) => {
      e.persist()
      setCouponDate(e.target.value)
  }
  const handelSubmit = async ()=>{
    if(couponName === '' && couponDate === '' && couponDiscount === 0){
      notify('الرجاء اكمال ادخال البيانات','warn')
      return;
    }
    setLoading(true)
    await dispatch(addCoupon({
      name:couponName,
      expire:couponDate,
      discount:couponDiscount,
    }))
    setLoading(false)
  }

  const res = useSelector(state=>state.couponReducer.addCoupon)
  const {changeCart,updateChangeCart} = useChangeCart()

  useEffect(()=>{
    if(loading === false){
      if(res && res.status === 201){
        notify('تمت اضافة الكوبون بنجاح','success')
        setCouponDate('')
        setCouponDiscount(0)
        setCouponName('')
        updateChangeCart(Math.random(100 * 500))
      }else if (res && res.status === 400){
        notify('هذا الكوبون مستخدم من قبل','error')
      }
      else if (res && res.status === 403){
        notify('انت غير مسموح لك باضافة كوبون','error')
      }
    }
  },[loading])
  const [items, setItems] = useState([])
  useEffect(()=>{
    const get = async ()=>{
      await dispatch(getCoupons())
    }
    get()
  },[changeCart, dispatch])
  const resItems = useSelector(state=>state.couponReducer.getCoupons)

  useEffect(()=>{
      if(resItems && resItems.data){
        setItems(resItems)
      }
  },[resItems,changeCart])

  return [  
    couponDiscount,
    onChangeCouponDiscount,
    couponName,
    onChangeCouponName,
    couponDate,
    onChangeCouponDate,
    handelSubmit,
    loading,
    items,
  ]
}

export default AddCouponHook