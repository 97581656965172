import {
    ADD_COUPON ,GET_COUPONS ,DELETE_COUPON,EDIT_COUPON,GET_ONE_COUPON} from "../type";
  
  const inital = {
    addCoupon:[],
    getCoupons:[],
    deleteCoupon:[],
    editCoupon:[],
    getOneCoupon:[],
    loading: true,
  };
  const couponReducer = (state = inital, action) => {
    switch (action.type) {
       case ADD_COUPON:
         return {
           ...state,
           addCoupon: action.payload,
           loading: false,
         };
       case GET_COUPONS:
         return {
           ...state,
           getCoupons: action.payload,
           loading: false,
         };
       case DELETE_COUPON:
         return {
           ...state,
           deleteCoupon: action.payload,
           loading: false,
         };
       case EDIT_COUPON:
         return {
           ...state,
           editCoupon: action.payload,
           loading: false,
         };
       case GET_ONE_COUPON:
         return {
           ...state,
           getOneCoupon: action.payload,
           loading: false,
         };

      default:
        return state;
    }
  };
  export default couponReducer;
  