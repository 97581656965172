import axios from "axios";

const baseURL = axios.create({
  baseURL: "https://www.kiendback.tech",
  cache: {
    maxAge: 15 * 60 * 1000, // 15 minutes
    forceUpdate: true
  },
});

export default baseURL;
