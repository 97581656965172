/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import "./ProductCard.css";
import { useDispatch, useSelector } from "react-redux";
import { getOneCategory } from "../../redux/actions/categoryAction";
import { StarFilled } from "@ant-design/icons";
import {
  addProductToWishList,
  removeProductToWishList,
} from "../../redux/actions/wishlistAction";
import notify from "./../../hook/useNotifaction";
import { ToastContainer } from "react-toastify";
import url from "../../Api/url";
import { useChange } from "../../context/ChangeContext";
import AddToCartHook from "../../hook/cart/add-to-cart-hook";
import Skeleton from "react-loading-skeleton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import { Eye, Heart, Warning } from "phosphor-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useChangeWishlist } from "../../context/ChangeWishlist";
import { Link } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LazyImage from "./LazyImage";
const ProductCard = ({ item, favProd }) => {
  let imgUrl;

  let prod = item
    ? item
    : {
        _id: "",
        price: "",
        inStock: "",
        imageCover: "",
        images: "",
        title: "",
      };
  const [
    handleAddToCart,
    colorClick,
    indexColor,
    colorText,
    loadingCart,
    indexSize,
    sizeClick,
    sizeText,
  ] = AddToCartHook(item._id, item);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingRemove, setLoadingRemove] = useState(true);
  const notifyError = (msg) => {
    NotificationManager.error(msg);
  };
  const notifySucess = (msg) => {
    NotificationManager.success(msg);
  };
  const resAdd = useSelector(
    (state) => state.wishlistReducer.addProductToWishList
  );
  const resRemove = useSelector(
    (state) => state.wishlistReducer.deleteProductFromWishList
  );
  const [isFav, setIsFav] = useState(false);
  useEffect(() => {
    if (favProd && favProd.length) {
      setIsFav(favProd.some((fitem) => fitem === item._id));
    }
  }, [favProd]);
  const handleFav = () => {
    if (localStorage.getItem("token")) {
      if (isFav) {
        removeFromWishList(item._id);
        setIsFav(false);
      } else {
        addToWishList(item._id);
        setIsFav(true);
      }
    } else {
      notifyError("يجب تسجيل الدخول اولا");
    }
  };

  const addToWishList = async (id) => {
    setLoading(true);
    setIsFav(true);
    await dispatch(
      addProductToWishList({
        productId: id,
      })
    );
    updateChangeWishlist(Math.random(100 * 500));

    setLoading(false);
  };
  const { changeWishlist, updateChangeWishlist } = useChangeWishlist();

  const removeFromWishList = async () => {
    setLoadingRemove(true);
    setIsFav(false);
    await dispatch(removeProductToWishList(item._id));
    updateChangeWishlist(Math.random(100 * 500));
    setLoadingRemove(false);
  };

  useEffect(() => {
    if (favProd && favProd.length) {
      if (favProd.some((fitem) => fitem === item._id) === true) {
        setIsFav(true);
      } else {
        setIsFav(false);
      }
    }
  }, [
    favProd && favProd.length && favProd.some((fitem) => fitem === item._id),
  ]);

  const [hasNotified, setHasNotified] = useState(false);

  useEffect(() => {
    if (loading === false && !hasNotified) {
      if (resAdd && resAdd.status === 200) {
        notifySucess("تمت اضافة العنصر الى قائمة المفضلات");

        updateChangeWishlist(Math.random(200 * 500));
        setHasNotified(false); 
      } else if (resAdd && resAdd.status === 401) {
        notifyError("يجب تسجيل الدخول اولا");
        setHasNotified(false); // تعيين القيمة إلى true بمجرد عرض الإشعار
      }
    }
  }, [loading, resAdd, hasNotified]);

  useEffect(() => {
    if (loadingRemove === false) {
      if (resRemove && resRemove.status === "success") {
        notifySucess("تمت ازالة العنصر من قائمة المفضلات");

        updateChangeWishlist(Math.random(200 * 500));
      } else if (resRemove && resRemove.status === 401) {
        notifyError("يجب تسجيل الدخول اولا");
      }
    }
  }, [loadingRemove]);
  const [showQ, setShowQ] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleImageClick = (index) => {
    setActiveIndex(index);
  };
  const [categoryName, setCategoryName] = useState("");
  useEffect(() => {
    const getAsync = async () => {
      // if (showQ === true) {
      if (item.category) {
        let res = dispatch(getOneCategory(item.category));
        res.then((result) => {
          if (result && result.data) {
            setCategoryName(result.data.name);
          }
        });
      }
      // }
    };
    getAsync();
  }, [item.category, showQ]);
  if (item.images[0].includes("https://www.kiendback.tech")) {
    imgUrl = true;
  } else {
    imgUrl = false;
  }

  const percent =
    prod.priceAfter && ((prod.price - prod.priceAfter) / prod.priceAfter) * 100;


  let imagesas = prod 
  const colors = item?.availableColorsPerSize.map((co)=>co.color)
  console.log(colors)
  return (
    <>
      <>
        {/* {showQ ? (
        <>
          <div className={`popup`}>
            <div className="popup-quick" style={{ position: "relative" }}>
              <div
                className="close-popup-quick"
                onClick={() => setShowQ(false)} 
              >
                <CloseIcon style={{ fontSize: "31px" }} />
              </div>
              <div className="product-gallery-card">
                {loading && item.price === undefined ? (
                  <Skeleton style={{ width: "100%", height: "400px" }} />
                ) : item.price !== undefined ? (
                  <>
                    <div className="main-image-container">
                      <img
                        style={{ opacity: item.inStock ? "1" : "0.7" }}
                        className={`main-image`}
                        src={item.images[activeIndex]}
                        alt={`${item.title} - Kiwi Fashion`}
                        loading="eager"
                      />
                    </div>
                    <div className="thumbnail-images">
                      {item.images.map((img, index) => (
                        <div
                          key={index}
                          className={`thumbnail-image-container ${
                            activeIndex === index ? "active" : ""
                          }`}
                          onClick={() => handleImageClick(index)}
                          // eslint-disable-next-line react/jsx-no-comment-textnodes
                        >
              
                          <img
                            style={{ opacity: item.inStock ? "1" : "0.7" }}
                            src={img}
                            alt={`${item.title} - image${index}`}
                            className={`thumbnail-image`}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <Skeleton style={{ width: "100%", height: "100%" }} />
                )}
              </div>
              <div style={{ marginBottom: "80px" }} className={"product-texts"}>
                <Row className="mt-2">
                  <div className="category-product-text">
                    {item.inStock === false ? (
                      <span className="out-stock-circle">
                        لقد نفذ هذا المنتج
                      </span>
                    ) : (
                      ""
                    )}
                    {item.lowerStock === true && item.inStock === true ? (
                      <span className="lower-stock-circle">
                        بقي القليل من هذا المنتج <Warning />
                      </span>
                    ) : (
                      ""
                    )}
                    <br />
                    {loading && !item ? (
                      <Skeleton width={80} height={10} />
                    ) : typeof categoryName === "string" ? (
                      <span>
                        {categoryName} :
                        <br />
                        {item.code ? (
                          <span style={{ color: "#777" }}>
                            كود المنتج {item.code}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      <Skeleton width={80} height={10} />
                    )}
                  </div>
                </Row>
                <Row>
                  <Col
                    md="11"
                    style={{
                      borderBottom:
                        typeof item.title === "string" && "1px solid #dfdfdf",
                      paddingBottom: "16px",
                      position: "relative",
                    }}
                  >
                    <div className="cat-title d-inline">
                      {loading && !item ? (
                        <Skeleton width={290} height={25} />
                      ) : typeof item.title === "string" ? (
                        `${item.title}`
                      ) : (
                        <Skeleton width={290} height={25} />
                      )}
                    </div>
                    <a className="mt-2" href="#rate-container">
                      <div className="d-flex">
                        {loading === false && item && item.ratingsAverage ? (
                          <>
                            <span className="rate-prod">
                              <StarFilled style={{ marginLeft: "3px" }} />{" "}
                              {item.ratingsAverage}
                            </span>
                            <span className="quant-style">
                              {item.ratingsQuantity
                                ? `${item.ratingsQuantity} تقييم`
                                : ""}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="rate-prod">
                              <StarFilled /> 0
                            </span>
                            <span
                              style={{
                                color: "#777",
                                marginLeft: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {item.ratingsQuantity
                                ? `${item.ratingsQuantity} تقييم`
                                : ""}
                            </span>
                          </>
                        )}
                      </div>
                    </a>
                  </Col>
                </Row>
                <>
                  {!Array.isArray(item.availableColors) ? (
                    <Skeleton width={140} height={16} />
                  ) : Array.isArray(item.availableColors) &&
                    JSON.stringify(item.availableColors) !== '[""]' &&
                    JSON.stringify(item.availableColors) !== '["none"]' ? (
                    <Row className="mt-4">
                      <div className="cat-text">الالوان المتاحة :</div>
                    </Row>
                  ) : (
                    ""
                  )}
                </>
                {item.availableColors &&
                Array.isArray(item.availableColors) &&
                JSON.stringify(item.availableColors) !== '[""]' &&
                JSON.stringify(item.availableColors) !== '["none"]' ? (
                  <Row>
                    <Col
                      md="10"
                      className="mt-1 d-flex"
                      style={{ flexWrap: "wrap", gap: "10px" }}
                    >
                      {item.availableColors
                        ? item.availableColors.map((color, index) =>
                            color === "none" ? (
                              ""
                            ) : (
                              <div
                                className="color ms-2"
                                key={index}
                                onClick={() => colorClick(index, color)}
                                style={{
                                  backgroundColor: color,
                                  border:
                                    indexColor === index
                                      ? "4px solid black"
                                      : "2px solid black",
                                  outline:
                                    indexColor === index
                                      ? "2px solid #777"
                                      : "2px solid black",
                                }}
                              ></div>
                            )
                          )
                        : null}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {item.availableColors &&
                Array.isArray(item.availableColors) &&
                JSON.stringify(item.availableColors) !== '[""]' &&
                JSON.stringify(item.availableColors) !== '["none"]' ? (
                  <Divider
                    sx={{
                      height: "1px",
                      width: "100%",
                      background: "rgba(0,0,0,0.5)",
                      marginTop: "16px",
                      marginBottom: "-9px",
                    }}
                  />
                ) : (
                  ""
                )}
                {item &&
                item.availableSizes &&
                item.availableSizes.length > 0 ? (
                  <Row className="mt-4">
                    {loading && !item && !item.availableSizes ? (
                      <Skeleton width={140} height={16} />
                    ) : (
                      <div className="cat-text">الاحجام المتاحة :</div>
                    )}
                  </Row>
                ) : (
                  ""
                )}
                {item.availableSizes && Array.isArray(item.availableSizes) ? (
                  <Row>
                    <Col
                      md="10"
                      className="mt-1 d-flex"
                      style={{ flexWrap: "wrap" }}
                    >
                      {item.availableSizes
                        ? item.availableSizes.map((size, index) => (
                            <div
                              className="select-size"
                              key={index}
                              style={{
                                background:
                                  indexSize === index
                                    ? "var(--main-color)"
                                    : "#fff",
                                color: indexSize === index ? "#fff" : "#111",
                                borderRadius:
                                  indexSize === index ? "3px" : "9px",
                                transition: "0.15s linear",
                              }}
                              onClick={() => sizeClick(index, size)}
                            >
                              {size}
                            </div>
                          ))
                        : null}
                    </Col>
                  </Row>
                ) : null}
                {item && item.price && (
                  <Divider
                    sx={{
                      height: "1px",
                      width: "100%",
                      background: "rgba(0,0,0,0.5)",
                      marginTop: "16px",
                      marginBottom: "0px",
                    }}
                  />
                )}
                <Row className="mt-2">
                  <Col md="11">
                    <div
                      className="price-product-single d-flex"
                      style={{ flexDirection: "row", textAlign: "right" }}
                    >
                      <div
                        style={{
                          marginLeft: "6px",
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item && item.priceAfter ? (
                          <>
                            <span
                              className="before-dis"
                              style={{
                                textDecoration: "line-through",
                                fontSize: "14px",
                                color: "#999",
                                marginTop: "6px",
                                marginLeft: "3px",
                              }}
                            >
                              {item.price}TL
                            </span>
                            <span
                              className="after-dis"
                              style={{ marginLeft: "2px" }}
                            >
                              {item.priceAfter}TL
                            </span>
                          </>
                        ) : (
                          <>
                            {loading && item.price === undefined ? (
                              <Skeleton width={190} height={26} />
                            ) : item.price !== undefined ? (
                              <span style={{ fontSize: "27px" }}>
                                {item.price}TL
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>

                      <div
                        style={{
                          textAlign: "right",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {loading && !item.price ? (
                          <Skeleton width={190} height={26} />
                        ) : item.priceDollar !== undefined &&
                          item.priceDollar &&
                          item.priceDollarAfter ? (
                          <>
                            <span
                              className="before-dis"
                              style={{
                                textDecoration: "line-through",
                                fontSize: "14px",
                                color: "#999",
                                marginTop: "6px",
                                marginLeft: "3px",
                              }}
                            >
                              {`${item.priceDollar}$`}
                            </span>
                            <span
                              className="after-dis"
                              style={{ marginLeft: "7px" }}
                            >
                              {`${item.priceDollarAfter}$`}
                            </span>
                          </>
                        ) : (
                          <span
                            className="after-dis"
                            style={{ marginLeft: "7px" }}
                          >
                            {`${item.priceDollar}$`}
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    {loading && item.price === undefined ? (
                      <Skeleton width={320} height={17} />
                    ) : item.price !== undefined ? (
                      <div
                        onClick={handleAddToCart}
                        className={`product-cart-add ${
                          item.inStock === false ? "btn-disabled" : ""
                        }`}
                      >
                        {loadingCart === true ? (
                          <Spinner animation="border" variant="dark" />
                        ) : (
                          "اضف الى السلة"
                        )}
                        <AddShoppingCartIcon />
                      </div>
                    ) : (
                      <Skeleton width={320} height={17} />
                    )}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md="11">
                    <div className="product-contact-container">
                      {loading && item.price === undefined ? (
                        <Skeleton width={420} height={25} />
                      ) : item.price !== undefined ? (
                        <div>ايميل التواصل: kiwi@kiwifashion.net</div>
                      ) : (
                        <Skeleton width={420} height={25} />
                      )}
                      {loading && item.price === undefined ? (
                        <Skeleton width={420} height={25} />
                      ) : item.price !== undefined ? (
                        <div> رقم التواصل: 00905395955434 </div>
                      ) : (
                        <Skeleton width={420} height={25} />
                      )}
                      {loading && item.price === undefined ? (
                        <Skeleton width={420} height={25} />
                      ) : item.price !== undefined ? (
                        <div style={{ color: "red" }}>
                          {" "}
                          ملاحظة: يجب اختيار لون او مقاس ان وجدوا
                        </div>
                      ) : (
                        <Skeleton width={420} height={25} />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </>
      ) : null} */}
      </>
      <div className="showcase" style={{ direction: "rtl" }}>
        <div className="showcase-banner">
          <a
            href={`/products/${prod._id}`}
            style={{ opacity: !prod.inStock && "0.6" }}
          >
            <LazyImage imgUrl={imgUrl} items={imagesas} />
            {/* <img
              src={
                imgUrl
                  ? `${url}/products/${
                      prod.images.length === 1
                        ? prod.imageCover
                        : prod.images[1]
                    }`
                  : prod.images.length === 1
                  ? prod.imageCover
                  : prod.images[1]
              }
              alt="imageCover"
              className="product-img hover"
              width="300"
              loading={"eager"}
            /> */}
          </a>
          {prod.inStock === false ? (
            <p className="showcase-badge angle red">انتهى</p>
          ) : (
            ""
          )}
          {prod.lowerStock === true && prod.inStock === true ? (
            <p className="showcase-badge angleTwo orange">بقي القليل</p>
          ) : (
            ""
          )}
          {prod.inStock && (
            <div className="showcase-actions">
              <button
                className={`btn-action ${isFav ? "is-fav" : ""}`}
                onClick={handleFav}
              >
                <Heart
                  size={26}
                  name="heart-outline"
                  role="img"
                  className="md hydrated"
                  aria-label="heart outline"
                />
              </button>

              {/* <button className="btn-action" onClick={() => setShowQ(true)}>
                <Eye
                  size={26}
                  name="eye-outline"
                  role="img"
                  className="md hydrated"
                  aria-label="eye outline"
                />
              </button> */}

              {/* <button className="btn-action">
                <AddShoppingCartOutlinedIcon
                  name="repeat-outline"
                  role="img"
                  className="md hydrated"
                  aria-label="add to cart"
                ></AddShoppingCartOutlinedIcon>
              </button> */}
            </div>
          )}
        </div>

        <div className="showcase-content">
          <div className="first-content">
            <a
              className="cat-name"
              href={`/products?category=${prod.category}`}
            >
              {categoryName}
            </a>
            <a href={`/products/${prod._id}`} className="prod-title">
              {prod.title}
            </a>
          </div>
          <div className="price">
            <span
              className="percent"
              dir="ltr"
              style={{ paddingRight: "12px" }}
            >
              {percent > 0 && `-${Math.floor(percent)}%`}
            </span>

            <div>
              {item.price ? (
                <>
                  {prod.priceAfter && prod.priceAfter != 0 ? (
                    <span className="price-after">{`${prod.price}TL`}</span>
                  ) : (
                    ""
                  )}
                  <span>
                    {`${
                      prod.priceAfter != 0 && prod.priceAfter
                        ? prod.priceAfter
                        : prod.price
                    }TL`}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="colors-product-card">
            {colors.slice(0, 3).map((color, index) => (
              <div
                className="color"
                key={index}
                style={{ backgroundColor: color }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
