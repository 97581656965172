import React from "react";
import CartItem from "../Cart/CartItem";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import GetOrderDetailsHook from "../../hook/admin/get-order-details-hook";
import UserAllOrdersItem from './../User/UserAllOrdersItem';
import ChangeOrderStatusHook from "../../hook/admin/change-order-status-hook";
import { ToastContainer } from "react-toastify";

const AdminOrderDetalis = () => {
  const {id} = useParams()
  const [orderData,cartItems] = GetOrderDetailsHook(id)
  const dateString = orderData.createdAt

  const formatDate = (dateString)=>{
        const options = {year:'numeric',month:'long',day:'numeric'}
        return new Date(dateString).toLocaleDateString(undefined,options)
    }
    let dateOfOrder = formatDate(dateString)
    const [onChangePaid,pay,changePayOrder,onChangeDeliver,deliver,changeDeliverOrder] = ChangeOrderStatusHook(id)
  return (
    <div>
      <div className="admin-content-text"> تفاصيل طلب #2133</div>
      <Row className="justify-content-between ">
        <UserAllOrdersItem item={orderData} />
 
        <Row className="justify-content-center mt-4 user-data">
          <Col xs="12" className=" d-flex">
            <div className="admin-content-text py-2">تفاصيل العميل</div>
          </Col>
          <Col xs="12" className="d-flex">
            <div
              style={{
                color: "#555550",
                fontSize: "16px",
              }}
            >
              الاسم:
            </div>

            <div
              style={{
                color: "#979797",
                fontSize: "16px",
              }}
              className="mx-2"
            >
             {orderData.user && orderData.user.name && orderData.user.name}
            </div>
          </Col>

          <Col xs="12" className="d-flex">
            <div
              style={{
                color: "#555550",
                fontSize: "16px",
              }}
            >
              رقم الهاتف:
            </div>

            <div
              style={{
                color: "#979797",
                fontSize: "16px",
              }}
              className="mx-2"
              dir="ltr"
            >
                           +{orderData.user && orderData.user.phone && orderData.user.phone}

            </div>
          </Col>
          <Col xs="12" className="d-flex">
            <div
              style={{
                color: "#555550",
                fontSize: "16px",
              }}
            >
              الايميل:
            </div>

            <div
              style={{
                color: "#979797",
                fontSize: "16px",
              }}
              className="mx-2"
            >
             {orderData.user &&  orderData.user.email && orderData.user.email}
            </div>
          </Col>
      
          <div className=" d-inline px-4 border text-center pt-2">
            المجموع  {orderData.totalOrderPrice ? orderData.totalOrderPrice : 0}TL
          </div>
          <div className="d-flex mt-2 justify-content-space-around" style={{marginBottom:"25px"}}>

          <div className="d-flex mt-2 justify-content-center" style={{width:'50%'}}>
            <select
              style={{boxShadow:'0 0 12px rgba(0,0,0,0.1)'}}
              name="pay"
              id="pay"
              onChange={onChangePaid}
              value={pay}
              className="select input-form-area mt-1  text-center px-2 w-50"
            >
              <option value="0">حالة الدفع</option>
              <option value={'true'}> تم الدفع</option>
              <option value={'false'}>لم يتم الدفع</option>
            </select>
            <button onClick={changePayOrder} className="btn-a px-3 d-inline mx-2 ">حفظ </button>
          </div>
          <div className="d-flex mt-2 justify-content-center" style={{width:'50%'}}>
            <select
              name="deliver"
              style={{boxShadow:'0 0 12px rgba(0,0,0,0.1)'}}
              value={deliver}
              onChange={onChangeDeliver}
              id="deliver"
              className="select input-form-area mt-1  text-center px-2 w-50"
            >
              <option value="0">حالة التوصيل</option>
              <option value="false">قيد التوصيل</option>
              <option value="true">تم التوصيل</option>
            </select>
            <button className="btn-a px-3 d-inline mx-2 " onClick={changeDeliverOrder} >حفظ </button>
          </div>
          </div>

        </Row>
      </Row>
      <ToastContainer/>
    </div>
  );
};

export default AdminOrderDetalis;
