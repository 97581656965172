import  { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getAllCategory, getAllCategoryPage } from '../../redux/actions/categoryAction'

const AllCategoryHook = () => {
    const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1); // حالة لتتبع الصفحة الحالية
  const [currentLimit, setCurrentLimit] = useState(8); 
  useEffect(() => {
      dispatch(getAllCategory(currentLimit));
  }, [dispatch, currentLimit]);

  const categories = useSelector((state) => state.allCategory.category);
  const loading = useSelector((state) => state.allCategory.loading);
  let pageCount = currentPage;
  if (categories.paginationResult) {
    pageCount = categories.paginationResult.numberOfPages;
  }
  const getPage = (page) => {
    setCurrentPage(page);
    dispatch(getAllCategoryPage(page, currentLimit));
  };
    return [categories,loading,pageCount,getPage]
};

export default AllCategoryHook;
