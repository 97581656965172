import {CREATE_NEW_USER,LOGIN_USER,RESET_PASSWORD,GET_CURRENT_USER,FORGET_PASSWORD ,VERIFY_PASSWORD} from "../type";

const inital = {
  loginUser: [],
  createUser:[],
  getCurrentUser:[],
  forgetPassword:[],
  verifyPassword:[],
  resetPassword:[],
  loading: true,
};
const authReducer = (state = inital, action) => {
  switch (action.type) {
    case CREATE_NEW_USER:
      return {
        ...state,
        createUser: action.payload,
        loading: false,
      };
    case LOGIN_USER:
      return {
        ...state,
        loginUser: action.payload,
        loading: false,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        getCurrentUser: action.payload,
        loading: false,
      };
 
    case FORGET_PASSWORD:
      return {
        ...state,
        forgetPassword: action.payload,
        loading: false,
      };
    case VERIFY_PASSWORD:
      return {
        ...state,
        verifyPassword: action.payload,
        loading: false,
      };
 
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.payload,
        loading: false,
      };
 
    default:
      return state;
  }
};
export default authReducer;
