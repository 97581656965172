// ChangeContext.js
import React, { createContext, useContext, useState } from 'react';

const ChangeCart = createContext();

export const ChangeCartProvider = ({ children }) => {
  const [changeCart, setChangeCart] = useState(null);

  const updateChangeCart = (newChange) => {
    setChangeCart(newChange);
  };

  return (
    <ChangeCart.Provider value={{ changeCart, updateChangeCart }}>
      {children}
    </ChangeCart.Provider>
  );
};

export const useChangeCart = () => {
  return useContext(ChangeCart);
};
