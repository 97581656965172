import React from "react";
import { Col, Row } from "react-bootstrap";
import UserAllOrderCard from "./UserAllOrderCard";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import notify from "../../hook/useNotifaction";
import { ToastContainer } from "react-toastify";
import copy from "clipboard-copy";
import { Helmet } from "react-helmet";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const UserAllOrdersItem = ({ item }) => {
  const dateString = item.createdAt;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  let dateOfOrder = formatDate(dateString);
  const handleCopy = () => {
    try {
      copy(item.message);
      notify("تم نسخ الرسالة بنجاح ", "success");
    } catch (error) {
      console.error("Error copying to clipboard:", error);
      // يمكنك استخدام حالة لعرض رسالة خطأ إذا كان هناك خطأ في النسخ
      // setCopyError(true);
    }
  };
  return (
    <div className="user-order mt-2">
      <Helmet>
        <title>صفحة ادارة الطلبات , Kiwi Fashion</title>
        <meta
          name="description"
          content={`ادارة الطلبات في كيوي  , Kiwi Fashion `}
        />
      </Helmet>
      <BootstrapDialog
        style={{ fontFamily: "Tajawala" }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          style={{ fontFamily: "Tajawala", textAlign: "left" }}
        >
          رسالة الطلب
        </DialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom style={{ fontFamily: "Tajawala" }}>
            اذا كنت لم ترسلها انسخها وقم بارسالها الى هذا الرقم
            <p>
              <a href="tel:00905395955434">00905395955434</a>
            </p>
            <Divider variant="inset" component="li" sx={{ mb: 2 }} />{" "}
            <div
              style={{
                maxHeight: "60%",
                overflowY: "auto",
                whiteSpace: "pre-line",
              }}
            >
              <p>{item.message}</p>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCopy}
            style={{ fontFamily: "Tajawala" }}
          >
            نسخ الرسالة
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Row>
        <div className="py-2 order-title">طلب رقم #{item.id || 0}</div>
      </Row>
      {item &&
        item.cartItems &&
        item.cartItems.map((item, index) => {
          return <UserAllOrderCard key={index} item={item} />;
        })}
      <Row className="d-flex justify-content-between">
        <Col xs="6" className="">
          <div>
            <div className="d-inline"> حالة التوصيل </div>
            <div className="d-inline mx-2 stat">
              {item.isDelivered === true ? " تم التوصيل " : " لم يتم التوصيل "}
            </div>
          </div>
          <div>
            <div className="d-inline"> حالة الدفع </div>
            <div className="d-inline mx-2 stat">
              {item.isPaid === true ? " تم دفع " : " لم يتم الدفع "}
            </div>
          </div>
          <div>
            <div className="d-inline"> طريقة الدفع </div>
            <div className="d-inline mx-2 stat">
              {item.paymentMethodType === "cash" ? "تحويل بنكي" : " تحويل بنكي"}
            </div>
          </div>
          <div>
            <div className="d-inline"> تاريخ الطلب </div>
            <div className="d-inline mx-2 stat">{dateOfOrder}</div>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-end">
          <div>
            <div className="barnd-text">{item.totalOrderPrice || 0}TL </div>
          </div>
        </Col>
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          style={{
            fontFamily: "Tajawala",
            maxWidth: "200px",
            margin: "15px auto",
          }}
          aria-label="فتح رسالة الطلب"
        >
          فتح رسالة الطلب
        </Button>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default UserAllOrdersItem;
