import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getAllProductsPress,
  getAllProductsSearch,
} from "../../redux/actions/productsAction";
import { useLocation, useParams } from "react-router-dom";
import { usePagination } from "../../context/getPaginationPage";

const ViewSearchProductsHook = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState("");
  const { currentPage, onPageChange } = usePagination();

  // const [allProducts, setAllProducts] = useState([]);
  const allProducts = useSelector((state) => state.allProducts.allProductsNew);
  const [loading, setLoading] = useState(true);
  let limit = 8;
  let sortType = "";
  const [sort, setSort] = useState("");
  const sortData = useCallback(async () => {
    if (localStorage.getItem("sortType")) {
      sortType = localStorage.getItem("sortType");
    }

    if (sortType === "السعر من الاقل للاعلي") {
      setSort("+price");
    }
    if (sortType === "السعر من الاعلي للاقل") {
      setSort("-price");
    }
    if (sortType === "بدون ترتيب") {
      setSort("");
    }
    if (sortType === "الاعلي تقييما") {
      setSort("-ratingsQuantity");
    }
    if (sortType === "الاكثر مبيعا") {
      setSort("-sold");
    }
  }, []);
  // const queryString = window.location.search;
  // const { categoryValue, subcategoryValue } = useParams();

  const [fromPrice, setFromPrice] = useState("");
  const [toPrice, setToPrice] = useState("");
  const [sizeS, setSize] = useState("");
  // تحليل الاستعلام باستخدام URLSearchParams
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryValue = searchParams.get("category");
  const subcategoryValue = searchParams.get("subcategory");

  const getProduct = useCallback(async () => {
    const word = localStorage.getItem("searchWord") || "";
    sortData();
    const sortType = localStorage.getItem("sortType");
    const sortOptions = {
      "السعر من الاقل للاعلي": "+price",
      "السعر من الاعلي للاقل": "-price",
      "بدون ترتيب": "",
      "الاعلي تقييما": "-ratingsQuantity",
      "الاكثر مبيعا": "-sold",
    };
    const so = sortOptions[sortType] || "";

    setLoading(true);
    setTimeout(async () => {
      await dispatch(
        getAllProductsSearch(
          limit,
          categoryValue,
          subcategoryValue,
          word,
          so,
          currentPage || ""
        )
      );
      setLoading(false);
    }, 2000);
  }, [categoryValue, subcategoryValue]);

  useEffect(() => {
    // if (categoryValue !== initialCategoryValue)
    sortData();
    getProduct();
  }, [categoryValue, subcategoryValue]); // استخدم selectedCatID كاعتماد للتأثير

  let items = allProducts ? allProducts : [];
  let pagination = allProducts?.paginationResult || [];
  let results = allProducts?.results || 0;
  let word = "";

  const onPress = useCallback(
    async (page) => {
      setPageNumber(page);

      sortData();
      if (localStorage.getItem("sortType")) {
        sortType = localStorage.getItem("sortType");
      }
      let so = "";
      if (sortType === "السعر من الاقل للاعلي") {
        so = "+price";
      }
      if (sortType === "السعر من الاعلي للاقل") {
        so = "-price";
      }
      if (sortType === "بدون ترتيب") {
        so = "";
      }
      if (sortType === "الاعلي تقييما") {
        so = "-ratingsQuantity";
      }
      if (sortType === "الاكثر مبيعا") {
        so = "-sold";
      }

      if (localStorage.getItem("searchWord")) {
        word = localStorage.getItem("searchWord");
        setLoading(true);

        setTimeout(async () => {
          await dispatch(
            getAllProductsSearch(
              limit,
              categoryValue || "",
              subcategoryValue || "",
              word || "",
              so,
              page,
              fromPrice || "",
              toPrice || "",
              sizeS || ""
            )
          );
          setLoading(false);
        }, 1000);
        // setAllProducts(res);
      }

      setLoading(true);
      setTimeout(async () => {



          let res = await dispatch(
            getAllProductsSearch(
              limit,
              categoryValue,
              subcategoryValue,
              word,
              so,
              page,
              sizeS || "",
              toPrice,
              fromPrice
            )
          );
        

          // }
    
          setLoading(false);
      }, 1500);
      // setAllProducts(res);
    },
    [pageNumber]
  );

  const filterByPrice = async () => {
    if (localStorage.getItem("sortType")) {
      sortType = localStorage.getItem("sortType");
    }
    let so = "";
    if (sortType === "السعر من الاقل للاعلي") {
      so = "+price";
    }
    if (sortType === "السعر من الاعلي للاقل") {
      so = "-price";
    }
    if (sortType === "بدون ترتيب") {
      so = "";
    }
    if (sortType === "الاعلي تقييما") {
      so = "-ratingsQuantity";
    }
    if (sortType === "الاكثر مبيعا") {
      so = "-sold";
    }
    setLoading(true);
    setTimeout(async () => {
      await dispatch(
        getAllProductsSearch(
          limit,
          categoryValue || "",
          subcategoryValue || "",
          word || "",
          so,
          pageNumber || "",
          fromPrice || "",
          toPrice || "",
          sizeS || ""
        )
      );
      setLoading(false);
    }, 1500);
  };

  const filterBySize = async () => {
    if (localStorage.getItem("sortType")) {
      sortType = localStorage.getItem("sortType");
    }
    let so = "";
    if (sortType === "السعر من الاقل للاعلي") {
      so = "+price";
    }
    if (sortType === "السعر من الاعلي للاقل") {
      so = "-price";
    }
    if (sortType === "بدون ترتيب") {
      so = "";
    }
    if (sortType === "الاعلي تقييما") {
      so = "-ratingsQuantity";
    }
    if (sortType === "الاكثر مبيعا") {
      so = "-sold";
    }
    setLoading(true);
    setTimeout(async () => {
      await dispatch(
        getAllProductsSearch(
          limit,
          categoryValue || "",
          subcategoryValue || "",
          word || "",
          so,
          pageNumber || "",
          fromPrice || "",
          toPrice || "",
          sizeS || ""
        )
      );
      setLoading(false);
    }, 1500);
  };
  const resetFilters = async () => {
    setLoading(true); 
    setTimeout(async () => {
      await dispatch(getAllProductsSearch(limit));
      setLoading(false);
    }, 1500);
  };

  return [
    items,
    pagination,
    onPress,
    getProduct,
    results,
    fromPrice,
    setFromPrice,
    toPrice,
    setToPrice,
    filterByPrice,
    loading,
    filterBySize,
    setSize,
    sizeS,
    resetFilters,
  ];
};

export default ViewSearchProductsHook;
