import React from "react";
import "./else.css";
import SecondAd from "../../Components/AdsCompnents/2Ad";
import AdCom from "../../Components/AdsCompnents/AdCom";
const WarrantyPolicy = () => {
  return (
    <div className="container-else">
      <h2>سياسة الضمان</h2>
      <p>
        يعتبر الضمان ساري من تاريخ الشراء. هذا الضمان يغطي عيوب التصنيع, ولا
        يشمل اي تلف ناجم عن سوء الإستخدام. إذا تعرضت أي من مشترياتك لأي مشاكل
        ناجمه عن عيوب التصنيع خلال فترة الضمان, سوف نتكفل بتصليح او استبدال
        المنتج على حسب تقدير الأضرار (تطبق الشروط والأحكام). يجب إظهار فاتورة
        الشراء الأصلية لموظف الخدمة أثناء التقدم بطلب استبدال أو استرجاع لأي من
        المنتجات السارية تحت الضمان.
      </p>
      <AdCom slot="7372516538" />
    </div>
  );
};

export default WarrantyPolicy;
