// ChangeContext.js
import React, { createContext, useContext, useState } from 'react';

const ChangeContext = createContext();

export const ChangeProvider = ({ children }) => {
  const [change, setChange] = useState(null);

  const updateChange = (newChange) => {
    setChange(newChange);
  };

  return (
    <ChangeContext.Provider value={{ change, updateChange }}>
      {children}
    </ChangeContext.Provider>
  );
};

export const useChange = () => {
  return useContext(ChangeContext);
};
