import React from "react";
import { Col, Row } from "react-bootstrap";
import ProductGallery from "./ProductGallery";
import ProductsText from "./ProductsText";
import ViewProductDetailsHook from "../../hook/products/view-product-details-hook";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const ProductDetails = () => {
  let { id } = useParams();
  const [
    item,
    goToNextImage,
    goToPreviousImage,
    images,
    currentImageIndex,
    cat,
    brand,
    filteration,
    loading,
  ] = ViewProductDetailsHook(id);

  return (
    <div className="product-details-container">
      {loading ? (
        // إذا كان التحميل قيد التشغيل، عرض دائرة التحميل في المنتصف
        <div className="loader-container">
          <ClipLoader color="#36D7B7" loading={loading} size={50} />
        </div>
      ) : (
        // إذا تم التحميل بنجاح، عرض المحتوى
        <Row className="py-3">
          <Col xs="12" md="12" lg="6">
            <ProductGallery item={item} id={id} />
          </Col>
          <Col xs="12" md="12" lg="6">
            <ProductsText item={item} cat={cat} filteration={filteration} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProductDetails;
