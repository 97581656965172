import React, { useState } from "react";
import { Button, Modal, Row, Spinner } from "react-bootstrap";
import { Col } from "react-bootstrap";
import mobile from "../../images/mobile.png";
import deleteicon from "../../images/delete.png";
import url from "../../Api/url";
import DeleteCartHook from "../../hook/cart/delete-cart-hook";
import { ToastContainer } from "react-toastify";
const CartItem = ({ item }) => {
  const [
    handleDeleteCart,
    loadingDelete,
    handleDeleteOneProd,
    show,
    handleClose,
    handleShow,
    loadingDeleteOne,
    itemCount,
    onChangeCount,
    handleUpdateCart,
  ] = DeleteCartHook(item);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>تاكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل انت متاكد من انك تريد حذف المنتج من العربة</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            تراجع
          </Button>
          <Button
            className={`${loadingDeleteOne ? "stop-btn-save" : ""}`}
            variant="danger"
            onClick={handleDeleteOneProd}
          >
            {loadingDeleteOne === true ? (
              <Spinner animation="border" variant="light" />
            ) : (
              "حذف"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Col xs="12" className="cart-item-body my-2 d-flex px-2">
        <img
          className="imgCover"
          src={`${url}/products/${
            item && item.product ? item.product.imageCover : ""
          }`}
          alt=""
        />
        <div className="w-100">
          <Row className="justify-content-between">
            <Col sm="12" className=" d-flex flex-row justify-content-between">
              <div className="d-inline pt-2 cat-text">
                {item && item.product ? item.product.category.name : ""}
              </div>
              <div
                onClick={handleShow}
                className="d-flex pt-2 "
                style={{ cursor: "pointer" }}
              >
                <img src={deleteicon} alt="" width="20px" height="24px" />
                <div className="cat-text d-inline me-2">ازاله</div>
              </div>
            </Col>
          </Row>
          <a href={`/products/${item && item.product && item.product._id}`}>
          <Row  className="justify-content-center mt-2">
            <Col sm="12" className=" d-flex flex-row justify-content-start">
              <div className="d-inline pt-2 cat-title">
                {item && item.product ? item.product.title : ""}
              </div>
              <div
                style={{ color: "orange" }}
                className="d-inline pt-2 cat-rate me-2"
              >
                {item && item.product ? item.product.ratingsAverage : 0}
              </div>
            </Col>
          </Row>
          </a>
          {/* <Row>
          <Col sm="12" className="mt-1">
            <div className="cat-text d-inline">الماركة :</div>
            <div className="barnd-text d-inline mx-1">ابل </div>
          </Col>
        </Row> */}
          <Row className="d-flex">
            <Row>
              <Col sm="12" className="mt-1 d-flex">
                {item && item.color === "" ? null : (
                  <div
                    className="color ms-2 border"
                    style={{ backgroundColor: `${item && item.color}` }}
                  >
                    {/* محتوى العنصر إذا كان لديك محتوى إضافي */}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="mt-2 mb-1 d-flex">
                {item && item.size === "" ? null : (
                  <div className="select-size-cart">{item.size}</div>
                )}
              </Col>
            </Row>
          </Row>

          <Row className="justify-content-between">
            <Col sm="12" className=" d-flex flex-row justify-content-between">
              <div className="d-inline pt-2 d-flex">
                <div className="cat-text  d-inline">الكميه</div>
                <input
                  className="mx-2 "
                  type="number"
                  value={itemCount || 0}
                  onChange={onChangeCount}
                  style={{ width: "40px", height: "25px" }}
                />
                <Button
                  onClick={handleUpdateCart}
                  variant="dark"
                  style={{ fontSize: "9px", padding: "6px 12px" }}
                >
                  تطبيق
                </Button>
              </div>
              <div className="d-inline pt-2 barnd-text">
                {item && item.price ? item.price : 0}TL
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <ToastContainer/>
    </>
  );
};

export default CartItem;
