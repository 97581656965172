import React , { useEffect, useState } from 'react';
import baseUrl from '../../Api/baseURL';
import { useSelector, useDispatch } from 'react-redux'

import { getAllBrand,getAllBrandPage } from './../../redux/actions/brandAction';

const AllBrandHook = () => {
    const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1); // حالة لتتبع الصفحة الحالية
  const [currentLimit, setCurrentLimit] = useState(6); 
  useEffect(() => {
    const brandTimeout = setTimeout(() => {
      dispatch(getAllBrand(currentLimit));
    }, 0);

    return () => {
      clearTimeout(brandTimeout);
    };
  }, [dispatch, currentLimit]);

  const brands = useSelector((state) => state.allBrand.brand);
  const loading = useSelector((state) => state.allBrand.loading);
  let pageCount = currentPage;
  if (brands.paginationResult) {
    pageCount = brands.paginationResult.numberOfPages;
  }
  const getPage = (page) => {
    setCurrentPage(page);
    dispatch(getAllBrandPage(page, currentLimit));
  };
    return [brands,loading,pageCount,getPage]
};

export default AllBrandHook;
