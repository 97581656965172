import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import "./payment.css";
import ViewUserAddressesHook from "./../../hook/user/view-user-addresses-hook";
import OrderPayChashHook from "../../hook/checkout/order-pay-chash-hook";
import { ToastContainer } from "react-toastify";
import GetAllUserCartHook from "../../hook/cart/get-all-user-cart-hook";
import { getAllCartItems } from "../../redux/actions/cartAction";
import { useChangeCart } from "../../context/ChangeCart";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
const ChoosePayMethoud = () => {
  const [res] = ViewUserAddressesHook();
  const [
    itemsNum,
    cartItems,
    totalPrice,
    couponName,
    totalPriceAfter,
    cartID,
    loadingCart,
  ] = GetAllUserCartHook();
  const [
    handleChooseAddress,
    addressDetails,
    handleCreateOrderCash,
    addressValue,
  ] = OrderPayChashHook();

  return (
    <>
      {loadingCart ? (
        <div className="loader-container">
          <ClipLoader color="#36D7B7" loading={loadingCart} size={50} />
        </div>
      ) : (
        <div>
          <div className="admin-content-text pt-5">اختر طريقة الدفع</div>
          <div className="user-address-card my-3 px-3">
            <div className="payment-method">
              <div className="mar">
                <input
                  name="group"
                  id="group1"
                  type="radio"
                  value="الدفع عن طريق التحويل البنكي"
                  className="mt-2"
                  checked
                />
                <label className="mx-2" for="group1">
                  الدفع عن طريق التحويل البنكي
                </label>
              </div>
            </div>

            <select
              name="address"
              id="address"
              className="select-sub-cat mt-3 px-2 "
              onChange={handleChooseAddress}
              value={addressValue}
            >
              <option value="0">اختر عنوان للشحن</option>
              {res.data && res.data.length > 0 ? (
                res.data.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.alias}
                  </option>
                ))
              ) : (
                <option value="0">لا يوجد عناوين</option>
              )}
            </select>
            {/* {!loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">يتم التحميل...</span>
            </Spinner>
          ) : null} */}
            <span style={{ display: "block", margin: "13px" }}>
              لم تقم باضافة عنوان{" "}
              <a href="/user/addresses">اضغط هنا لاضافة عنوان جديد</a>
            </span>
          </div>

          <Row>
            <Col xs="12" className="d-flex justify-content-end">
              <div className="product-price d-inline   border">
                {totalPriceAfter > 0
                  ? `${totalPriceAfter}ليرة تركي`
                  : `${totalPrice}ليرة تركي`}
              </div>
              <div
                onClick={handleCreateOrderCash}
                className="product-cart-add d-flex me-2 justify-content-center"
                style={{ alignItems: "center", padding: "8px 14px" }}
              >
                {" "}
                اتمام الشراء
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default ChoosePayMethoud;
