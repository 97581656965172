import React, { useEffect } from "react";
import AdSense from "react-adsense";

const SixAd = () => {
  useEffect(()=>{
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
},[])

  return (
    <div>
      {/* إضافة الكود الخاص بالإعلان هنا */}
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6852607654742650"
        crossorigin="anonymous"
      ></script>
      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="autorelaxed"
        data-ad-client="ca-pub-6852607654742650"
        data-ad-slot="8536644146"
      ></ins>
    </div>
  );
};

export default SixAd;
