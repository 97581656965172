import React, { useEffect } from "react";

const AdCom = ({ slot, keyS, fuild, fullWidth }) => {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
    // eslint-disable-next-line no-undef
    // (adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div style={{ margin: "auto" }}>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6852607654742650"
        crossorigin="anonymous"
      ></script>
      {/* إضافة الكود الخاص بالإعلان هنا */}
      <ins
        className="adsbygoogle"
        style={{
          display: "block",
          width: fullWidth && "150px", // تحديد العرض وفقًا للشرط
          height: fullWidth && "250px",
        }}
        data-ad-layout-key={keyS ? keyS : ""}
        data-ad-client="ca-pub-6852607654742650"
        data-ad-slot={slot}
        data-ad-format={fuild ? "fluid" : "auto"}
      ></ins>
    </div>
  );
};

export default AdCom;
