import { ADD_PRODUCT_TO_WISHLIST,REMOVE_PRODUCT_FROM_WISHLIST,USER_WISHLIST} from "../type";

  
  const inital = {
    addProductToWishList:[],
    deleteProductFromWishList:[],
    userWishlist:[],
    loading: true,
  };
  const wishlistReducer = (state = inital, action) => {
    switch (action.type) {
      case ADD_PRODUCT_TO_WISHLIST:
        return {
          ...state,
          addProductToWishList: action.payload,
          loading: false,
        };
      case REMOVE_PRODUCT_FROM_WISHLIST:
        return {
          ...state,
          deleteProductFromWishList: action.payload,
          loading: false,
        };
      case USER_WISHLIST:
        return {
          ...state,
          userWishlist: action.payload,
          loading: false,
        };

      default:
        return state;
    }
  };
  export default wishlistReducer;
  