import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import deleteicon from "../../images/delete.png";
import "./CardAddress.css"; // استيراد ملف الأنماط CSS
import { ToastContainer } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress } from "../../redux/actions/userAdressesAction";
import notify from "../../hook/useNotifaction";
import { useChange } from "../../context/ChangeContext";

const UserAddressCard = ({item}) => {
  const [show,setShow]=useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading,setLoading] = useState('')
  const dispatch = useDispatch()
  const handleDelete = async()=>{
      setLoading(true)
      await dispatch(deleteAddress(item._id))
      setLoading(false)
  }
  const {change,updateChange} = useChange()
const res = useSelector(state=>state.userAddressReducer.deleteAddress)
  useEffect(()=>{
    if(loading === false){
      if(res){
        if(res.status === 'success'){
          notify('تم حذف العنوان بنجاح','success')
            updateChange(Math.random(100 * 500))
            handleClose()
        }
      }

    }
  },[loading])
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>تاكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل انت متاكد من انك تريد حذف التقييم</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            تراجع
          </Button>
         
            <Button variant="danger" onClick={handleDelete}>
            حذف
          </Button>
         
        
        </Modal.Footer>
      </Modal>
    <div className="user-address-card my-3">
      <div className="header">
        <div className="title">اسم العنوان: {item.alias}</div>
        <div className="actions">
          <a href={`/user/edit-address/${item._id}`} className="edit-link">
            <i className="fas fa-pencil-alt"></i> تعديل
          </a>
          <span className="delete" onClick={handleShow} style={{cursor:'pointer'}}>
            <img
              alt=""
              className="delete-icon"
              src={deleteicon}
      
              height="17px"
              width="15px"
              />
            ازاله
          </span>
        </div>
      </div>

      <div className="address">{item.details}</div>

      <div className="d-flex pt-3">
        <div className="phone-label"> الدولة: </div>
        <div className="phone-number"> {item.country} </div>

        <div className="phone-label" style={{paddingRight:'15px'}}> المدينة: </div>
        <div className="phone-number"> {item.city} </div>
      </div>
      <br/>
      <div className="d-flex mb-2">
        <div className="phone-label">  الرمز البريدي: </div>
        <div className="phone-number" dir="ltr">{item.postalCode} </div>
      </div>
      <div className="d-flex mb-2">
        <div className="phone-label"> رقم الهاتف: </div>
        <div className="phone-number" dir="ltr">+{item.phone} </div>
      </div>
    <ToastContainer/>
    </div>
              </>
  );
};

export default UserAddressCard;
