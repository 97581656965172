import React, { useEffect, useState } from "react";
import { getOneAddress } from "../../redux/actions/userAdressesAction";
import { useDispatch, useSelector } from "react-redux";
import GetAllUserCartHook from "../cart/get-all-user-cart-hook";
import notify from "../useNotifaction";
import { createOrderCash } from "../../redux/actions/checkoutAction";
import {
  changeOrderMessage,
  getOneOrder,
} from "../../redux/actions/orderAction";
import url from "./../../Api/url";
import qs from "qs";

import axios from "axios";
import translate from "translate";
import { useChange } from "../../context/ChangeContext";
import { useChangeCart } from "../../context/ChangeCart";

const OrderPayChashHook = () => {
  const [loading, setLoading] = useState("");
  const [loadingCreate, setLoadingCreate] = useState("");
  const [addressDetails, setAddressDetails] = useState([]);
  const [addressValue, setAddressValue] = useState("");
  const [itemsNum, cartItems, totalPrice, couponName, totalPriceAfter, cartID] =
    GetAllUserCartHook();
  const dispatch = useDispatch();
  const fetch = async (userAddress) => {
    setLoading(true);
    await dispatch(getOneAddress(userAddress));
    setLoading(false);
  };
  const handleChooseAddress = (e) => {
    setAddressDetails([]);
    setAddressValue(e.target.value);
    if (e.target.value != "0") {
      fetch(e.target.value);
    }
  };
  const res = useSelector((state) => state.userAddressReducer.getOneAddress);

  useEffect(() => {
    if (loading === false) {
      if (res && res.status === "success") setAddressDetails(res.data);
    } else {
      setAddressDetails([]);
    }
  }, [loading]);
  const handleCreateOrderCash = async () => {
    if (cartID) {
      if (cartID == "0") {
        notify("الرجاء اضافة منتجات الى العربة اولاً", "warn");
        return;
      }
      if (addressValue == "0" || !addressValue) {
        notify("الرجاء تحديد عنوان", "warn");
        return;
      }

      setLoadingCreate(true);
      await dispatch(
        createOrderCash(cartID, {
          shippingAddress: {
            details: addressDetails.details,
            phone: addressDetails.phone,
            country: addressDetails.country,
            city: addressDetails.country,
            postalcode: addressDetails.postalCode,
          },
          message: "",
        })
      );
    }
    setLoadingCreate(false);
  };
  const resCash = useSelector((state) => state.checkoutReducer.createCashOrder);
  const resOne = useSelector((state) => state.orderReducer.getOneOrder);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [imgCover, setImgCover] = useState([]);
  const [namesOfProds, setNamesOfProds] = useState([]);
  const [colorsOfProds, setColorsOfProds] = useState([]);
  const [sizesOfProds, setSizesOfProds] = useState([]);
  const [countsOfProds, setCountsOfProds] = useState([]);
  const [colorName, setColorName] = useState([]);
  useEffect(() => {
    if (loadingCreate === false) {
      if (
        resCash &&
        resCash.data &&
        resCash.data.data &&
        resCash.data.data._id
      ) {
        const get = async () => {
          await dispatch(getOneOrder(resCash.data.data._id));
        };
        get();
      }
    }
  }, [resCash, loadingCreate]);

  // ...
  const { changeCart, updateChangeCart } = useChangeCart();
  useEffect(() => {
    if (!addressDetails && addressDetails.length > 0) {
      notify("الرجاء اضافة عنوان ", "error");
      return;
    }
    if (loadingCreate === false) {
      if (!addressDetails && addressDetails.length > 0) {
        notify("الرجاء اضافة عنوان ", "error");
        return;
      }
      if (
        resCash &&
        resCash.status === 201 &&
        resCash.data &&
        resCash.data.data._id &&
        cartID
      ) {
        const orderId = resCash.data.data.id;
        const order_id = resCash.data.data._id;
        let details = addressDetails.details,
          country = addressDetails.country,
          city = addressDetails.city,
          postalcode = addressDetails.postalCode;

        //  // قم بتشكيل رابط واتساب مع الرسالة المناسبة
        setTimeout(async () => {
          dispatch(getOneOrder(resCash.data.data._id)).then(async (res) => {
            await Promise.all(
              res.data.cartItems.map(async (item, index) => {
                // imgCover.push(`${url}/${item.product.imageCover}`);
                imgCover.push(item.product._id);
                namesOfProds.push(item.product.title);
                if (item.color && item.color !== "") {
                  colorsOfProds.push(item.color);
                  colorName.push(item.colorName);
                }
                sizesOfProds.push(item.size);
              })
            );

            // الآن يمكنك تحديث الحالة بمرة واحدة بعد اكتمال جميع الترجمات

            // إضافة القيم المترجمة إلى مصفوفة countsOfProds
            res.data.cartItems.forEach((item) => {
              countsOfProds.push(item.count);
            });

            const message = `معلومات عن الطلب:
  - رقم الطلب: ${orderId}
  - اسم المستخدم: ${res.data.user.name}
  - ايميل المستخدم: ${res.data.user.email}
  - تاريخ الطلب: ${res.data.createdAt}
  
  معلومات المنتج:
  ${namesOfProds
    .map(
      (name, index) => `
    المنتج ${index + 1}:
    - اسم المنتج: ${name}
    - رابط المنتج: https://kiwifashion.net/products/${imgCover}
    ${
      colorsOfProds && colorsOfProds.length > 0
        ? ` - كود اللون المختار: ${colorsOfProds[index]}`
        : ""
    }
    ${
      colorName && colorName.length > 0
        ? ` - اسم اللون المختار: ${colorName[index]}`
        : ""
    }
    - الحجم المختار: ${sizesOfProds[index]}
    - العدد المطلوب: ${countsOfProds[index]}
`
    )
    .join("\n")}
  
  عنوان الشحن:
  - ${details}
  - الدولة: ${country}
  - المدينة: ${city}
  - الرمز البريدي: ${postalcode}
  
  السعر الكامل: ${res.data.totalOrderPrice} ليرة تركي

  رقم الحساب والاسم من أجل التحويل :
  AHMET EL SALİH
  TR54 0021 0000 0005 3389 6000 01
`;
            // تشكيل رابط واتساب مع الرسالة المناسبة

            // window.location.href = "/products";

            localStorage.setItem("message", message);

            // بعد ذلك، قم بعمل replace إلى رابط واتساب
            const encodedMessage = encodeURIComponent(message);
            const whatsappNumber = "+905395955434";
            if (message) {
              let resa = await dispatch(
                changeOrderMessage(order_id, {
                  message: message,
                })
              );
            }

            const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodedMessage}`;
            window.location.href = whatsappLink;

            // بعد فترة زمنية، يتم توجيه المستخدم إلى صفحة السلة
            setTimeout(() => {
              window.location.replace("/cart");
            }, 2000);
          });
          updateChangeCart(Math.random(500 * 100));
        }, 1000);
      }
    } else if (
      resCash &&
      resCash.status !== 201 &&
      typeof resCash === "number"
    ) {
      //   notify("فشل في اكمال الطلب الرجاء المحاولة لاحقاً", "warn");
      //   setTimeout(() => {
      //     window.location.replace("/cart");
      //   }, 1500);
    } else if (!cartID) {
      // notify("فشل في اكمال الطلب الرجاء المحاولة لاحقاً", "warn");
      // setTimeout(() => {
      //   window.location.replace("/cart");
      // }, 1500);
    }
  }, [loadingCreate]);

  return [
    handleChooseAddress,
    addressDetails,
    handleCreateOrderCash,
    addressValue,
  ];
};

export default OrderPayChashHook;
