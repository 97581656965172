import React from 'react'
import fiveStars from './5-star.png'
import fourStars from './4-star.png'
import { Quotes } from 'phosphor-react'
const ReviewCard = ({children,userImg,userName,stars,sex}) => {
  return (
    <div className="card-review">
        <div className="first-sec">

        <div className="stars">
            <img src={stars===5?fiveStars:fourStars} alt="" />
        </div>
        <div className="review-text">
            {children}
        </div>
        </div>
        <hr/>
        <div className="user-review">
            <span className="quote">
            <img width="40px" src={sex === 'male' ? 'https://cdn-icons-png.flaticon.com/512/149/149071.png' : 'https://cdn-icons-png.flaticon.com/512/149/149070.png'} alt="" />
            <h4 className="userName">{userName}</h4>
             </span>
             
            <Quotes />
        </div>
    </div>
  )
}

export default ReviewCard