import React from "react";
import { Container } from "react-bootstrap";
import BrandContainer from "../../Components/Brand/BrandContainer";
import Pagination from "../../Components/Utility/Pagination";
import AllBrandHook from "../../hook/brand/all-brand-page-hook";
const AllBrandPage = () => {
  const [brands,loading,pageCount,getPage] = AllBrandHook()

  return (
    <Container style={{ minHeight: "680px" }}>
      <BrandContainer data={brands.data} loading={loading} />
      <Pagination pageCount={pageCount} onPress={getPage}/>
    </Container>
  );
};

export default AllBrandPage;
