import React from "react";
import { Col, Row } from "react-bootstrap";
import mobile from "../../images/mobile.png";
import url from "./../../Api/url";
import { Star } from "@mui/icons-material";
const UserAllOrderCard = ({ item }) => {
  return (
    <div>
      <Row className="d-flex mb-2">
        <Col xs="3" md="2" className="d-flex justify-content-start">
          <a
            href={`/products/${
              item && item.product && item.product._id && item.product._id
            }`}
          >
            <img
              width="93px"
              height="120px"
              src={`${url}/products/${
                (item && item.product && item.product.imageCover) || ""
              }`}
              alt=""
            />
          </a>
        </Col>
        <Col xs="8" md="6" style={{ position: "relative" }}>
          <div className="d-inline pt-2 cat-title">
            {(item && item.product && item.product.title) || ""}
          </div>
          <div
            style={{
              top: "-18%",
              position: "absolute",
              left: "6%",
              color: "orange",
              display:'flex',
              width:'max-content'
            }}
            className="d-inline pt-2 cat-rate me-2"
          >
            {item && item.product && item.product.ratingsAverage
              ? item.product.ratingsAverage
              : 0} <Star/>
          </div>
          <div className="rate-count d-inline p-1 pt-2">
            ({(item && item.product && item.product.ratingsQuantity) || 0}{" "}
            تقييم)
          </div>
          <div className="mt-3 d-flex">
            <div className="cat-text  d-inline">الكميه</div>
            <input
              className="mx-2 "
              type="number"
              value={item && item.count}
              disabled
              style={{ width: "40px", height: "25px" }}
            />
            {item && item.color ? (
              <div
                className="color ms-2"
                style={{ background: item && item.color }}
              ></div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserAllOrderCard;
