import { createContext, useContext, useState } from "react";

const DeleteUserChangeContext = createContext();

export const DeleteUserChangeProvider = ({ children }) => {
  const [deleteChange, setDeleteChange] = useState();

  const updateDeleteChange = (newChange) => {
    setDeleteChange(newChange);
  };

  return (
    <DeleteUserChangeContext.Provider
      value={{ deleteChange, updateDeleteChange }}
    >
      {children}
    </DeleteUserChangeContext.Provider>
  );
};

export const useDeleteChangeContext = () => {
  return useContext(DeleteUserChangeContext);
};
