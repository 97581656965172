import  { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  updateReviewOnProduct } from '../../redux/actions/reviewAction';
import notify from '../useNotifaction';
import ViewAllReviewHook from './view-all-review-hook';
import { useChange } from '../../context/ChangeContext';

const EditRateHook = (item) => {
  const dispatch = useDispatch();
  const [loadingE, setLoadingE] = useState('');
  const [newRateText, setNewRateText] = useState(item.review);
  const [newRateValue, setNewRateValue] = useState(item.rating);
  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const onChangeNewRateText = (e)=>{
    setNewRateText(e.target.value)
  }
  const onChangeRateValue = (val)=>{
    setNewRateValue(val)
  }
  const handleEdit = async () => {
    if(newRateValue < 1){
      setNewRateValue(1)
    }
    if(newRateText ===''){
      setNewRateText(item.review)
    }
    try {
      setLoadingE(true);
      await dispatch(updateReviewOnProduct(item._id,{
        review:newRateText,
        rating:newRateValue,
      }));
      setLoadingE(false);
      handleCloseEdit();
    } catch (error) {
      console.error('Error deleting review:', error);
      setLoadingE(false);
      handleCloseEdit();
    }
  };
const res = useSelector(state=>state.reviewReducer.updateReviewOnProduct)
const { change, updateChange } = useChange();
let co = 1
useEffect(() => {
    if (loadingE === false) {
     if(res){
        if(res.status === 200){
            // eslint-disable-next-line react-hooks/exhaustive-deps
            
            notify('تمت عملية التعديل بنجاح', 'success');
             setTimeout(() => {
                updateChange(Math.random(100 * 500))
                 },1000)
        }
     }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingE]);
 
  return [onChangeRateValue,newRateValue, loadingE,showEdit,handleEdit,handleCloseEdit,handleShowEdit,newRateText,onChangeNewRateText];
};

export default EditRateHook;
